import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocs from '@portal-ng/PNSLocation/reducers';
import {IPNSGenerosityProvider, IPNSLocation, IPNSOrganization} from '@portal-ng/PNSModels';
import * as fromOrgs from '@portal-ng/PNSOrganization/reducers';
import {GoogleGeocodingService} from '@portal-ng/PNSServices/Google.Geocoding';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'portal-ng-pnsgenerosity-add-form',
  templateUrl: './pnsgenerosity-add-form.component.html',
  styleUrls: ['./pnsgenerosity-add-form.component.scss'],
})
export class PNSGenerosityAddFormComponent implements OnInit {
  orgs$: Subscription;
  orgs: IPNSOrganization[];
  locs$: Subscription;
  locs: IPNSLocation[];
  geoAddr: any;
  addrValidated: boolean;
  geocodedAddress$: Subscription;
  addGenPro$: Partial<IPNSGenerosityProvider> = {
    address: {
      street1: 'PO BOX 1243',
      street2: '',
      city: 'Endicott',
      state: 'NY',
      zip: '13413',
      longitude: '',
      latitude: '',
    },
    timeZone: '',
    name: 'Rise-NY (Formerly S.O.S. Shelter)',
    description: 'We are committed to the achievement of racial, ethnic and cultural diversity within its workplace, which will in turn' +
      ' provide the delivery of culturally competent care to the clients we serve. We strive to create an environment of acceptance of ' +
      'individual differences as we understand that diversity among our workforce contributes to a unified and interdependent team that' +
      ' is aware and sensitive to the diverse values and needs of one another as well as our clientele. This understanding and acceptance ' +
      'allows the empowerment of individuals to develop in order that each of us and each of the clients we serve can develop to their ' +
      'fullest potential.',
    phoneNumber: '(123) 123-1234',
    emailAddress: 'info@service.org',
    url: 'http://rise-ny.org/',
    services: [{
      id: '',
      name: 'Food and Meal Planning',
      description: 'Food and Meal Planning for Shelter residents',
      category: 'restaurant',
      cost: 'N/A',
      phoneNumber: '(123) 123-1234',
      emailAddress: 'info@service.org',
      attributes: [{
        key: 'Cause Eligibility',
        value: 'Domestic Abuse',
      }],
      notes: [{
        author: 'Jane Doe',
        created: new Date('2019-01-10T03:24:00'),
        note: 'This place is full as of today.',
      },
      {
        author: 'Jane Doe',
        created: new Date('2019-01-17T03:24:00'),
        note: 'Has availability.',
      }],
  },
  ],
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private locationService: PNSLocationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAuthState>,
    private geocodingService: GoogleGeocodingService,
  ) {
  }

  @HostListener('keydown.esc')
  public onEsc() {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['/admin/locations/facility/list']);
    this.cdr.detectChanges();

  }

  ngOnInit() {
    this.invalidateAddress();
    this.orgs$ = this.store.pipe(select(fromOrgs.getAllOrganizations)).subscribe((orgs) => {
      this.orgs = orgs;
    });
    this.locs$ = this.store.pipe(select(fromLocs.getAllLocationsArray)).subscribe((locs) => {
      this.locs = locs;

    });
  }

  invalidateAddress() {
    this.addrValidated = false;
  }

  validateAddress(form: NgForm): void {
    const v = form.value;
    const address: string = v.street1 + ', ' + v.street2 + ' ' + v.city + ', ' + v.state + ' ' + v.zip;
    this.geocodedAddress$ = this.geocodingService.geoCodeAddress(address).subscribe((addr) => this.geoAddr = this.mapAddr(addr));
  }

  // noinspection JSMethodCanBeStatic
  compareObjects(o1: any, o2: any): boolean {
    if (o1 !== null && o2 !== null) {
      return o1.name === o2.name && o1.id === o2.id;
    } else {
      return o1;
    }
  }

  mapAddr(googleGeoAddr: any): any {
    const geoAddr = googleGeoAddr.results[0];

    if (geoAddr !== undefined && geoAddr !== null) {
      geoAddr.address_components.forEach((addressPart) => {
        if (addressPart.types.includes('street_number')) {
          this.addGenPro$.address.street1 = addressPart.long_name + ' ';
        }

        if (addressPart.types.includes('route')) {
          this.addGenPro$.address.street1 += (addressPart.long_name);
        }

        if (addressPart.types.includes('locality')) {
          this.addGenPro$.address.city = addressPart.long_name;
        }

        if (addressPart.types.includes('administrative_area_level_1')) {
          this.addGenPro$.address.state = addressPart.short_name;
        }

        if (addressPart.types.includes('postal_code')) {
          this.addGenPro$.address.zip = addressPart.short_name;
        }

        if (addressPart.types.includes('postal_code_suffix')) {
          this.addGenPro$.address.zip += '-' + addressPart.short_name;
        }
      });

      if (geoAddr.geometry.location.lat !== null) {
        this.addGenPro$.address.latitude = geoAddr.geometry.location.lat;
        this.addGenPro$.address.longitude = geoAddr.geometry.location.lng;
      }
    }

    this.geocodingService.getTimeZone(this.addGenPro$.address.latitude.toString(), this.addGenPro$.address.longitude.toString()).subscribe((addrTZ) => {
      this.addGenPro$.timeZone = addrTZ.timeZoneId;
      this.addrValidated = true;
      this.cdr.detectChanges();
    });
  }

  addGenerosityProvider(): void {
    // this.store.dispatch(new AddPNSGenerosityProvider(this.addGenPro$));
  }
}
