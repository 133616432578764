<div id="page">
  <div *ngIf="this.location$">
    <h1 class="mat-display-1"><button mat-mini-fab color="accent" routerLink="/admin/locations/facility/list">
      <mat-icon svgIcon="arrow_back"></mat-icon></button>{{location$.name}} Data Quality Reporting Tool</h1>
  </div>

<mat-horizontal-stepper linear="true" #stepper>
  <mat-step label="Problem Type">
    <form #problemForm="ngForm">
      <ng-template matStepLabel>What's Your Problem?</ng-template>
      <mat-grid-list cols="3" rowHeight="1:1">
        <div *ngFor="let pt of problemTypes" class="dataQualityButton">
          <mat-grid-tile>
        <button matStepperNext (click)="changeProblemType(pt.label)">
  <img [src]="pt.image" [alt]="pt.label" />
  <svg class="DataQualityTypeButtonLabel" viewBox="0 0 130 30" xmlns="http://www.w3.org/2000/svg">
    <text text-anchor="middle" x="50%" y="50%">{{pt.label}}</text>
  </svg>
         </button >
          </mat-grid-tile>
        </div>
      </mat-grid-list>
    </form>
  </mat-step>
  <mat-step label="Problem Details">
    <form  #problemDetailsForm="ngForm">
      <ng-template matStepLabel>Describe your Problem</ng-template>

      <br/><br/>
      <fieldset>
        <legend>Request Information</legend>

      <div *ngIf="this.location$">
        <mat-form-field class="small" appearance="outline">
          <mat-label >Organization</mat-label>
          <input matInput disabled name="organization" [(ngModel)]="problem.organization" required [value]="this.location$.organizationName">
        </mat-form-field>

        <mat-form-field class="small" appearance="outline">
          <mat-label >Facility</mat-label>
          <input matInput disabled name="facility" [(ngModel)]="problem.facility" required [value]="this.location$.name">
        </mat-form-field>
      </div>

      <mat-form-field class="small" appearance="outline">
      <mat-label>Problem Type</mat-label>
        <input matInput disabled name="type" [(ngModel)]="problem.type" required>
      </mat-form-field>
<br/>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Who Are You?</mat-label>
        <input matInput name="reporter" [(ngModel)]="problem.reporter" placeholder="rdonahue" required>
        <mat-hint>pns username e.g. jallen</mat-hint>
      </mat-form-field>
      </fieldset>
      <br/><br/>
        <div *ngIf="problem.type !== 'Something Worse'">
          <fieldset>
            <legend>Patient Information</legend>
            <div *ngIf="loadingPatientData[0] === false; else loading">

      <mat-form-field class="medium action" appearance="outline">
        <mat-label>Patient Medical Record Number</mat-label>
        <input matInput name="mrn1" [(ngModel)]="problem.visits[0].mrn" required placeholder="310745">
        <button mat-icon-button (click)="lookupMRN(0)"><mat-icon svgIcon="search"></mat-icon></button>
        <mat-hint>Please format identically with C2D, include any leading 0's</mat-hint>
        <div *ngIf="this.mrnNotFound[0]">
          <mat-error>MRN Not Found</mat-error>
        </div>
      </mat-form-field>

      <mat-form-field class="medium action" appearance="outline">
        <mat-label>Patient Visit Number</mat-label>
        <input matInput name="visitNumber1" [(ngModel)]="problem.visits[0].visitNumber" required placeholder="12345">
        <button mat-icon-button (click)="lookupVisit(0)"><mat-icon svgIcon="search"></mat-icon></button>
        <mat-hint>Can appear on Census as FIN</mat-hint>
        <div *ngIf="this.visitNumberNotFound[0]">
          <mat-error>VisitID Not Found</mat-error>
        </div>
      </mat-form-field>

      <mat-form-field class="medium" appearance="outline">
        <mat-label>Patient Name</mat-label>
        <input matInput name="name1" [(ngModel)]="problem.visits[0].name" required placeholder="DONAHUE, RYAN">
        <mat-hint>Please format Last name, First name</mat-hint>
      </mat-form-field>

      <mat-form-field class="medium" appearance="outline">
        <mat-label>Patient Admit Date</mat-label>
        <input matInput name="admitDate1" type='datetime-local' [(ngModel)]="problem.visits[0].admitDate" required placeholder="2019-05-10T12:13:23">
      </mat-form-field>

      <mat-form-field class="small" appearance="outline">
        <mat-label >Unit</mat-label>
        <input matInput name="unit1"  [(ngModel)]="problem.visits[0].unit" required placeholder="ED">
      </mat-form-field>

      <mat-form-field class="small" appearance="outline">
        <mat-label >Room</mat-label>
        <input matInput name="room1"  [(ngModel)]="problem.visits[0].room"  placeholder="123">
      </mat-form-field>

      <mat-form-field class="small"  appearance="outline">
        <mat-label >Bed</mat-label>
        <input matInput name="bed1" class="small left" [(ngModel)]="problem.visits[0].bed"  placeholder="1">
      </mat-form-field>

            </div>
      </fieldset>
        </div>
      <br/><br/>


      <div *ngIf="problem.type == '2 Patients, 1 Bed'">
          <fieldset>
            <legend>Second Patient Information</legend>
            <div *ngIf="loadingPatientData[1] === false; else loading">
        <h3>Second Patient</h3>
        <mat-form-field class="medium action" appearance="outline">
          <mat-label>Patient Medical Record Number</mat-label>
          <input matInput name="mrn2" [(ngModel)]="problem.visits[1].mrn" required placeholder="310745">
          <button mat-icon-button (click)="lookupMRN(1)"><mat-icon svgIcon="search"></mat-icon></button>
          <mat-hint>Please format identically with C2D, include any leading 0's</mat-hint>
          <div *ngIf="this.mrnNotFound[1]">
            <mat-error>MRN Not Found</mat-error>
          </div>
        </mat-form-field>

        <mat-form-field class="medium action" appearance="outline">
          <mat-label>Patient Visit Number</mat-label>
          <input matInput name="visitNumber2" [(ngModel)]="problem.visits[1].visitNumber" required placeholder="310745">
          <button mat-icon-button (click)="lookupVisit(1)"><mat-icon svgIcon="search"></mat-icon></button>
          <mat-hint>Can appear on Census as FIN</mat-hint>
          <div *ngIf="this.visitNumberNotFound[1]">
            <mat-error>VisitID Not Found</mat-error>
          </div>
        </mat-form-field>

        <mat-form-field class="medium" appearance="outline">
          <mat-label>Patient Name</mat-label>
          <input matInput name="name2" [(ngModel)]="problem.visits[1].name" placeholder="DONAHUE, RYAN" required>
          <mat-hint>Please format Last name, First name</mat-hint>
        </mat-form-field>

        <mat-form-field class="medium" appearance="outline">
          <mat-label>Patient Admit Date</mat-label>
          <input matInput name="admitDate2" type="datetime-local" [(ngModel)]="problem.visits[1].admitDate" required placeholder="2019-05-18T02:12:23">
        </mat-form-field>
          </div>
      </fieldset>

      </div>

      <fieldset>
          <legend>What is the problem?</legend>

          <mat-form-field class="large" appearance="outline">
            <mat-label>Problem Description</mat-label>

            <textarea matInput name="description" [(ngModel)]="problem.description" required>
            </textarea>
            <mat-hint>Please explain what happened, what steps you took to make it happen, and what you expected.</mat-hint>
          </mat-form-field>


        <mat-form-field class="small" appearance="outline">
          <mat-label >Unit</mat-label>
          <input matInput name="unit2"  [(ngModel)]="problem.visits[1].unit" required placeholder="ED">
        </mat-form-field>

        <mat-form-field class="small" appearance="outline">
          <mat-label >Room</mat-label>
          <input matInput name="room2"  [(ngModel)]="problem.visits[1].room"  placeholder="123">
        </mat-form-field>

        <mat-form-field class="small"  appearance="outline">
          <mat-label >Bed</mat-label>
          <input matInput name="bed2" class="small left" [(ngModel)]="problem.visits[1].bed"  placeholder="1">
        </mat-form-field>

        </fieldset>
      <button mat-raised-button (click)="submit()">Submit</button>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    You are now done.
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>

</div>


<ng-template #loading>
  <div class="loading-spinner"><mat-progress-spinner class="spinner" color="primary" mode="indeterminate"></mat-progress-spinner></div>
</ng-template>
