import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {

  // input  : +12073232219
  // output : +1 (207) 323-2219
  static validateFormat(input: string): boolean {
    return input !== undefined && (input.length === 12) && (input.indexOf('+') === 0) && (!Number.isNaN(Number.parseInt(input.substr(1), 10)));
  }

  transform(value: any, args?: any): any {
    if (PhoneNumberPipe.validateFormat(value)) {
      const countryCode = value.substr(0, 2);
      const areaCode = value.substr(2, 3);
      const exchange = value.substr(5, 3);
      const lastPart = value.substr(8);
      return countryCode + ' (' + areaCode + ') ' + exchange + '-' + lastPart;
    }
  }
}
