import {Action} from '@ngrx/store';
import {IPNSHttpError} from '@portal-ng/PNSModels/PNSError.model';

export enum ErrorActionTypes {
  APIError          = '[API] Error',
  ErrorNotification = '[API] Display Error Notification',
  ResetErrors       = '[API] Reset Errors',
  ActionError       = '[Action] Error',
}

export class APIError implements Action {
  readonly type = ErrorActionTypes.APIError;
  constructor(public payload: { error: IPNSHttpError} ) {}
}

export class ActionError implements Action {

readonly type = ErrorActionTypes.ActionError;
  constructor(public payload: { errorAction: Action} ) {}
}


export class ResetErrors implements Action {
  readonly type = ErrorActionTypes.ResetErrors;
  constructor() {}
}

export class ErrorNotification implements Action {
  readonly type = ErrorActionTypes.ErrorNotification;
  constructor(public payload: { error: IPNSHttpError } ) {}
}

export type ErrorActionsUnion = ActionError | APIError | ErrorNotification  | ResetErrors;
