import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {Buffer} from 'buffer/';

@Injectable({
    providedIn: 'root',
})

export class PNSFileService {

    constructor(
        private store: Store<IAuthState>,
        private auth: PNSAuthService,
        private PNS: PNSService) {
    }


    updateForm(id: string, updatedFile: File): void {
        let payload;
        const reader: FileReader = new FileReader();
        reader.onloadend         = (e) => {
            payload = {base64PDF: Buffer.from(reader.result as ArrayBuffer).toString('base64')};
            const headers = new HttpHeaders().append('content-type', 'application/json');
            this.PNS.authenticatePut('forms/templates/' + id + '.pdf', JSON.stringify(payload), null, headers).subscribe((r) => r);
        };

        reader.readAsArrayBuffer(updatedFile);

    }

    // Determines if value is arrayBufferLike or not for handling return value from btoa().
    isArrayBufferLike(value: any): boolean {
        return value && value.buffer instanceof ArrayBuffer && value.byteLength !== undefined;
    }
}
