import {Action} from '@ngrx/store';
import {IPNSSearchResults} from '@portal-ng/PNSModels';

export enum PNSExecutionsActionTypes {
  LoadPNSExecutions = '[PNSExecutions] Retrieve',
  LoadPNSExecutionsSuccess = '[PNSExecutions] Retrieve Success',
}

// Loading

export class LoadPNSExecutions implements Action {
  readonly type = PNSExecutionsActionTypes.LoadPNSExecutions;
  constructor() {}
}

export class LoadPNSExecutionsSuccess implements Action {
  readonly type = PNSExecutionsActionTypes.LoadPNSExecutionsSuccess;
  constructor(public payload: {executions: IPNSSearchResults} ) {}
}

export type PNSExecutionsActionTypeUnion =
  LoadPNSExecutions
  | LoadPNSExecutionsSuccess;
