import {Action} from '@ngrx/store';
import {IPNSCodeSearch, IPNSSearchResults} from '@portal-ng/PNSModels';

export enum PNSCodeActionTypes {
  PNSCodeLoad        = '[PNSCode] Load',
  PNSCodeLoadSuccess = '[PNSCode] Load Success',
}

export class PNSCodeLoad implements Action {
  readonly type = PNSCodeActionTypes.PNSCodeLoad;

  constructor(public payload: IPNSCodeSearch) {}
}

export class PNSCodeLoadSuccess implements Action {
  readonly type = PNSCodeActionTypes.PNSCodeLoadSuccess;

  constructor(public payload: { codes: IPNSSearchResults }) {}
}

export type PNSCodeActionsUnion = PNSCodeLoad | PNSCodeLoadSuccess;
