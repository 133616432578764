import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {PNSWebFormComponent} from '@portal-ng/PNSShared/components/PNSWebForm/PNSWebForm.component';
import {PNSMatChipFieldComponent} from '@portal-ng/PNSShared/components/PNSMatChipField/PNSMatChipField.component';
import {PNSTableComponent} from '@portal-ng/PNSShared/components/PNSTable/PNSTable.component';
import {PNSTreeSelectListComponent} from '@portal-ng/PNSShared/components/PNSTreeSelectList/PNSTreeSelectList.component';
import {PNSRawSearchResultComponent} from '@portal-ng/PNSShared/components/raw-search-result-component/raw-search-result.component';
import {PNSCapsDetectorDirective} from '@portal-ng/PNSShared/directives/PNSCapsDetector.directive';
import {EmailAddressPipe} from '@portal-ng/PNSShared/pipes/emailAddress.pipe';
import {ListPipe} from '@portal-ng/PNSShared/pipes/list.pipe';
import {NullPipe} from '@portal-ng/PNSShared/pipes/null.pipe';
import {EnumToArrayPipe} from '@portal-ng/PNSShared/pipes/enumToArray.pipe';
import {PhoneNumberPipe} from '@portal-ng/PNSShared/pipes/phone-number.pipe';
import {PNSWhatsNewComponent} from '@portal-ng/PNSShared/components/PNSWhatsNew/PNSWhatsNew.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TimeagoModule} from 'ngx-timeago';
import {NgxJsonViewerModule} from 'ngx-json-viewer';

const COMPONENTS = [
    PNSWebFormComponent,
    PNSTableComponent,
    PNSMatChipFieldComponent,
    PNSTreeSelectListComponent,
    PNSRawSearchResultComponent,
    PNSWhatsNewComponent,
];

const PIPES = [
    EmailAddressPipe,
    ListPipe,
    NullPipe,
    PhoneNumberPipe,
    EnumToArrayPipe,
];

const DIRECTIVES = [
    PNSCapsDetectorDirective,
];

const IMPORTS = [
    TimeagoModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    NgxJsonViewerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ClipboardModule,
];


const EXPORTS      = [...COMPONENTS, ...PIPES, ...IMPORTS, ...DIRECTIVES];
const DECLARATIONS = [...COMPONENTS, ...PIPES, ...DIRECTIVES];

@NgModule({
    imports     : IMPORTS,
    declarations: DECLARATIONS,
    exports     : EXPORTS,
})
export class SharedModule {}
