import {IPNSRole} from '@portal-ng/PNSModels/PNSRole.model';
import * as PNSUserActions from '../actions/PNSUser.action';

export interface IRoleState {
  PNSRoles: IPNSRole[];
  loaded: boolean;
}

const initialState: IRoleState = {
  PNSRoles: [],
  loaded: false,
};

export function reducer(state: IRoleState = initialState, action: PNSUserActions.PNSUserActionsUnion): IRoleState {
  switch (action.type) {

    case PNSUserActions.PNSUserActionTypes.LoadPNSRoles:
      return {
        ...state,
        loaded: false,
      };

    case PNSUserActions.PNSUserActionTypes.LoadPNSRolesSuccess:
      return {
        ...state,
        PNSRoles: action.payload.roles.results,
        loaded: true,
      };
    default:
      return state;
  }
}
