import {Component, HostListener, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSOrganization} from '@portal-ng/PNSModels';
import {AddPNSOrganization} from '@portal-ng/PNSOrganization/actions/PNSOrganization.action';
import {PNSOrganizationService} from '@portal-ng/PNSServices/PNSOrganization.service';

@Component({
  selector: 'portal-ng-organization-add-form',
  templateUrl: './pnsorganization-add-form.component.html',
  styleUrls: ['./pnsorganization-add-form.component.scss'],
})
export class PNSOrganizationAddFormComponent implements OnInit {
  addOrg$: Partial<IPNSOrganization> = {
    name: 'Enter Name',
    abbreviation: 'EN',
    orgType: 'HEALTH_SYSTEM',
  };

  constructor(
    private organizationService: PNSOrganizationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAuthState>,
  ) {
  }

  @HostListener('keydown.esc')
  public onEsc() {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['/admin/organizations/list']);
  }

  ngOnInit() {}

  addOrg(form: NgForm): void {

    const v = form.value;

    const addOrg: Partial<IPNSOrganization> = {
     name: v.name,
      abbreviation: v.abbreviation,
      orgType: v.orgType,
    };

    this.store.dispatch(new AddPNSOrganization(addOrg));
  }
}
