import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {SAMLTokenResponsePayload} from '@portal-ng/PNSModels';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {SAMLTokenError} from '@portal-ng/PNSAuth/actions/PNSAuth.actions';

@Injectable({
    providedIn: 'root',
})

export class PNSAuthService {

    constructor(private store: Store<IAuthState>, private http: HttpClient) {}

    private authentication: SAMLTokenResponsePayload;

    getCurrentURL(): string {
        return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    attemptLogout(): Observable<any> {
        const params  = new HttpParams()
            .set('client_id', environment.Auth.clientId)
            .set('logout_uri', this.getCurrentURL() + '/logout');
        const url     = environment.Auth.baseURL + environment.Auth.logoutURL;
        const options = {params};
        return this.http.get(url, options);
    }

    attemptAuthorization(code: string): Observable<any> {
        if (code !== undefined) {
            const authorizationPayload = new URLSearchParams();
            const url                  = environment.Auth.baseURL + environment.Auth.authURL;
            authorizationPayload.set('code', code);
            authorizationPayload.set('grant_type', 'authorization_code');
            authorizationPayload.set('client_id', environment.Auth.clientId);
            authorizationPayload.set('redirect_uri', this.getCurrentURL() + '/login');

            const headers: HttpHeaders = new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded');
            const options              = {headers};
            return this.http.post(url, authorizationPayload.toString(), options);
        }
    }

    attemptTokenRefresh(): Observable<any> {
        const refreshPayload = new URLSearchParams();
        const url            = environment.Auth.baseURL + environment.Auth.authURL;

        const refreshToken = this.getRefreshToken();

        if (refreshToken === null || refreshToken === undefined || refreshToken === '') {
            this.store.dispatch(new SAMLTokenError());
        } else {

            refreshPayload.set('refresh_token', refreshToken);
            refreshPayload.set('grant_type', 'refresh_token');
            refreshPayload.set('client_id', environment.Auth.clientId);
            refreshPayload.set('redirect_uri', window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/login');

            const headers: HttpHeaders = new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded');
            const options              = {headers};
            return this.http.post(url, refreshPayload.toString(), options);
        }
    }

    getHostedUIURL(endpoint: string) {
        const params = new HttpParams()
            .append('response_type', environment.Auth.responseType)
            .append('client_id', environment.Auth.clientId)
            .append('redirect_uri', window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/' + endpoint)
            .append('scope', 'openid+profile+aws.cognito.signin.user.admin');
        return environment.Auth.baseURL + environment.Auth.loginURL + '?' + params.toString();
    }

    getHostedLoginURL = () => this.getHostedUIURL('login');
    getHostedLogoutURL = () => this.getHostedUIURL('logout');

    getAuthenticationObject(): SAMLTokenResponsePayload {
        return this.authentication;
    }

    setSAMLTokens(tokens: SAMLTokenResponsePayload) {
        this.authentication = tokens;
    }

    getAccessToken() {
        let accessToken = '';
        if (this.authentication && (this.authentication.access_token !== null || true)) {
            accessToken = this.authentication.access_token;
        }
        return accessToken;
    }

    getIDToken() {
        let idToken = '';
        if (this.authentication && (this.authentication.id_token !== null || true)) {
            idToken = this.authentication.id_token;
        }
        return idToken;
    }

    isExpired() {
        return (this.authentication && (parseInt(this.authentication.expires_in, 10) + parseInt(this.authentication.issued, 10) <= Date.now()));
    }

    getRefreshToken() {
        let refreshToken = '';
        if (this.authentication && (this.authentication.refresh_token !== null || true)) {
            refreshToken = this.authentication.refresh_token;
        }
        return refreshToken;
    }
}
