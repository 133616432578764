import {Component, OnDestroy} from '@angular/core';

@Component({
  selector: 'portal-ng-not-found-page',
  template: `
    <mat-card>
      <mat-card-title>404: Not Found</mat-card-title>
      <mat-card-content>
        <p>Hey! It looks like this page doesn't exist yet.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/">PNS Home</button>
      </mat-card-actions>
    </mat-card>
  `,
  styles: [
    `
      :host {
        text-align: center;
      }
    `,
  ],
})
export class NotFoundPageComponent implements OnDestroy {

  constructor() {
    document.body.className = 'blue';
  }

  ngOnDestroy() {
    document.body.className = '' ;
  }
}
