<div class="dashboard">
           <div class="r2 status">
               <h2 class="mat-h2">System Status</h2>

               <portalNG-status-bank></portalNG-status-bank>
            </div>

    <div id="quickLinks" class="r1">
        <br />
        <h2 class="mat-h2">Quick Links</h2>
        <div id="quickLinkGrid">
            <div *ngFor="let item of quickNav.items">
                    <button color="primary" mat-raised-button [routerLink]="item.routerLink ? item.routerLink : null"
                            [formAction]="item.href ? item.href : null">
                        <mat-icon [svgIcon]="item.icon"></mat-icon>
                    {{item.title}}</button>
                </div>
        </div>
    </div>
        <div class="l whatsNew">
            <portal-ng-pnswhats-new app="Portal" version="LATEST" [build]="env.type" format="html" tenant="all"></portal-ng-pnswhats-new>
        </div>
    </div>

