import * as fromRouter from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import * as fromPNSAuth from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromError from '@portal-ng/PNSCore/reducers/error.reducer';
import * as fromPNSUX from '@portal-ng/PNSCore/reducers/PNSUX.reducer';
import * as fromPNSLocation from '@portal-ng/PNSLocation/reducers/PNSLocation.reducer';
import * as fromPNSFormTemplate from '@portal-ng/PNSForms/reducers/PNSFormTemplate.reducer';
import {environment} from '../../../environments/environment';
import {getFormTemplatesForLocationId} from '@portal-ng/PNSForms/reducers';
import {getLocationByID} from '@portal-ng/PNSLocation/reducers';

export interface IAppState {
    pnsux: fromPNSUX.IPNSUXState;
    router: fromRouter.RouterReducerState;
    auth: fromPNSAuth.IAuthState;
    error: fromError.IErrorState;
    location: fromPNSLocation.IPNSLocationState;
    forms: fromPNSFormTemplate.IPNSFormModuleState;
}

export const reducers: ActionReducerMap<IAppState> = {
    pnsux   : fromPNSUX.reducer,
    router  : fromRouter.routerReducer,
    auth    : fromPNSAuth.reducer,
    error   : fromError.reducer,
    location: fromPNSLocation.reducer,
    forms   : fromPNSFormTemplate.reducer,
};

export function logger(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
    return (state: IAppState, action: any): IAppState => {
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */

export const metaReducers: MetaReducer<IAppState>[] = !environment.production
    ? [logger]
    : [];
export const getPNSUXState                          = createFeatureSelector<fromPNSUX.IPNSUXState>('pnsux');
export const getErrorState                          = createFeatureSelector<fromError.IErrorState>('error');
export const getError                               = createSelector(getErrorState, fromError.getError);
export const getErrorMessage                        = createSelector(getErrorState, getError, fromError.getErrorMessage);
export const getPNSUXStatus                         = createSelector(getPNSUXState, fromPNSUX.getStatus);

export const getPNSLocationWithFormTemplates = ((facilityId: string) => createSelector(
        getLocationByID(facilityId),
        getFormTemplatesForLocationId(facilityId),
        (loc, forms) => {
            return {
               loc, forms
            };
        })
);
