import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {EditPNSLocation} from '@portal-ng/PNSLocation/actions/PNSLocation.action';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSLocationFormData, IPNSUser} from '@portal-ng/PNSModels';
import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';
import {EditPNSUser} from '@portal-ng/PNSUser/actions/PNSUser.action';
import * as fromUser from '@portal-ng/PNSUser/reducers';
import {Subscription} from 'rxjs';
import {PNSLocationFormDialogComponent} from '@portal-ng/PNSLocation/components/pnslocation-form-dialog/p-n-s-location-form-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import * as fromRoot from '@portal-ng/PNSCore/reducers';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';


@Component({
    selector   : 'portal-ng-pnslocation-facility-list',
    templateUrl: './pnslocation-facility-list.component.html',
    styleUrls  : ['./pnslocation-facility-list.component.scss'],
})
export class PNSLocationFacilityListComponent implements OnInit, OnDestroy {
    dataSource = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    filter: string;
    operationalFilter: boolean;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['id', 'configure', 'name', 'organization', 'operational'];

    toggledLocation$: Partial<IPNSLocation> = {
        id: null,
    };
    location$: Subscription;
    user$: Subscription;
    locationId: string;
    authenticatedUser$: Subscription;
    authUser: Partial<IPNSUser>;

    constructor(
        private dialog: MatDialog,
        private store: Store<IAppState>,
        private route: ActivatedRoute,
        private locationService: PNSLocationService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.operationalFilter = true;
        this.store.pipe(select(fromLocation.getAllLocations)).subscribe();
        this.dataSource = new MatTableDataSource<IPNSLocation>();
        this.refresh();
        this.locationId = this.route.snapshot.params['id'];

        this.authenticatedUser$ = this.store.pipe(select(fromAuth.getAuthenticatedUser))
            .subscribe((authUser) => {
                this.authUser = authUser;
            });
    }

    toggleOperational(id: string) {

        if (id !== undefined && id !== null) {
            this.location$ = this.store.pipe(select(fromLocation.getLocationByID(id))).subscribe((location) => {
                if (location !== undefined && location !== null) {
                    this.toggledLocation$ = location;
                }
            });
        }

        const updatedLocation = {
            ...this.toggledLocation$,
            operational: !this.toggledLocation$.operational,
        };

        if (updatedLocation.operational === false && id !== undefined && id !== null) {
            console.log('deactivate user perms..');

            // remove location from relevant user permissions
            this.user$ = this.store.pipe(select(fromUser.getUsersByLocationID(id))).subscribe((user: IPNSUser[]) => {
                user.forEach((u: IPNSUser) => {

                    const updatedUser = {
                        ...u,
                        locations: u.locations.filter((loc) => loc.id.toString() !== id.toString()),
                    };

                    this.store.dispatch(new EditPNSUser(updatedUser));
                });
            });

            if (this.user$ !== undefined) {
                this.user$.unsubscribe();
            }

        }

        this.store.dispatch(new EditPNSLocation(updatedLocation));
        this.router.navigate(['/admin/locations/facility/list']);
    }

    ngOnDestroy() {
        // this.user$.unsubscribe();
    }

    applyFilter(filter: EventTarget) {
        const element = filter as HTMLInputElement;
        let filterValue: string;

        if (element.value !== undefined) {
            filterValue            = element.value.trim().toLowerCase(); // Datasource defaults to lowercase matches
            this.dataSource.filter = filterValue;
        } else {
            this.refresh();
        }
    }

    reloadLocation(locationId: string): void {
        this.locationService.reloadLocation(locationId);
    }

    openFormDialog(facilityId: string): void {
        this.store.select( fromRoot.getPNSLocationWithFormTemplates(facilityId)).subscribe((result) => {
            const data: IPNSLocationFormData = {
                facilityId  : result.loc.id,
                facilityName: result.loc.locationName,
                forms: result.forms,
            };

            const dialogRef = this.dialog.open(PNSLocationFormDialogComponent, {
                width: '50wv',
                data,
            });

            dialogRef.afterClosed().subscribe(() => {
            });

        });
    }

    refresh() {
        if (this.operationalFilter) {
            this.store.pipe(select(fromLocation.getOperationalLocationByType('FACILITY')))
                .subscribe((data) => {
                    data.sort((a: IPNSLocation, b: IPNSLocation) => a.name.localeCompare(b.name));
                    this.dataSource = new MatTableDataSource<IPNSLocation>(data);
                    setTimeout(() => {
                        this.dataSource.sort      = this.sort;
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.filter    = this.filter;
                    });
                });
        } else {
            this.store.pipe(select(fromLocation.getLocationByType('FACILITY')))
                .subscribe((data) => {
                    data.sort((a: IPNSLocation, b: IPNSLocation) => a.name.localeCompare(b.name));
                    this.dataSource = new MatTableDataSource<IPNSLocation>(data);
                    setTimeout(() => {
                        this.dataSource.sort      = this.sort;
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.filter    = this.filter;
                    });
                });
        }
    }
}
