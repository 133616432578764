<div id="wrapper" *ngIf="this.formTemplate as ft">

  <h2 class="mat-h2">Form Template Information</h2>
  <mat-form-field  appearance="fill" *ngIf="ft.displayName">
      <mat-label>Display Name</mat-label>
      <input [(ngModel)]="formTemplate.displayName" matInput placeholder="My Cool New Form">
      <mat-hint>Not too short&hellip; not too long.</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="ft.topic">
      <mat-label>Topic</mat-label>
      <mat-select [(ngModel)]="ft.topic">
        <mat-option *ngFor="let topic of this.topics | keyvalue" [value]="topic.key">{{topic.value}}</mat-option>
      </mat-select>
      <mat-hint>Ask Ryan or Lucas.</mat-hint>
    </mat-form-field>

  <br /><br />
  <h2 class="mat-h2">Replace Form PDF File</h2>

  <h3 class="mat-h3">Current File (ID {{this.formTemplate.assetRef.id}})</h3>

  <label></label>
    <input type="file" [(ngModel)]="this.newFormData" #file  (change)="onChange(file.files)" />
    <button mat-raised-button [disabled]="!this.readyForUpload" color="warn" (click)="updateFile(ft.id)">Replace File</button>

    <a mat-raised-button color="accent" [href]="this.fileUrl" [download]="ft.id + '.pdf'">Download File</a>
  <br /><br />


  <h2 class="mat-h2">Enabled Locations</h2>
  <PortalNG-PNSTreeSelectList #locationTree (selectionChanged)="log($event)" [initialSelectedValues]="formLocationIds" [treeData]="this.locationTreeData"
                              multiSelect="true" startExpanded="true"></PortalNG-PNSTreeSelectList>
  <br /><br />
<div *ngIf="ft.deliveryTargets">
  <h2 class="mat-h2">Delivery Targets</h2>
  <table mat-table [dataSource]="deliveryTargetDataSource" >
  <!-- Name Column -->
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef> label </th>
    <td mat-cell *matCellDef="let element"> {{element.label}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef> Address </th>
    <td mat-cell *matCellDef="let element"> {{element.address}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element"><button mat-icon-button color="primary" (click)="deleteDeliveryTarget(element.id)"><mat-icon svgIcon="delete"></mat-icon></button></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <br />
  <button mat-raised-button color="primary" (click)="applyFormTemplateChanges()">Apply</button>
</div>
</div>
