import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PEAFileTreeNode {
  constructor(name: string, displayName: string, type: string) {
    this.name        = name;
    this.displayName = displayName;
    this.type        = type;
    this.expanded    = true;
    if (this.type === 'directory') {
      this.children = Array();
    }
  }

  displayName: string;
  name: string;
  type?: string;
  children?: PEAFileTreeNode[];
  expanded?: boolean;
}

export class PEATreeService {

  tree$: Observable<PEAFileTreeNode>;
  root: PEAFileTreeNode;

  constructor() { }

  createRootNode() {
    this.root = new PEAFileTreeNode('Root', '/', 'directory');
  }

  getChildByName(childName) {
    this.root.children.forEach((child) => {
      if (child.name === childName) {
        return child;
      }
    });
  }


  getTree(): PEAFileTreeNode {
    return this.root;
  }

  printTree() {
    console.info(this.root);
  }

  addItemForPath(path, item) {

    if (this.root === undefined) { console.error('Bad Tree'); }
    if (path === undefined) { console.error('Bad Path'); }
    if (item === undefined) { console.error('Bad Item'); }

    let nodeCursor: PEAFileTreeNode = this.root;

    const pathElements = path.split('/');

    pathElements.forEach((pathElement) => {
      if (nodeCursor === undefined) {
        console.error('bad cursor.');
      }

      let type = 'application/octet-stream';

      if (pathElement === '' || pathElement === undefined) {
      } else if (pathElement.indexOf('.') !== -1) {
        const ext = item.Key.split('.').pop();
        switch (ext) {
          case 'json':
            type = 'application/json';
            break;
          default:
            type = 'application/octet-stream';
            break;
        }

        const name = item.Key;
        const displayName = pathElement;

        const childIndex = nodeCursor.children.push({name, displayName, type} as PEAFileTreeNode);
        nodeCursor       = nodeCursor.children[childIndex - 1];
      } else {
        const pathElementDir = pathElement + '/';

        let found = false;
        nodeCursor.children.forEach((child) => {

          if (child.name === pathElementDir) {
            nodeCursor = child;
            found      = true;
          }
        });

        if (!found) {
          const childIndex = nodeCursor.children.push(
            {name: pathElementDir, displayName: pathElement, type: 'directory', children: Array()} as PEAFileTreeNode);
          nodeCursor       = nodeCursor.children[childIndex - 1];
        }
      }
    });
  }
}
