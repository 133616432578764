<div *ngIf="dataSource.data != null">
  <div id="filter">
    <mat-form-field class="filter">
      <label>Filter
        <input matInput (keyup)="applyFilter($event.target)" placeholder="Filter"></label>
    </mat-form-field>
    <span *ngFor="let filter of this.filters">
          <span *ngIf="filter.type === 'text'">
             <mat-form-field class="filter">
             <label>{{filter.name}}
               <input matInput (keyup)="this.complexFilter.emit($event.target)" [placeholder]="filter.name"></label>
              </mat-form-field>
          </span>
         <span *ngIf="filter.type === 'checkbox'">
             <label class="mat-checkbox-label">{{filter.name}}
               <mat-checkbox (change)="this.complexFilter.emit($event)" [id]="filter.name"
                             [name]="filter.name"></mat-checkbox></label>
        </span>
         <span *ngIf="filter.type === 'dropDown'">
             <mat-form-field class="filter">
             <label>{{filter.name}}
               <mat-select (change)="this.complexFilter.emit($event)" [placeholder]="filter.name"
                           *ngFor="let option of filter.options">
                 <option (value)="option.value">{{option.name}}</option>
               </mat-select>
             </label>
              </mat-form-field>
        </span>
    </span>
    <button mat-icon-button *ngIf="button" [routerLink]="button.routerLink">
      <mat-icon [svgIcon]="button.icon"></mat-icon>
    </button>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table #table [dataSource]="dataSource" matSort *ngFor="let col of this.columnConfiguration">
          <ng-container [matColumnDef]="col.name" *ngIf="col.type === columnType.string">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.title}}</th>
            <td mat-cell *matCellDef="let row">{{pathDataAccessor(row, col.name)}}</td>
          </ng-container>
          <ng-container [matColumnDef]="col.name" *ngIf="col.type === columnType.visitStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.title}}</th>
            <td mat-cell *matCellDef="let row">{{this.getVisitStatus(row)}}</td>
          </ng-container>
          <ng-container *ngIf="col.type === columnType.concat" [matColumnDef]="col.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.title}}</th>
            <td mat-cell *matCellDef="let row">{{concatPathDataAccessor(row, col.names, col.delimiter)}}</td>
          </ng-container>
          <ng-container *ngIf="col.type === columnType.location" [matColumnDef]="col.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.title}}</th>
            <td mat-cell *matCellDef="let row">{{this.getLocation(row)}}</td>
          </ng-container>
          <ng-container [matColumnDef]="col.name" *ngIf="col.type === columnType.dateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.title}}</th>
            <td mat-cell *matCellDef="let row">{{row[col.name] | date}}</td>
          </ng-container>

          <ng-container [matColumnDef]="col.name" *ngIf="col.type === columnType.user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.title}}</th>
            <td mat-cell *matCellDef="let row">{{displayUser(pathDataAccessor(row, col.name))}}</td>
          </ng-container>
      <tr mat-header-row *matHeaderRowDef="this.displayedColumns; sticky: true;"></tr>
      <!--suppress JSUnusedLocalSymbols -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="100"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </div>
</div>
