import {NgModule} from '@angular/core';
import {PNSFormTemplateListComponent} from './components/PNSForm-Template-List/PNSFormTemplateList.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {SharedModule} from '@portal-ng/PNSShared/shared.module';
import {PNSFormsRoutingModule} from '@portal-ng/PNSForms/PNSForms-routing.module';
import {PNSFormTemplateEditComponent} from '@portal-ng/PNSForms/components/PNSForm-Template-Edit/PNSForm-template-edit.component';

export const COMPONENTS = [
    PNSFormTemplateListComponent,
    PNSFormTemplateEditComponent,
];

@NgModule({
    imports     : [
        SharedModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MaterialModule,
        PNSFormsRoutingModule,
    ],
    declarations: COMPONENTS,
    exports     : COMPONENTS,
})
export class PNSFormsModule {
}
