export enum PNSStatusType {
  connected = 'Connected',
  disconnected = 'Disconnected',
  refreshing = 'Refreshing',
}

export interface IPNSMenuStatus {
  admin: boolean;

}

export interface IPNSStatus {
  status: PNSStatusType;

}
