import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSConnection} from '@portal-ng/PNSModels';
import * as PNSConnectionActions from '../actions/PNSConnection.action';

export interface IPNSConnectionState extends EntityState<IPNSConnection> {
  loaded: boolean;
  selectedId: string;
}

export const adapter: EntityAdapter<IPNSConnection> = createEntityAdapter<IPNSConnection>({
  selectId: (connection: IPNSConnection) => connection.id,
  sortComparer: false,
});

const initialState: IPNSConnectionState = adapter.getInitialState({
  selectedId: null,
  loaded: false,
});

export function reducer(state: IPNSConnectionState = initialState, action: PNSConnectionActions.PNSConnectionActionTypeUnion): IPNSConnectionState {
  switch (action.type) {

    default:
      return state;
  }
}

export const getSelectedConnectionId = (state: IPNSConnectionState) => state.selectedId;
export const getConnectionsLoaded    = (state: IPNSConnectionState) => state.loaded;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectConnectionIds = selectIds;

// select the dictionary of user entities
export const selectConnectionEntities = selectEntities;

// select the array of users
export const selectAllConnections = selectAll;

// select the total user count
export const selectConnectionTotal = selectTotal;
