<div id="page">

    <h1 class="mat-h1">PNS Forms</h1>
    <div *ngIf="loaded; else loading">

        <table mat-table [dataSource]="tableData" class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="topic">
                <th mat-header-cell *matHeaderCellDef> Topic </th>
                <td mat-cell *matCellDef="let element"> {{element.topic}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay" [routerLink]="'/admin/forms/edit/' + row.id"></tr>
        </table>
    </div>
    <ng-template #loading><mat-progress-spinner color="primary"></mat-progress-spinner></ng-template>
</div>
