import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {InfoComponent} from '@portal-ng/PNSCore/components/info/info.component';
import {StatusBankComponent} from '@portal-ng/PNSCore/components/status-bank/status-bank.component';
import {YouShallNotPassComponent} from '@portal-ng/PNSCore/components/you-shall-not-pass.component';
import {BareLayoutComponent} from '@portal-ng/PNSCore/layouts/bare/bare.layout';
import {PNSAuthenticationGuard} from '@portal-ng/PNSShared/guards/PNSAuthentication.guard';
import {PNSAuthorizationGuard} from '@portal-ng/PNSShared/guards/PNSAuthorization.guard';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {NotFoundPageComponent} from './components/not-found-page.component';
import {AdminLayoutComponent} from '@portal-ng/PNSCore/layouts/admin/admin.layout.component';
import {PNSWhatsNewComponent} from '@portal-ng/PNSShared/components/PNSWhatsNew/PNSWhatsNew.component';
import {SamlLoginComponent} from '@portal-ng/PNSAuth/components/saml-login/saml-login.component';
import {AuthorizeComponent} from '@portal-ng/PNSAuth/components/authorize/authorize.component';

// This part is a little confusing, in order to use nested layouts, you need to define the empty path a few times:
// once for each master layout Code, then once for the redirect to the home component. Has to be in order where default route
// comes first in list.

export const routes: Routes = [
  {
    path: '', component: BareLayoutComponent, children: [
      {path: '', redirectTo: '/home', pathMatch: 'full'},
      {path: 'login', component: SamlLoginComponent},
      {path: 'authorize', component: AuthorizeComponent},
      {path: 'logout', redirectTo: '/login'},
      {path: 'whatsNewIn/:app', component: PNSWhatsNewComponent},
      {path: 'whatsNewIn/:app/:tenant/:format', component: PNSWhatsNewComponent},
      {path: 'whatsNewIn/:app/:format', component: PNSWhatsNewComponent},
      {path: 'whatsNewIn/:app/v/:version', component: PNSWhatsNewComponent},
      {path: 'whatsNewIn/:app/v/:version/:tenant', component: PNSWhatsNewComponent},
      {path: 'whatsNewIn/:app/v/:version/:tenant/:format', component: PNSWhatsNewComponent},
      {path: 'systemStatus', component: StatusBankComponent},
      {path: '403', component: YouShallNotPassComponent},
    ],
  },
  {
    path    : '', component: AdminLayoutComponent, canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    children: [
      {path: 'home', component: DashboardComponent},
      {path: 'info', component: InfoComponent},
    ],
  },
  {
    path: 'admin', component: AdminLayoutComponent, canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard], children: [
      {path: 'users', loadChildren: () => import('@portal-ng/PNSUser/PNSUser.module').then(m => m.PNSUserModule)},
      {path: 'forms', loadChildren: () => import('@portal-ng/PNSForms/PNSForms.module').then(m => m.PNSFormsModule)},
      {path: 'applications', loadChildren: () => import('@portal-ng/PNSAdminEntities/PNSAdminEntities.module').then(m => m.PNSAdminEntitiesModule)},
      {path: 'auth', loadChildren: () => import('@portal-ng/PNSAuth/PNSAuth.module').then(m => m.PNSAuthModule)},
      {path: 'organizations', loadChildren: () => import('@portal-ng/PNSOrganization/PNSOrganization.module').then(m => m.PNSOrganizationModule)},
      {path: 'locations', loadChildren: () => import('@portal-ng/PNSLocation/PNSLocation.module').then(m => m.PNSLocationModule)},
      {path: 'tasks', loadChildren: () => import('@portal-ng/PNSAdminEntities/PNSAdminEntities.module').then(m => m.PNSAdminEntitiesModule)},
    ],
  },
  {path: '**', component: NotFoundPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})

export class CoreRoutingModule {}
