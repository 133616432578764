import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Action, MemoizedSelector, select, Store} from '@ngrx/store';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import * as fromFormTemplate from '@portal-ng/PNSForms/reducers';
import {PNSFormTemplateService} from '@portal-ng/PNSServices/PNSFormTemplate.service';
import {take} from 'rxjs/operators';
import {LoadPNSFormTemplates} from '@portal-ng/PNSForms/actions/PNSFormTemplate.action';

@Injectable()
export class PNSFormTemplateResolver implements Resolve<void> {

  constructor(private store: Store<IAppState>) {}

  entityName = 'FormTemplates';
    selectMethod: MemoizedSelector<any, any> = fromFormTemplate.getFormTemplatesLoaded;
    selectAction: Action = new LoadPNSFormTemplates(PNSFormTemplateService.defaultSearch());

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.pipe(select(this.selectMethod), take(1)).subscribe((hasLoaded) => {
      if (!hasLoaded) {
        this.store.dispatch(this.selectAction);
      } else {
        console.info(this.entityName + ' loading skipped, ' + this.entityName + ' are already loaded.');
      }
    });
  }

}
