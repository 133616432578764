import {Injectable} from '@angular/core';
import S3, {ListObjectsV2Output, Object} from 'aws-sdk/clients/s3';
import {environment} from '../../environments/environment';
import {AWSError} from 'aws-sdk/global';
import {Observable} from 'rxjs';
import {PEATreeService} from '@portal-ng/PNSServices/PEATree.service';

export interface S3File {
  type: string;
    name: string;
    body: string;
}

// tslint:disable:ban-types
@Injectable({
  providedIn: 'root'
})
export class AWSS3Service {
  S3: S3;

  constructor(private PEATrees: PEATreeService) {
    this.S3 = this.getS3ServiceObject();
  }

  getS3ServiceObject(): S3 {
    return new S3(
      {
        accessKeyId    : environment.AWS.accessKeyId,
        secretAccessKey: environment.AWS.secretAccessKey,
        region         : environment.AWS.region,
      });
  }

  getS3Object(bucketName: string, obj: Object): any {

    if (obj.Key) {
      const params = {
        Bucket: bucketName,
        Key   : obj.Key,
      };

      this.S3.getObject(params, (err, data) => {
        if (err) { console.log(err, err.stack); } else { return (data); }
      });
    } else {
    }
  }
  getS3JSONStringFromKey(bucketName: string, key: string): Observable<string> {
    const params = {
      Bucket: bucketName,
      Key   : key,
    };


    return new Observable((observer) => {
      this.S3.getObject(params, (err, data) => {
        if (err) { console.log(err, err.stack); } else if (data.ContentType === 'application/json') {

          observer.next(data.Body.toString('utf-8'));
          observer.complete();
        } else {
          const fnError: any = {
            'error': 'data incorrect format'
          };
          observer.error(JSON.stringify(fnError));
        }
      });
    });
  }

  getS3JSONStringFromObject(bucketName: string, obj: Object): Observable<string> {
    const params = {
      Bucket: bucketName,
      Key   : obj.Key,
    };

    return new Observable((observer) => {
      this.S3.getObject(params, (err, data) => {
        if (err) { console.log(err, err.stack); } else if (data.ContentType === 'application/json') {

          observer.next(data.Body.toString('utf-8'));
          observer.complete();
        } else {
          const fnError: any = {
            'error': 'data incorrect format'
          };
          observer.error(JSON.stringify(fnError));
        }
      });
    });
  }

  listBucket(bucketName: string, includeEmptyKeys: boolean = false): Observable<Object[]> {

    const params: S3.ListObjectsV2Request = {
      Bucket: bucketName,
    };

    const returnedObjects: Object[] = Array();

    return new Observable((observer) => {
      this.S3.listObjectsV2(params, (err: AWSError, results: ListObjectsV2Output) => {
        if (err) {
          observer.error(err);
        } else {
          results.Contents.forEach((result: Object) => {
            if (!includeEmptyKeys) {
              if (result.Size > 0) {
                returnedObjects.push(result);
              }
            } else {
              returnedObjects.push(result);
            }
          });
        }
        observer.next(returnedObjects);
      });
    });
  }

  // @ts-ignore
  getBucketTree(bucketName: string): PEATreeNode {
    this.PEATrees.createRootNode();

    this.listBucket(bucketName, true).subscribe((bucketList) => {
      for (const listItem in bucketList) {
        if (bucketList.hasOwnProperty(listItem)) {
          const item = bucketList[listItem];
          this.PEATrees.addItemForPath(item.Key, item);
        }
      }
    });
  }


  deleteFile(bucket: string, key: string) {
    this.S3.deleteObject({Bucket: bucket, Key: key}, (err) => {
      if (err) { console.log(err, err.stack); } else {
        console.info(key + ' deleted.'); }
    });
  }

  fileUpload(bucketName: string, file: S3File, acl: string = 'public-read') {

    const params = {
      Bucket     : bucketName,
      Key        : file.name,
      Body       : file.body,
      ACL        : acl,
      ContentType: file.type
    };
    this.S3.upload(params, (err) => {
      if (err) {
        console.log('ERROR: ', JSON.stringify(err));
        return false;
      }
      return true;
    });
  }
}
