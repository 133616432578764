import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IPNSLocationFormData} from '@portal-ng/PNSModels';

@Component({
  selector: 'portal-ng-pnslocation-form-dialog',
  templateUrl: './p-n-s-location-form-dialog.component.html',
  styleUrls: ['./p-n-s-location-form-dialog.component.scss']
})
export class PNSLocationFormDialogComponent implements OnInit {

  public selectedFormTemplates: any;


  constructor(
      public dialogRef: MatDialogRef<PNSLocationFormDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IPNSLocationFormData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  public addFormTemplate() {

  }
    public editAssignedForms() {
      this.selectedFormTemplates.forEach((ft) => {
          console.log(ft);
        });
    }
}
