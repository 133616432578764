import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {PNSAuthActions} from '@portal-ng/PNSAuth/actions';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';

import {
    AuthenticationRequired,
    AuthSetPostAuthURL,
    PNSAuthActionsUnion,
    PNSAuthActionTypes,
    PNSWhoamiError,
    PNSWhoamiRequest,
    PNSWhoamiResponse,
    SAMLRefreshTokenError,
    SAMLRefreshTokenResponse,
    SAMLTokenError,
    SAMLTokenResponse
} from '@portal-ng/PNSAuth/actions/PNSAuth.actions';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {select, Store} from '@ngrx/store';
import {SAMLTokenRequestPayload} from '@portal-ng/PNSModels';
import {of, timer} from 'rxjs';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {environment} from '../../../environments/environment';
import {PNSUXSetStatus} from '@portal-ng/PNSCore/actions/PNSUX.actions';
import {PNSStatusType} from '@portal-ng/PNSModels/PNSUX.model';

@Injectable()
export class PNSAuthEffects {

    constructor(
        private store: Store,
        private actions$: Actions<PNSAuthActionsUnion>,
        private router: Router,
        private auth: PNSAuthService,
        private pns: PNSService) {}

    loginRedirect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActions.PNSAuthActionTypes.Logout),
            tap(() => {
                this.router.navigate(['/login']);
            }),
        ), {dispatch: false}
    );

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.LoginSuccess),
            withLatestFrom(this.store.pipe(select(fromAuth.getPreAuthenticationURL)), this.store.pipe(select(fromAuth.getPostAuthenticationURL))),
            tap(([action, preAuthURL, postAuthURL]) => {
                console.info(action);
                if (preAuthURL && preAuthURL.trim()) {
                    console.info('Redirect: Preauth -> ' + preAuthURL);
                    this.router.navigate([preAuthURL]);
                } else if (postAuthURL && postAuthURL.trim()) {
                    console.log('Redirect: Postauth -> ' + postAuthURL);
                    this.router.navigate([postAuthURL]);
                } else {
                    console.log('Redirect: Default -> ' + environment.Portal.defaultLoggedInRoute);
                    this.router.navigate([environment.Portal.defaultLoggedInRoute]);
                }
            }),
            map (() => new AuthenticationRequired())
        ), {dispatch: true}
    );

    refreshTokenError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.SAMLRefreshTokenError),
            map(() => new PNSUXSetStatus({status: PNSStatusType.disconnected})),
            tap(() => console.log('SAML Refresh Token Error')))
    );

    samlAuthorizationAttempt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.SAMLTokenRequest),
            map((action) => action.payload),
            mergeMap((requestPayload: SAMLTokenRequestPayload) =>
                this.auth.attemptAuthorization(requestPayload.code).pipe(
                    mergeMap((response) => [
                        new PNSUXSetStatus({status: PNSStatusType.refreshing}),
                        new AuthSetPostAuthURL( {url: requestPayload.redirectURL}),
                        new SAMLTokenResponse(response)
                    ]),
                    catchError(() => of(new SAMLTokenError())))
            )
        )
    );

    samlRefreshTokenRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.SAMLRefreshTokenRequest),
            mergeMap(() =>
                this.auth.attemptTokenRefresh().pipe(
                    concatMap((response) => [
                        new PNSUXSetStatus({status: PNSStatusType.refreshing}),
                        new SAMLRefreshTokenResponse(response)
                    ]),
                    catchError(() => of(new SAMLRefreshTokenError()))
                )
            )
        )
    );

    samlTokenResponse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.SAMLTokenResponse),
            concatMap(() => [
                    new PNSUXSetStatus({status: PNSStatusType.connected}),
                    new PNSWhoamiRequest(),
                ]
            )
        )
    );

    samlTokenExpired$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.SAMLTokenExpired),
            mergeMap(() =>
                this.auth.attemptTokenRefresh().pipe(
                    map((response) => new SAMLTokenResponse(response)),
                    catchError(() => of(new SAMLRefreshTokenError()))),
            )
        )
    );

    codeGrantError$ = createEffect(() => this.actions$.pipe(
        ofType(PNSAuthActionTypes.SAMLTokenError),
        map(() => new PNSUXSetStatus({status: PNSStatusType.disconnected})),
        tap(() => this.router.navigate(['/login'])))
    );

    refreshError$ = createEffect(() => this.actions$.pipe(
        ofType(PNSAuthActionTypes.SAMLRefreshTokenError),
        map(() => new PNSUXSetStatus({status: PNSStatusType.disconnected})),
        tap(() => this.router.navigate(['/login'])),
        )
    );

    pnsWhoamiRequest$ = createEffect(() => this.actions$.pipe(
        ofType(PNSAuthActionTypes.PNSWhoamiRequest),
        mergeMap(() =>
            this.pns.whoami().pipe(
                map((response) => new PNSWhoamiResponse(response)),
                catchError(() => of(new PNSWhoamiError())))))
    );

    pnsWhoamiResponse$ = createEffect(() => this.actions$.pipe(
        ofType(PNSAuthActionTypes.PNSWhoamiResponse),
        map(() => new PNSAuthActions.LoginSuccess())
        )
    );

    samlAuthorizationResponse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.SAMLTokenResponse),
            map((action: SAMLTokenResponse) => this.auth.setSAMLTokens(action.payload)),
            tap(() => this.router.navigate([this.router.url]))
        ), {dispatch: false}
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PNSAuthActionTypes.Logout),
            mergeMap(() =>
                this.auth.attemptLogout().pipe(
                    map(() => console.info('Logging Out...'))
                ))), {dispatch: false}
    );

    refreshTokenResponse$ = createEffect(() => this.actions$.pipe(
        ofType(PNSAuthActionTypes.SAMLRefreshTokenResponse),
        map(() => new PNSUXSetStatus({status: PNSStatusType.connected}))
        )
    );

    authRequired$ = createEffect(() => this.actions$.pipe(
            ofType(PNSAuthActionTypes.AuthenticationRequired),
            tap(() => console.log('Establishing Refresh Process')),
            switchMap(() => timer(environment.Auth.refreshTokenInterval, environment.Auth.refreshTokenInterval).pipe(
                map(() => new PNSAuthActions.SAMLRefreshTokenRequest()))
            )
        )
    );
}
