import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSUser, IPNSVisitSummary, IPNSVisitSummarySearchResults} from '@portal-ng/PNSModels';
import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {PNSVisitService} from '@portal-ng/PNSServices/PNSVisit.service';


@Component({
  selector: 'portal-ng-pnslocation-unit-status-board',
  templateUrl: './pnslocation-unit-status-board.component.html',
  styleUrls: ['./pnslocation-unit-status-board.component.scss'],
})
export class PNSLocationUnitStatusBoardComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<IAuthState>,
    private route: ActivatedRoute,
    private visitService: PNSVisitService,
  ) {
  }
  locationID: string;
  unitID: string;
  loc$: Subscription;
  u$: Subscription;
  sum$: Subscription;
  location$: IPNSLocation;
  unit$: IPNSLocation;
  summaries$: IPNSVisitSummary[];
  summaryFetch$: Observable<IPNSVisitSummarySearchResults>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filter: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['visitNumber', 'pnsStatus', 'pnsDischargeDisposition', 'Room', 'Name', 'MRN', 'compositeDischargeTime'];

  authenticatedUser$: Subscription;
  private authUser: Partial<IPNSUser>;

  polledSummaries$: Observable<IPNSVisitSummary[]>;
  load$ = new BehaviorSubject('');

  static getVisitStatus(x: IPNSVisitSummary): string {
    return x.visitAttributes.filter((summary) => summary.attributeId === 6)[0].valueCode.title;
  }

  static getInProgress(x: IPNSVisitSummary): boolean {
    if (x.taskManagements !== undefined && x.taskManagements !== null) {

      const inProgress = x.taskManagements.filter((task) => task.taskStatus === 'InProgress');

      if (inProgress.length > 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.locationID = this.route.snapshot.params['locationID'];
    this.unitID = this.route.snapshot.params['unitID'];

    this.loc$ = this.store.pipe(select(fromLocation.getLocationByID(this.locationID))).subscribe((loc) => {
      this.location$ = loc;
    });

    this.u$ = this.store.pipe(select(fromLocation.getLocationByID(this.unitID))).subscribe((unit) => {
      this.unit$ = unit;
    });

    this.summaryFetch$ = this.visitService.getVisitSummariesByUnitID(this.locationID, this.unitID);

    this.polledSummaries$ = this.load$.pipe(
      switchMap(() => {
        return timer(0, 10000).pipe(
          concatMap(() => this.summaryFetch$),
          map((response) => response.patientSummaries));
      }));

    this.refresh();

    this.authenticatedUser$ = this.store.pipe(select(fromAuth.getAuthenticatedUser))
      .subscribe((authUser) => {
        this.authUser = authUser;
      });
  }

  getStatus(x: IPNSVisitSummary): string {
    if (PNSLocationUnitStatusBoardComponent.getInProgress(x) === true) {
      return 'InProgress';
    } else {
      return PNSLocationUnitStatusBoardComponent.getVisitStatus(x);
    }
  }

  ngOnDestroy() {
    // this.user$.unsubscribe();
  }

  refresh() {
    this.load$.next('');
  }
}
