import {Action} from '@ngrx/store';
import {IPNSLocation, IPNSLocationSearch, IPNSLocationSearchResults} from '@portal-ng/PNSModels';

export enum PNSLocationActionTypes {
  AddPNSLocation        = '[PNSLocation] Add',
  AddPNSLocationSuccess = '[PNSLocation] Add Success',

  RemPNSLocationConfirm = '[PNSLocation] Remove Confirm',
  RemPNSLocationCancel  = '[PNSLocation] Remove Cancel',
  RemPNSLocation        = '[PNSLocation] Remove',
  RemPNSLocationSuccess = '[PNSLocation] Remove Success',

  LoadPNSLocations        = '[PNSLocation] Fetch',
  LoadPNSLocationsSuccess = '[PNSLocation] Fetch Success',

  LoadPNSProviders        = '[PNSProvider] Fetch',
  LoadPNSProvidersSuccess = '[PNSProvider] Fetch Success',

  EditPNSProviders        = '[PNSProvider] Edit',
  EditPNSProvidersSuccess = '[PNSProvider] Edit Success',

  EditPNSLocation         = '[PNSLocation] Edit',
  EditPNSLocationSuccess  = '[PNSLocation] Edit Success',
}

export class AddPNSLocation implements Action {
  readonly type = PNSLocationActionTypes.AddPNSLocation;
  constructor(public payload: Partial<IPNSLocation> ) {
  }
}

export class AddPNSLocationSuccess implements Action {
  readonly type = PNSLocationActionTypes.AddPNSLocationSuccess;
  constructor(public payload: { location: IPNSLocation }) {
  }
}

// Editing
export class EditPNSLocation implements Action {
  readonly type = PNSLocationActionTypes.EditPNSLocation;
  constructor(public payload: Partial<IPNSLocation>) {}
}

export class EditPNSLocationSuccess implements Action {
  readonly type = PNSLocationActionTypes.EditPNSLocationSuccess;
  constructor(public payload: {location: IPNSLocation} ) {}
}
// Loading

export class LoadPNSLocations implements Action {
  readonly type = PNSLocationActionTypes.LoadPNSLocations;
  constructor(public payload: IPNSLocationSearch) {}
}

export class LoadPNSLocationsSuccess implements Action {
  readonly type = PNSLocationActionTypes.LoadPNSLocationsSuccess;
  constructor(public payload: {locations: IPNSLocationSearchResults} ) {}
}

export type PNSLocationActionTypeUnion =
  AddPNSLocation
  | AddPNSLocationSuccess
  | EditPNSLocation
  | EditPNSLocationSuccess
  | LoadPNSLocations
  | LoadPNSLocationsSuccess;
