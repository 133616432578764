import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PNSAuthenticationGuard} from '@portal-ng/PNSShared/guards/PNSAuthentication.guard';
import {PNSAuthorizationGuard} from '@portal-ng/PNSShared/guards/PNSAuthorization.guard';
import {PNSLocationResolver} from '@portal-ng/PNSShared/resolvers/PNSLocation.resolver';
import {PNSRoleResolver} from '@portal-ng/PNSShared/resolvers/PNSRole.resolver';
import {PNSUserResolver} from '@portal-ng/PNSShared/resolvers/PNSUser.resolver';
import {PNSUserAuditComponent} from '@portal-ng/PNSUser/components/pnsuser-audit/pnsuser-audit.component';
import {PNSUserFormComponent} from '@portal-ng/PNSUser/components/pnsuser-form/pnsuser-form.component';
import {PNSUserListComponent} from '@portal-ng/PNSUser/components/pnsuser-list/pnsuser-list.component';

export const userRoutes: Routes = [
  {
    path: 'edit/:id',
    component: PNSUserFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    resolve: [PNSRoleResolver, PNSUserResolver, PNSLocationResolver],
    pathMatch: 'full',
    data: {editMode: true},
  },
  {
    path: ':id/audit',
    component: PNSUserAuditComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    resolve: [PNSRoleResolver, PNSUserResolver, PNSLocationResolver],
    pathMatch: 'full',
  },
  {
    path: 'add',
    component: PNSUserFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    resolve: [PNSRoleResolver, PNSUserResolver, PNSLocationResolver],
    pathMatch: 'full',
  },
  {
    path: 'list',
    component: PNSUserListComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    resolve: [PNSLocationResolver, PNSUserResolver],
  },
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule],
})

export class PNSUserRoutingModule {}
