import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSUser} from '@portal-ng/PNSModels/PNSUser.model';
import * as PNSUserActions from '../actions/PNSUser.action';

export interface IUserState extends EntityState<IPNSUser> {
  selectedUserId: string;
  PNSUsers: IPNSUser[] | null;
  loaded: boolean;
}

export const adapter: EntityAdapter<IPNSUser> = createEntityAdapter<IPNSUser>({
  selectId: (user: IPNSUser) => user.id,
  sortComparer: false,
});

const initialState: IUserState = adapter.getInitialState({
  selectedUserId: null,
  PNSUsers: null,
  loaded: false,
});


export function reducer(state: IUserState = initialState, action: PNSUserActions.PNSUserActionsUnion): IUserState {
  switch (action.type) {
    case PNSUserActions.PNSUserActionTypes.LoadPNSUsers:
      return {
        ...state,
      };

    case PNSUserActions.PNSUserActionTypes.LoadPNSUsersSuccess:
      const updateState = {...state,
        loaded: true,
      };
      return adapter.setAll(action.payload.response.results, updateState);

    case PNSUserActions.PNSUserActionTypes.RemovePNSUserSuccess:
      return adapter.removeOne(Number.parseInt(action.payload.id, 10), state);

    case PNSUserActions.PNSUserActionTypes.EditPNSUserSuccess:
      const changes = action.payload.user;

      const id = Number.parseInt(changes.id, 10);
      return adapter.updateOne({id, changes}, state);

    case PNSUserActions.PNSUserActionTypes.AddPNSUserSuccess:
      return adapter.addOne(action.payload.user, state);

    default:
      return state;
  }
}
