import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {PNSUserActionsUnion} from '@portal-ng/PNSUser/actions/PNSUser.action';

import * as fromRole from './PNSRole.reducer';
import {IRoleState} from './PNSRole.reducer';
import * as fromUser from './PNSUser.reducer';
import {IUserState} from './PNSUser.reducer';

export interface IState {
  users: fromUser.IUserState;
  roles: fromRole.IRoleState;
}

export const reducers: ActionReducerMap<IState, PNSUserActionsUnion> = {
  users: fromUser.reducer,
  roles: fromRole.reducer,
};

export const getUserEntityState = createFeatureSelector<fromUser.IUserState>('users');

export const getUserModuleState = createFeatureSelector<IState>('users');
export const getRolesState = createSelector(getUserModuleState, (state: IState) => state.roles);
export const getUserState = createSelector(getUserModuleState, (state: IState) => state.users);

export const getEntityById = (id: string) => (state: IState) => state.users[id];
export const getUserEntityById = (id: string) => createSelector(getUserModuleState, getEntityById(id));

export const {selectIds, selectEntities, selectAll} = fromUser.adapter.getSelectors(getUserEntityState);
export const getUserEntities = createSelector(getUserEntityState, selectEntities);
export const getAllUsers = createSelector(getUserEntityState, selectAll);
export const getUsersByLocationID = (locID: string) => createSelector(getAllUsers, (users) => users.filter((x) => {
  if (x.locationIds !== undefined && x.locationIds.some((locs) => locs.toString() === locID.toString())) {
    return x;
  }}));

export const getUsersLoaded = createSelector(getUserState, (state: IUserState) => state.loaded);
export const getRoles       = createSelector(getRolesState, (state: IRoleState) => state.PNSRoles);
export const getRolesLoaded = createSelector(getRolesState, (state: IRoleState) => state.loaded);

export const getRolesByName = (name: string) => createSelector(getRoles, (rol) => rol.filter((x) => x.name === name));

export const getUserById = (id: string) => createSelector(getUserEntities, (user) => user[id]);
export const getUserByUsername = (username: string) => createSelector(getAllUsers, (user) => user.filter((x) => x.username === username));

export const getAllUsersArray = createSelector(getUserEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
