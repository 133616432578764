import {IPNSSearch} from '@portal-ng/PNSModels/PNSSearch.model';

export enum LocationType {
  FACILITY = 'FACILITY',
  PHARMACY = 'PHARMACY',
  UNIT = 'UNIT',
  PRODUCT= 'PRODUCT',
}
export interface IPNSLocationSearch extends IPNSSearch {
  type: LocationType;
  organizationId?: string | null;
  locationId?: string | null;
  operational?: boolean | null;
  name?: string | null;
  eSchediulingEnabled?: boolean | null;
  parentId?: string | null;
  distance?: number | null;
  latitude?: number | null;
  populateConfiguration?: boolean | null;
  longitude?: number | null;

}
