import {Injectable} from '@angular/core';
import {IPNSAppIdentifier, IPNSWhatsNewItem} from '../PNSModels';
import {Object} from 'aws-sdk/clients/s3';
import {AWSS3Service} from '@portal-ng/PNSServices/AWS-S3.service';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import c2dLatest from '../../assets/release-notes/c2d/latest.json';
import posLatest from '../../assets/release-notes/pos/latest.json';
import portalLatest from '../../assets/release-notes/portal/latest.json';
import runnerLatest from '../../assets/release-notes/runner/latest.json';
// tslint:disable:ban-types
// @ts-ignore
@Injectable({
    providedIn: 'root',
})

export class PNSWhatsNewService {
    items$: Observable<Object[]>;

    constructor(private s3: AWSS3Service) {}

    static getPathFromAttributes(appIdentifier: string, buildType: string, version: string): string {
        let dirName: string;

        switch (appIdentifier) {
            case IPNSAppIdentifier.announcement:
                dirName = 'Announcement';
                break;
            case IPNSAppIdentifier.warning:
                dirName = 'Warning';
                break;
            case IPNSAppIdentifier.runner:
                dirName = 'Runner';
                break;
            case IPNSAppIdentifier.c2d:
                dirName = 'C2D';
                break;
            case IPNSAppIdentifier.pos:
                dirName = 'POS';
                break;
            case IPNSAppIdentifier.portal:
                dirName = 'Portal';
                break;
            default:
                dirName = appIdentifier;
                break;
        }

        return (dirName + '/' + buildType + '/' + version + '.json');
    }

    static getPathFromDocument(formDocument: IPNSWhatsNewItem) {
        let dirName     = '/';
        const buildType = formDocument.buildType.toLowerCase();

        switch (formDocument.appIdentifier) {
            case IPNSAppIdentifier.runner:
                dirName = 'Runner';
                break;
            case IPNSAppIdentifier.c2d:
                dirName = 'C2D';
                break;
            case IPNSAppIdentifier.pos:
                dirName = 'POS';
                break;
            case IPNSAppIdentifier.portal:
                dirName = 'Portal';
                break;
            case IPNSAppIdentifier.announcement:
                if (formDocument.active === true) {
                    dirName = 'Announcement/Active';
                } else {
                    dirName = 'Announcement';
                }
                break;
        }
        return dirName + '/' + buildType.toUpperCase() + '/' + formDocument.versionNumber + '.json';
    }

    getWhatsNewItem(app: string, buildType: string, version: string): Observable<string> {
        return this.s3.getS3JSONStringFromObject(environment.AWS.releaseNoteBucket, {Key: PNSWhatsNewService.getPathFromAttributes(app, buildType, version)});
    }

    normalizeAppCase(app: String) {
        const lowerCaseApp = app.toLowerCase();
        switch (lowerCaseApp) {
            case 'c2d':
                return 'C2D';
            case 'pos':
                return 'POS';
            case 'runner':
                return 'Runner';
            case 'portal':
                return 'Portal';
            default:
                return 'Unknown';
        }
    }


    // compiler options are on to make this object import.. can console.log it using JSON.stingify and it generates the string)
    //    "resolveJsonModule": true,
    //     "esModuleInterop": true,
    //     "allowSyntheticDefaultImports": true

    // am passing JSON string because we're replacing an old web call with local json and don't want to refactor the whole dang thing)

    //  import latestItem from '../../assets/items/latest.json';
    getLatestItem(itemType: string) {
        switch (itemType) {
            case 'type1':
                return of (JSON.stringify(portalLatest));
        }
    }
    // then calling
    // this.service.getLatestItem(this.itemType).subscribe((result) => { ...


    getLatestWhatsNewItem(app: string, build: string): Observable<string> {
        switch (app.toLowerCase()) {
            case 'c2d':
                return of(JSON.stringify(c2dLatest));
            case 'pos':
                return of(JSON.stringify(posLatest));
            case 'portal':
                return of(JSON.stringify(portalLatest));
            case 'runner':
                return of(JSON.stringify(runnerLatest));
            default:
                return of(JSON.stringify(c2dLatest));
        }
    }
}
