<div id="page">
  <h1 class="mat-h1">Add Generosity Provider</h1>
  <form #genProForm="ngForm" name="form" (ngSubmit)="addGenerosityProvider()">

  <mat-form-field class="full-width">
    <input matInput placeholder="Provider Name" [(ngModel)]="addGenPro$.name"  name="providerName">
    <mat-hint align="start"><strong>The provider's name</strong> </mat-hint>
    <mat-hint align="end">{{addGenPro$.name.length}} / 256</mat-hint>
    <mat-error *ngIf="genProForm.hasError('name') && !genProForm.hasError('name')">
      Please enter a valid Location name.
    </mat-error>
    <mat-error *ngIf="genProForm.hasError('required')">
      Location name is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br /><br />

  <mat-form-field  class="full-width">
    <textarea matInput placeholder="Provider Description" [(ngModel)]="addGenPro$.description" name="description"  ></textarea>
  </mat-form-field>
  <br />  <br />

  <mat-card>
    <mat-card-header>
      <mat-card-title><mat-icon svgIcon="location_on"></mat-icon> Provider Address</mat-card-title>
  </mat-card-header>
    <mat-card-content>
  <mat-form-field class="full-width">
    <input required matInput placeholder="123 Anywhere Lane" [(ngModel)]="addGenPro$.address.street1" name="street1"/>
    <mat-hint align="start">The Provider's address line.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input matInput placeholder="Unit 2" [(ngModel)]="addGenPro$.address.street2" name="street2"/>
    <mat-hint align="start">The Provider's second address line.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input required matInput placeholder="Anywhere" [(ngModel)]="addGenPro$.address.city" name="city"/>
    <mat-hint align="start">The Provider's city.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input required matInput placeholder="NJ" [(ngModel)]="addGenPro$.address.state" name="state"/>
    <mat-hint align="start">The Provider's state.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input required matInput placeholder="08502" [(ngModel)]="addGenPro$.address.zip" name="zip"/>
    <mat-hint align="start">The Provider's zipcode.</mat-hint>
  </mat-form-field>

      <div *ngIf="this.addrValidated">
        <mat-form-field class="full-width" appearance="outline" >
          <input readonly required class="inline"  matInput placeholder="" [(ngModel)]="addGenPro$.address.latitude" name="lat"/>
          <input readonly required class="inline" matInput placeholder="" [(ngModel)]="addGenPro$.address.longitude" name="lng"/>
          <mat-hint align="start">The Provider's Latitude and Longitude.</mat-hint>
        </mat-form-field>
        <br /><br />
        <mat-form-field class="full-width" appearance="outline">
          <input readonly  matInput placeholder="" [(ngModel)]="addGenPro$.timeZone" name="timezone"/>
          <mat-hint align="start">The Provider's Timezone.</mat-hint>
        </mat-form-field>
      </div>



    </mat-card-content>
    <mat-card-actions>
      <button mat-button type='button' (click)="validateAddress(genProForm)">VALIDATE ADDRESS</button>
    </mat-card-actions>
  </mat-card>
  <br />  <br />

  <mat-form-field class="full-width">
    <input matInput placeholder="Phone Number" [(ngModel)]="addGenPro$.phoneNumber"  name="providerPhoneNumber">
    <mat-hint align="start"><strong>The service phone number</strong> </mat-hint>
    <mat-error *ngIf="genProForm.hasError('servicePhoneNumber') && !genProForm.hasError('providerPhoneNumber')">
      Please enter a valid provider phone number.
    </mat-error>
    <mat-error *ngIf="genProForm.hasError('required')">
      Provider Phone Number is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <br /> <br />

  <mat-form-field class="full-width">
  <input matInput placeholder="Email Address" [(ngModel)]="addGenPro$.emailAddress"  name="providerEmailAddress">
  <mat-hint align="start"><strong>The service email address</strong> </mat-hint>
  <mat-error *ngIf="genProForm.hasError('providerEmailAddress') && !genProForm.hasError('providerEmailAddress')">
    Please enter a valid provider email address.
  </mat-error>
  <mat-error *ngIf="genProForm.hasError('required')">
    Provider email address is <strong>required</strong>
  </mat-error>
</mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input matInput placeholder="Provider URL" [(ngModel)]="addGenPro$.url"  name="providerUrl">
    <mat-hint align="start"><strong>The provider URL</strong> </mat-hint>
    <mat-error *ngIf="genProForm.hasError('providerURL') && !genProForm.hasError('providerURL')">
      Please enter a valid provider URL.
    </mat-error>
    <mat-error *ngIf="genProForm.hasError('required')">
      Provider url is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br />  <br />
  <h1 class="mat-h1">Provider Services</h1>
  <mat-accordion class="example-headers-align">

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Residential Program</mat-panel-title>
      <mat-panel-description>
        Residential Program
        <mat-icon svgIcon="home"></mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>The shelter operates under an Operating Certificate from the New York State Office of Children and Family Services. The shelter is at a confidential location and the safety and privacy of our residents are some of our primary concerns. We are able to shelter up to 20 individuals, including women and men in crisis and their children. Usually each family has their own bedroom and bathroom. All residents share kitchen and dining areas, living room and recreational space. While we’re unable to provide housing for pets, we can often make other arrangements to have a pet temporarily housed at one of our partner animal shelters while their owner is in our shelter.</p>
  </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{addGenPro$.services[0].name}}</mat-panel-title>
        <mat-panel-description>
          {{addGenPro$.services[0].description}}
          <mat-icon [svgIcon]="addGenPro$.services[0].category"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field class="full-width">
        <input matInput placeholder="Service Name" [(ngModel)]="addGenPro$.services[0].name"  name="serviceName">
        <mat-hint align="start"><strong>The service name</strong> </mat-hint>
        <mat-hint align="end">{{addGenPro$.name.length}} / 256</mat-hint>
        <mat-error *ngIf="genProForm.hasError('serviceName') && !genProForm.hasError('serviceName')">
          Please enter a valid service name.
        </mat-error>
        <mat-error *ngIf="genProForm.hasError('required')">
          Service name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <br /><br />
      <mat-form-field  class="full-width">
        <textarea matInput [(ngModel)]="addGenPro$.services[0].description" name="description" placeholder="Service Description"></textarea>
      </mat-form-field>
      <br /><br />
      <mat-form-field  class="full-width">
      <textarea matInput [(ngModel)]="addGenPro$.services[0].cost" name="cost" placeholder="Service Cost"></textarea>
      </mat-form-field>
      <br /><br />
      <mat-form-field class="full-width">
        <input matInput placeholder="Phone Number" [(ngModel)]="addGenPro$.services[0].phoneNumber"  name="phoneNumber">
        <mat-hint align="start"><strong>The service phone number</strong> </mat-hint>
        <mat-error *ngIf="genProForm.hasError('servicePhoneNumber') && !genProForm.hasError('servicePhoneNumber')">
          Please enter a valid service phone number.
        </mat-error>
        <mat-error *ngIf="genProForm.hasError('required')">
          Service Phone Number is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <br /><br />
      <mat-form-field class="full-width">
        <input matInput placeholder="Email Address" [(ngModel)]="addGenPro$.services[0].emailAddress"  name="emailAddress">
        <mat-hint align="start"><strong>The service email address</strong> </mat-hint>
        <mat-error *ngIf="genProForm.hasError('serviceEmailAddress') && !genProForm.hasError('serviceEmailAddress')">
          Please enter a valid service email address.
        </mat-error>
        <mat-error *ngIf="genProForm.hasError('required')">
          Service name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <br /><br />

      <h3>Notes</h3>
      <div class='notes' *ngFor="let note of addGenPro$.services[0].notes">
        <h6>{{note.author}} wrote on {{note.created | date}}...</h6>
        <p>{{note.note}}</p>
      </div>
      <h3>Attributes</h3>
      <table>
        <thead>
        <tr>
          <th>Attribute</th>
          <th>Value</th>
        </tr>
        </thead>
      <tbody *ngFor="let attribute of addGenPro$.services[0].attributes">
          <tr>
            <td>{{attribute.key}}</td>
            <td>{{attribute.value}}</td>
          </tr>
      </tbody>
        </table>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Transportation</mat-panel-title>
        <mat-panel-description>
          Transportation to and from shelter and appointments
          <mat-icon  svgIcon="directions_bus"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>This is the primary content of the panel.</p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Personal Items</mat-panel-title>
        <mat-panel-description>
          Transportation to and from shelter and appointments
          <mat-icon svgIcon="shopping_cart"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>This is the primary content of the panel.</p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Clothing Items</mat-panel-title>
        <mat-panel-description>
          Transportation to and from shelter and appointments
          <mat-icon svgIcon="store_mall_directory"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>This is the primary content of the panel.</p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Counciling</mat-panel-title>
        <mat-panel-description>
          Transportation to and from shelter and appointments
          <mat-icon svgIcon="mode_comment"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>This is the primary content of the panel.</p>
    </mat-expansion-panel>

  </mat-accordion>
  <br />  <br />
  <div class="form-actions">
    <button type="button" mat-raised-button [routerLink]="['/admin/locations/facility/list']">Cancel Adding Generosity Provider</button>
    <button type="submit" mat-raised-button color="primary" disabled cdkFocusInitial [disabled]="!genProForm.valid" >Add Generosity Provider</button>


  </div>
</form>
</div>
