export enum IPNSWebFormFieldTypes {
  STRING      = 'STRING',
  BOOLEAN     = 'BOOLEAN',
  INTEGER     = 'INTEGER',
  DOUBLE      = 'DOUBLE',
  FLOAT       = 'FLOAT',
  STRINGARRAY = 'STRINGARRAY',
}

export enum IPNSWebFormFieldStatus {
  Future     = 'FUTURE',
  Present    = 'PRESENT',
  Deprecated = 'DEPRECATED',
}

export enum IPNSWebFieldStates {
  UNKNOWN    = 'UNKNOWN',
  CONFIGURED = 'CONFIGURED',
  WARN       = 'WARN',
  ERROR      = 'ERROR',
}

export interface IPNSWebFormItem {
  name: string;
  parent?: string;
  color?: string;
  error?: string;
  status: IPNSWebFormFieldStatus;
  defaultValue?: any;
  description: string;
  displayName?: string;
  requiredRoles?: string[];
  state?: string;
  type: string;
  codeCategory?: string;
  value?: any;
}

export interface IPNSWebFormGroup {
  name: string;
  description: string;
  requiredRoles?: string[];
  error?: string;
  disabled?: boolean;
  state?: string;
  status?: string;
  iconColor?: string;
  icon?: string;
  configurationItems: IPNSWebFormItem[];
}

export interface IPNSWebForm {
  name: string;
  description: string;
  configurationGroups: IPNSWebFormGroup[];
}
