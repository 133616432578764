import {IPNSWebForm, IPNSWebFormFieldStatus, PNSLocationConfigurationPermissionLevel} from '@portal-ng/PNSModels';
/* tslint:disable:max-line-length */

export const PNSLocationConfiguration: IPNSWebForm = {
         name               : 'Location Configuration',
         description        : 'Configuration items related to the operation of the location.',
         configurationGroups: [
           {
             name              : 'Pseudoephedrine Settings',
             description       : 'Settings for Pseudoephedrine workflow.',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'verifyPsePurchase',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'This configuration informs the POS to follow the PSE workflow, when a PSE product is scanned. \n' +
                   'This workflow includes scanning a driver\'s licenses and checking if the person is \n' +
                   'allowed to make the purchases" ',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'pseVerifcationOverride',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'This configuration informs the POS make an \'override\' button available if a PSE verification fails.\n' +
                   'Tapping this button will allow the purchase to be completed regardless of the verification step.',
                 type       : 'BOOLEAN',
               },
             ],
           },
           {
             name              : 'Point of Sale Settings',
             description       : 'Settings for POS workflow.',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name        : 'posEnabled',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'This must be set to true if the location is going to be use the PEA POS. This is also how C2D determines which ' +
                   'location to send the POS during SSO.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'rxProductID',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'Erply Product ID for this location',
                 type        : 'TEXT',
               },
               {
                 name        : 'preferScannedPriceForRx',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'When a Rx is scanned, the price in the barcode may be different from the price returned by the Rx System.' +
                   'When this configuration is set to true, the price on the barcode is used. If false, the price from the Rx System is used.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'verifyCheckPayment',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'This configuration adds a check verification workflow to the checkout process if a check payment is made.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'embeddedPOS',
                 status      : IPNSWebFormFieldStatus.Deprecated,
                 defaultValue: null,
                 description : 'This feature was used to distinguish locations that used PEA\'s POS app vs. locations that used the POS \n' +
                   'features in the old PNS application. All locations that use PEA\'s POS should have this set to true.  ',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'promptForCustomerDesgination',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'When true, this causes the POS to classify the customer as either a Patient or Employee during checkout' +
                   '(Transaction Task).',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'autoSelectCreditCardOnFileAgreement',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'In the POS, if a purchase is being completed with a Card On File payment, this setting controls if the ' +
                   'agreement starts out agreed to or not. If false, the customer need to tap and "agree" to the Card On File agreement.' +
                   ' If true, the agreement will already be selected. If however, the transaction includes a subscription, this configuration' +
                   'is ignored, and the agreement will automatically be selected.\n',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'restrictVoidToManagers',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'When this is true, only users with the role "Store Manager" can void a transaction in the POS. If true, all' +
                   'users can void transactions.\n',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'autoPrintReceipt',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'When true, the POS will print a receipt when a purchase is completed without user intervention. If false, ' +
                   'the user needs to hit the Print Receipt button.\n',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'posEmployeeGroupId',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'The ID of the group used to identify Erply customers that are Employees. This value should be set during Erply ' +
                   'setup and never changed.  See the gateway service Create / Retrieve Erply Customer ID',
                 type        : 'TEXT',
               },
               {
                 name        : 'posPatientGroupId',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'The ID of the group used to identify Erply customers that are patients. This value should be set during Erply ' +
                   'setup and never changed.  See the gateway service Create / Retrieve Erply Customer ID',
                 type        : 'TEXT',
               },
               {
                 name        : 'posNonPatientGroupId',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'The ID of the group used to identify Erply customers that are not patients. This value should be set during Erply ' +
                   'setup and never changed. See the gateway service Create / Retrieve Erply Customer ID',
                 type        : 'TEXT',
               },
               {
                 name        : 'posPayrollDeductPaymentId',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: null,
                 description : 'If a transaction payment is type PAYROLL, this ID is used in Erply to specify the payroll deduct account. This ' +
                   'value should be set during Erply setup and never changed. * See the gateway service POS Payment Service - V3',
                 type        : 'TEXT',
               },
               {
                 name        : 'useEMV',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'For PayGuardian locations, this determines if the payment device should be prompted request an EMV ' +
                   'transaction. If false, a card swipe will be prompted.\n',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'enableWebPayments',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'Make the "Manual Payment" button enabled in POS.',
                 type        : 'BOOLEAN',
               },
               {
                 name       : 'scanIdControlledSubstanceOverride',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Allows location to bypass the scanning of an ID when selling controlled substances',
                 type       : 'BOOLEAN',
               },
               {
                 name        : 'paymentGateway',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: 0,
                 description : 'The object that stores the payment gateway details.',
                 type        : 'GATEWAY',
               },
             ],
           },
           {
             name              : 'RX Notification Sale Settings',
             description       : 'Controls if patient can be notified electronically about their prescription..',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name        : 'rxNotificationEnabled',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'Controls if patient can be notified electronically about their prescription.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'rxNotificationMessage',
                 status      : IPNSWebFormFieldStatus.Deprecated,
                 defaultValue: 'default RX Notification',
                 description : ' The SMS message the patient receives when their prescriptions are ready for pickup.',
                 type        : 'TEXTAREA',
               },
             ],
           },
           {
             name              : 'C2D Settings',
             description       : 'Settings for the C2D Application',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name        : 'c2dEnabled',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'This must be set to true if the location is going to be use the PEA C2D.',
                 type        : 'BOOLEAN',
               },
               {
                 name       : 'permissionTypes',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Types of Permissions',
                 type       : 'STRINGARRAY',
               },
               {
                 name        : 'displaySSN',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'When true, C2D will display the SSN of the patient under the Patient Profile. If set to false, a masked version ' +
                   'will be displayed (*** ** ****).\n',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'manualRxCountTracking',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'For locations using the C2D \'classic\' column set, this controls if the "# of Rx" column is present.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'thirdPartyAppointmentFollowupDetails',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'This enables the appointment details to be sent in real-time to a third party application (integration)',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'operationalUnitsOnly',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'Controls the default search in C2D. Should all units or all operational units be included in the search.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'includeLyftWorkflow',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: false,
                 description : 'Enables Lyft related features in C2D.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'transportationProviders',
                 /* Arrays need a display title for the fieldset */
                 displayName : 'Transporation Providers',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: 'Uber: www.uber.com',
                 description : 'The list of providers available under the Transportation Tracking Task.',
                 type        : 'STRINGARRAY',
               },
               {
                 name        : 'unitAssignmentEnabled',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'Enables assigning users to units in C2D',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'followUpUnableToServeFeedbackEnabled',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'This enables unable to serve reasons to be sent back to a locations IT team.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'enableBulkUserAssignment',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'If true, C2D will allow bulk assignment of a user to many patients.',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'showPCPAffiliation',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: true,
                 description : 'If true, C2D will display the affiliation of the PCP in the application (organization affiliation)',
                 type        : 'BOOLEAN',
               },
               {
                 name        : 'readmissionsDays',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: 30,
                 description : 'Specifies the number of days to use for a readmission calculation. When specified, readmissions are calculated ' +
                   'by the system.',
                 type        : 'INTEGER',
               },
               {
                 name        : 'permissionLevel',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: PNSLocationConfigurationPermissionLevel.Visit,
                 description : 'Entity level where the permission is for this location',
                 type        : 'PERMISSION_LEVEL',
               },
               {
                 name        : 'numberOfMinBeforeExpectedDischargeNotify',
                 status      : IPNSWebFormFieldStatus.Present,
                 defaultValue: 120,
                 description : 'When the "red dot" should appear in the discharge status column.',
                 type        : 'INTEGER',
               },
             ],
           },
           {
             name              : 'Misc Deprecated Settings',
             description       : 'Random Deprecated Settings.',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'charityCare',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'When a Rx is scanned, the price in the barcode may be different from the price returned by the Rx System.' +
                   'When this configuration is set to true, the price on the barcode is used. If false, the price from the Rx System is used.',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'allowFamilyPickup',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'The allowFamilyPickup setting seems to be no longer read by either the client or server side.\n',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'embeddedPOS',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'This feature was used to distinguish locations that used PEA\'s POS app vs. locations that used the POS \n' +
                   'features in the old PNS application. All locations that use PEA\'s POS should have this set to true.  ',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'defaultOTCProductId',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 // For clients with integration, when a product is not found leveraging the upc
                 // code, we need to default an OTC product
                 description: 'The defaultOTCProductId setting seems to be no longer read by either the client or server side.',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'internalReferralValues',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'List of internal referral types, these are included on the Internal Referral column set of C2D.',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'numberOfRetries',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'Probably was intended for tracking how many retries a subscription should try before failing. Does not seem to be read anyplace.',
                 type       : 'INTEGER',
               },
             ],
           },
           {
             name              : 'Loyalty Points Settings',
             description       : 'This enables loyalty point behavior in the POS.',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'loyaltyPointsEnabled',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'This enables loyalty point behavior in the POS.',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'loyaltyPointsEarnedPerDollar',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Dictates how many loyalty points are earned for each amount' +
                   'spent. For example, if every $2 spent earns 1 loyalty point, 0.5 will be set below.',
                 type       : 'DOUBLE',
               },
               {
                 name       : 'posLoyaltyPointsPromotionId',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The ID of the campaign / promotion in ERPLY where rules based on earned loyalty points are specified.',
                 type       : 'DOUBLE',
               },
               {
                 name       : 'posLoyaltyPointsCouponId',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The ID of the coupon rule associated with the loyalty promotion in Erply',
                 type       : 'DOUBLE',
               },
               {
                 name       : 'loyaltyPointsDiscountThreshold',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The number of earned loyalty points needed to qualify for the loyalty point discount',
                 type       : 'DOUBLE',
                 // LONG
               },
               {
                 name       : 'loyaltyPointsDiscountAmount',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The discount amount given, when the loyaltyPoints reaches a certain threshold',
                 type       : 'DOUBLE',
                 // BigDecimal
               },
             ],
           },
           {
             name              : 'Email Codes',
             description       : 'Codes for various PNS Email Messages',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'shippedEmailCode',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Email Code that is used when items are shipped to a customer.',
                 type       : 'TEXTAREA',
                 // EMAIL
               },
               {
                 name       : 'failedEmailCode',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Email Code that is used when items fail to ship to a customer.',
                 type       : 'TEXTAREA',
                 // EMAIL
               },
               {
                 name       : 'processingEmailCode',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Email Code used to inform a customer that a shipment is being processed.',
                 type       : 'TEXTAREA',
                 // EMAIL
               },
               {
                 name       : 'paymentFailureEmailCode',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'If a subscription payment fails, this email Code is sent.',
                 type       : 'TEXTAREA',
                 // EMAIL
               },
               {
                 name       : 'subscriptionBrandName',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The name of the company providing subscriptions, used in email Codes.',
                 type       : 'TEXT',
               },
               {
                 name       : 'subscriptionAdminEmail',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The email for help in subscription emails.',
                 type       : 'EMAIL_ADDRESS',
                 // EMAIL ADDRESS
               },
             ],
           },
           {
             name              : 'Receipt Settings',
             description       : 'Settings for POS Receipts',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'receiptReturnPolicy',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Return policy printed on the receipt.',
                 type       : 'TEXTAREA',
               },
               {
                 name       : 'receiptThankYouStatement',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Thank you statement printed on receipt.',
                 type       : 'TEXTAREA',
               },
             ],
           },
           {
             name              : 'C2D - POS Application Links',
             description       : 'Location ID Correlation between POS and C2D',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'c2dApplicationLocationId',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The location if of the C2D Application.',
                 type       : 'TEXT',
               },
               {
                 name       : 'posApplicationLocationId',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'The location of the POS Application.',
                 type       : 'TEXT',
               },
             ],
           },
           {
             name              : 'Chain of Custody Settings',
             description       : 'Settings for Chain of Custody',
             iconColor         : 'primary',
             icon              : 'check-circle',
             requiredRoles     : ['Admin'],
             configurationItems: [
               {
                 name       : 'custodialOrganizations',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'Organizations with custody jurisdiction',
                 type       : 'TEXTAREA',
               },
               {
                 name       : 'custodialCouriers',
                 status     : IPNSWebFormFieldStatus.Deprecated,
                 description: 'Couriers with access to COC at location.',
                 type       : 'TEXTAREA',
               },
               {
                 name       : 'chainOfCustodyWorkflowAvailable',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'COC in POS?',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'chainOfCustodyTubeSystemAvailable',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'COC in POS?',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'chainOfCustodyCourierAvailable',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Is there a courier workflow?',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'chainOfCustodyTSPickupAvailable',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Can a TS pickup themselves at this location?',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'allowNurseHandoffForMedsToBeds',
                 status     : IPNSWebFormFieldStatus.Future,
                 description: 'Can a TS designate a nurse for last-mile delivery.  Defaults to false.',
                 type       : 'BOOLEAN',
               },
               {
                 name       : 'chainOfCustodyTubeSystemStationRegex',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'COC Regex for valid tube stations',
                 type       : 'TEXT',
               },
               {
                 name       : 'chainOfCustodyTubeSystemStationFormatLabel',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'Text for tube station workflow screen?',
                 type       : 'TEXT',
               },
               {
                 name       : 'chainOfCustodyTubeSystemStationImage',
                 status     : IPNSWebFormFieldStatus.Present,
                 description: 'URI to an image of the tube station',
                 type       : 'TEXT',
               },
               {
                 name        : 'allowedParcelPaymentTypes',
                 status      : IPNSWebFormFieldStatus.Present,
                 description : 'The Array of allowed parcel payment types.',
                 type        : 'CODE_ARRAY',
                 codeCategory: 'ParcelPaymentType',
               },
             ],
           },
         ],
       }
;
