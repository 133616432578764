import {Action} from '@ngrx/store';
import {IPNSJobTypes} from '@portal-ng/PNSModels';

export enum PNSJobTypesActionTypes {
  LoadPNSJobTypes = '[PNSJobTypes] Retrieve',
  LoadPNSJobTypesSuccess = '[PNSJobTypes] Retrieve Success',
}

// Loading

export class LoadPNSJobTypes implements Action {
  readonly type = PNSJobTypesActionTypes.LoadPNSJobTypes;
  constructor() {}
}

export class LoadPNSJobTypesSuccess implements Action {
  readonly type = PNSJobTypesActionTypes.LoadPNSJobTypesSuccess;
  constructor(public payload: {jobTypes: IPNSJobTypes[]} ) {}
}

export type PNSJobTypesActionTypeUnion =
  LoadPNSJobTypes
  | LoadPNSJobTypesSuccess;
