<div *ngIf="!showLoginButton" style="height: 100vh; width: 100vw;">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%">
            <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
        </div>
    </mat-card>
</div>

<div *ngIf="showLoginButton" style="height: 100vh; width: 100vw;">
    <mat-card style="width: 10vw; min-width: 100px; margin: 20vh auto;" >
        <mat-card-content>
            <p class="mat-body-1">You need to login to continue.</p>
        </mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%">
            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="doLogin()">Login</button>
            </mat-card-actions>
        </div>
    </mat-card>
</div>
