export enum IPNSCodeCategory {
  ALLERGY,
  CONFIDNETIALITY,
  COUNTRY,
  CVX,
  DischargeDisposition,
  ETHNICITY,
  InsuranceClass,
  GENDER,
  ICD_9,
  ICD_10,
  LANGUAGE,
  MARITAL_STATUS,
  PatientAliasType,
  PnsStatus,
  PROBLEM,
  RACE,
  ReadmissionRisk,
  RELIGION,
  RxNORM,
  RxDelivery,
  STATE,
  SNOMED,
  Speciality,
  UnableToServe,
  VENDOR,
  SocialDeterminateTaskUnableToServeReason,
  NutritionUnableToServeReason,
  ScheduleFollowupTaskUnableToServeReason,
  PermissionUnableToServeReason,
  PrescriptionUnableToServeReason,
  A2hUnableToServeReason,
  A2hReferredTo,
  ScheduleTransportationUnableToServeReason,
  PostDischargeActivityUnableToServeReason,
  PcpAppointmentUnableToServeReason,
  InternalReferralUnableToServeReason,
  DispositionUnableToServeReason,
  DispositionFacilityQuality,
  ICD_10_PCS,
  NutritionReferredTo,
  PTReferredTo,
  PTReferralUnableToServeReason,
  HHReferredTo,
  HHReferralUnableToServeReason,
  TelephonicScheduleFollowupUnableToServeReason,
  FirstCallOutcomeForScheduleFollowup,
  SecondCallOutcomeForScheduleFollowup,
  MailBrochureOutcomeForScheduleFollowup,
  ReasonForSchedulingWithUnAffiliatedProvider,
  ParcelPaymentType,
}

export enum ManagableCodeCategories {
  A2hReferredTo,
  A2hUnableToServeReason,
  CaseManager,
  CONFIDNETIALITY,
  Covid19RiskStatus,
  CovidRiskTeam,
  DischargeDisposition,
  DispositionFacilityQuality,
  DispositionUnableToServeReason,
  ETHNICITY,
  FirstCallOutcomeForScheduleFollowup,
  HHReferralUnableToServeReason,
  HHReferredTo,
  InsuranceClass,
  InternalReferralUnableToServeReason,
  LANGUAGE,
  MailBrochureOutcomeForScheduleFollowup,
  MARITAL_STATUS,
  MedicationChangeReason,
  NutritionReferredTo,
  NutritionUnableToServeReason,
  ParcelPaymentType,
  PatientAliasType,
  PcpAppointmentUnableToServeReason,
  PermissionUnableToServeReason,
  PnsStatus,
  PostDischargeActivityUnableToServeReason,
  PrescriptionUnableToServeReason,
  PTReferralUnableToServeReason,
  PTReferredTo,
  RACE,
  ReadmissionRisk,
  ReasonForSchedulingWithUnAffiliatedProvider,
  RELIGION,
  RxDelivery,
  ScheduleFollowupTaskUnableToServeReason,
  ScheduleTransportationUnableToServeReason,
  SecondCallOutcomeForScheduleFollowup,
  SocialDeterminateTaskUnableToServeReason,
  Speciality,
  STATE,
  TelephonicScheduleFollowupUnableToServeReason,
  UnableToServe,
  VENDOR,
}

export interface IPNSCode {
  id: string;
  active: boolean;
  abbreviation: string;
  title: string;
  description: string;
  category: IPNSCodeCategory;
  sortOrder: number;
  updatedBy: string;
}

export interface IPNSCodeValue {
  id: string;
  updatedBy: string;
  active: boolean;
  abbreviation: string;
  title: string;
  category: IPNSCodeCategory;
  universalCode: boolean;
}
