import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {IPNSLocation, IPNSLocationConfigurationModel, IPNSWebForm, IPNSWebFormFieldTypes} from '@portal-ng/PNSModels/';

@Injectable({
  providedIn: 'root',
})

export class PNSWebFormService {

  constructor() {}

  form: FormGroup;

  static processControl(controlName: string, control: FormControl, conf: IPNSLocationConfigurationModel): IPNSLocationConfigurationModel {
    if (control.value !== null) {
      conf = Object.assign(conf, {[controlName]: control.value});
    }
    return conf;
  }

  static processFormGroup(form: FormGroup, configLocation: IPNSLocationConfigurationModel): IPNSLocationConfigurationModel {
    let conf: IPNSLocationConfigurationModel = {};
    for (const key of Object.keys(form.controls)) {
      const control: AbstractControl = form.controls[key];
      if (control instanceof FormControl) {
        conf = PNSWebFormService.processControl(key, form.controls[key] as FormControl, conf);
      } else if (control instanceof FormArray) {
        conf = PNSWebFormService.processControl(key, form.controls[key] as FormControl, conf);
      }
    }
    return {...configLocation, ...conf};
  }

  static updateObject(configLocation: IPNSLocation, config: IPNSWebForm, form: FormGroup): IPNSLocation {
    let currentConfigLocation = configLocation.configuration;
    if (form.controls !== undefined && form.controls !== null) {
      for (const key of Object.keys(form.controls)) {
        const control: AbstractControl = form.controls[key];
        if (control instanceof FormGroup || control instanceof FormArray) {
          const newConfig: Partial<IPNSLocationConfigurationModel> = PNSWebFormService.processFormGroup(control as FormGroup, currentConfigLocation);
          currentConfigLocation                                    = {...currentConfigLocation, ...newConfig};
        }
      }
    }

    return {
      ...configLocation,
      configuration: currentConfigLocation,
    };
  }

  // takes location, config and generated form objects
  static createForm(entity: IPNSLocation, config: IPNSWebForm): FormGroup {
    const form: FormGroup = new FormGroup({}, null, null);
    config.configurationGroups.forEach((configGroup) => {
      const group = new FormGroup({}, null, null);
      configGroup.configurationItems.forEach((configItem) => {
        if (configItem.type === IPNSWebFormFieldTypes.STRINGARRAY) {
          const controlArray: FormControl[] = [];

          if (entity.configuration[configItem.name] !== undefined && entity.configuration[configItem.name] !== null) {
            entity.configuration[configItem.name].forEach((item, index) => {
              controlArray.push(new FormControl(entity.configuration[configItem.name][index]));
            });
            group.addControl(configItem.name, new FormArray(controlArray));

          } else {
            controlArray.push(new FormControl(configItem.defaultValue));
            group.addControl(configItem.name, new FormArray(controlArray));

          }
        } else {
          if (entity.configuration[configItem.name] !== undefined && entity.configuration[configItem.name] !== null) {
            group.addControl(configItem.name, new FormControl(entity.configuration[configItem.name], []));
          } else {
            group.addControl(configItem.name, new FormControl(configItem.defaultValue, []));
          }
        }
      });
      form.addControl(configGroup.name, group);
    });
    return form;
  }
}
