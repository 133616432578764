import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {LoadPNSLocations} from '../../PNSLocation/actions/PNSLocation.action';

@Injectable()
export class PNSLocationUnitResolver implements Resolve<void> {

  constructor(
    private store: Store<IAppState>,
    ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(new LoadPNSLocations(PNSLocationService.getUnitSearch(route.params.locationID)));
  }
}
