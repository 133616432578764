<fieldset>
  <legend>Select Location <button mat-icon-button (click)="toggleExpanded()"><mat-icon [svgIcon]="expandToggleIcon"></mat-icon></button></legend>

  <mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <div *ngIf="multiSelect; else singleSelect">
        <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="nodeLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
      </div>
      <ng-template #singleSelect>
        <mat-radio-button class="checklist-leaf-node"
                          radioGroup="locationTreeGroup"
                          [checked]="checklistSelection.isSelected(node)"
                          (change)="nodeLeafItemSelectionToggle(node)">{{node.item}}</mat-radio-button>
      </ng-template>
    </mat-tree-node>

    <!-- <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
    </mat-tree-node> -->

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button *ngIf="treeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="expand-more"></mat-icon>
      </button>
      <button *ngIf="!treeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-right"></mat-icon>
      </button>
      <mat-checkbox *ngIf="multiSelect" [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="nodeItemSelectionToggle(node)">{{node.item}}</mat-checkbox>


      <p *ngIf="!multiSelect" class="checklist-leaf-node">{{node.item}}</p>
    </mat-tree-node>
  </mat-tree>
</fieldset>
