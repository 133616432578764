<div class="portalNG-status-badge" *ngIf="application as app; else loading">
  <table [class]="app.health_status">
    <tbody>
    <tr>
      <td colspan="3"><h4>{{generateStatus(app.health_status)}}</h4></td>
    </tr>
    <tr>
      <td><mat-icon svgIcon="timer"></mat-icon></td>
      <td><mat-icon svgIcon="throughput"></mat-icon></td>
      <td><mat-icon svgIcon="alert"></mat-icon></td>
    </tr>
   <tr *ngIf="app.application_summary; else placeholder">
     <td>{{app.application_summary.response_time}} ms</td>
     <td>{{app.application_summary.throughput}} rpm</td>
     <td>{{app.application_summary.error_rate | number:'1.1-2' }} %</td>
   </tr>

    </tbody>
</table>
</div>
<ng-template #placeholder>
  <tr>
    <td>--</td>
    <td>--</td>
    <td>--</td>
  </tr>
</ng-template>

<ng-template #loading>
<div><mat-progress-spinner class="spinner" color="primary" mode="indeterminate"></mat-progress-spinner></div>
</ng-template>
