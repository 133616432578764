import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {LoadPNSExecutions} from '@portal-ng/PNSAdminEntities/actions/PNSExecutions.action';
import {getExecutionsLoaded} from '@portal-ng/PNSAdminEntities/reducers';
import {IPNSExecutionsState} from '@portal-ng/PNSAdminEntities/reducers/PNSExecutions.reducer';
import {PNSTaskService} from '@portal-ng/PNSServices/PNSTask.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';

@Injectable()
export class PNSJobExecutionsGuard implements CanActivate {
  constructor(private taskService: PNSTaskService, private store: Store<IPNSExecutionsState>) {
  }

  canActivate(): Observable<boolean> {
    return this.getExecutions().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  private getExecutions(): Observable<any> {
    return this.store.pipe(
      select(getExecutionsLoaded),
      tap((execsLoaded) => { if (!execsLoaded) {
        this.store.dispatch(new LoadPNSExecutions());
      }}),
      filter((execLoad) => execLoad),
      take(1),
    );
  }
}
