import {Component, OnDestroy} from '@angular/core';

@Component({
  selector: 'portal-ng-you-shall-not-pass',
  template: `
    <div style="justify-content: center;" fxFlex="0 0 100%">
      <div style="margin: 4em" fxFlex="0 0 50%">
        <mat-card>
          <mat-card-title>403: Forbidden</mat-card-title>
          <mat-card-content>
            <p>You do not have permissions to access this content.</p>
            <p>For information on accessing this resource, please contact your supervisor.</p>
            <pre>Username:}}</pre>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" routerLink="/logout">Logout</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  `,
  styles: [
      `
      :host {
        text-align: center;
      }

      mat-card {
        width: 100%;
      }
    `,
  ],
})
export class YouShallNotPassComponent implements OnDestroy {

  constructor() {
    document.body.className = 'blue';
  }

  ngOnDestroy() {
    document.body.className = '';
  }
}
