import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSProvider, IPNSSearch, IPNSSearchResults} from '@portal-ng/PNSModels';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PNSProviderService {

  static locationId: string;

  constructor(
    private store: Store<IAuthState>,
    private auth: PNSAuthService,
    private PNS: PNSService) {
  }

  editProvider(provider: Partial<IPNSProvider>): Observable<IPNSProvider> {
    return this.PNS.authenticatePut( 'providers/' + provider.id, provider);
  }

  loadProvider(payload: IPNSSearch, locationId: string): Observable<IPNSSearchResults> {
    const url = this.PNS.getEndpoint() + 'providers';
    const params = new HttpParams().append('locationId', locationId).append('limit', '10000');
    return this.PNS.authenticateGet(url, params);
  }

  getProvider(providerId: string, locationId: string): Observable<IPNSProvider> {
    const params = new HttpParams().append('locationId', locationId);
    return this.PNS.authenticateGet( 'providers/' , params);
  }
}
