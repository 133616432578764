import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as ErrorActions from '@portal-ng/PNSCore/actions/error.actions';
import {
    IPNSLocation,
    IPNSLocationConfigurationBarcodeFormat,
    IPNSLocationConfigurationModel,
    PNSLocationConfigurationBarcodeControlledSubstanceFormat,
    PNSLocationConfigurationbarcodePriceFormat,
    PNSLocationConfigurationBarcodeSymbology,
    PNSLocationConfigurationBarcodeType,
} from '@portal-ng/PNSModels';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
    selector   : 'portalNG-pnsbarcode-config-form',
    templateUrl: './pnsbarcode-config-form.component.html',
    styleUrls  : ['./pnsbarcode-config-form.component.scss'],
})

export class PNSBarcodeConfigFormComponent implements OnInit {
    @Input() data: IPNSLocation;

    locationConfig: IPNSLocationConfigurationModel;
    bcConf: IPNSLocationConfigurationBarcodeFormat;
    controlledSubstanceFormat: PNSLocationConfigurationBarcodeControlledSubstanceFormat;
    controlledSubstanceExpression: string[];
    priceFormat: PNSLocationConfigurationbarcodePriceFormat;
    priceFMTOptions: string[];
    controlFMTOptions: string[];
    typeOptions: string[];
    symbologyOptions: string[];
    form: FormGroup;
    barcodeFormats: FormArray;

    constructor(private dialogRef: MatDialogRef<any>, private locationService: PNSLocationService, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) data: any) {
        this.data = data.data;
    }

    ngOnInit() {

        this.form = this.fb.group({
            barcodeFormats: this.fb.array([]),
        });

        this.initializeBarcodeFormatsFromConfig(this.data);
        const priceFMT    = PNSLocationConfigurationbarcodePriceFormat;
        const controlFMT  = PNSLocationConfigurationBarcodeControlledSubstanceFormat;
        const types       = PNSLocationConfigurationBarcodeType;
        const symbologies = PNSLocationConfigurationBarcodeSymbology;

        this.priceFMTOptions = Object.keys(priceFMT);
        this.priceFMTOptions = this.priceFMTOptions.slice(this.priceFMTOptions.length / 2);

        this.controlFMTOptions = Object.keys(controlFMT);
        this.controlFMTOptions = this.controlFMTOptions.slice(this.controlFMTOptions.length / 2);

        this.typeOptions = Object.keys(types);
        this.typeOptions = this.typeOptions.slice(this.typeOptions.length / 2);

        this.symbologyOptions = Object.keys(symbologies);
        this.symbologyOptions = this.symbologyOptions.slice(this.symbologyOptions.length / 2);

    }

    initializeBarcodeFormatsFromConfig(location: IPNSLocation) {

        this.barcodeFormats                                           = this.form.get('barcodeFormats') as FormArray;
        const barcodeConfig: IPNSLocationConfigurationBarcodeFormat[] = location.configuration.barcodeFormats;

        if (barcodeConfig !== undefined && barcodeConfig.length !== undefined && barcodeConfig.length > 0) {
            for (const bcf of barcodeConfig) {
                this.barcodeFormats.push(this.initBarcodeFormat(bcf));
            }
        } else {
            // this.barcodeFormats.push(this.createBarcodeFormat());
        }
    }

    // make an empty barcode format form.
    createBarcodeFormat(): FormGroup {
        const bcFormatFormGroup = this.fb.group({
            id                           : [''],
            organizationId               : [''],
            locationId                   : [''],
            type                         : [''],
            matchingExpresion            : [''],
            symbology                    : [''],
            rxNumberIndex                : [''],
            fillNumberIndex              : [''],
            partialFillNumberIndex       : [''],
            controlledSubstanceIndex     : [''],
            priceIndex                   : [''],
            priceFormat                  : [''],
            controlledSubstanceFormat    : [''],
            controlledSubstanceExpression: [''],
        });

        bcFormatFormGroup.patchValue({
            organizationId: this.data.organizationId,
            locationId    : this.data.locationId,
        });

        return bcFormatFormGroup;
    }

    // take a Barcode config and turn it into a form group.

    initBarcodeFormat(barcodeFormat: IPNSLocationConfigurationBarcodeFormat): FormGroup {
        const bcFormatFormGroup = this.fb.group({
            id                           : [''],
            organizationId               : [''],
            locationId                   : [''],
            type                         : [''],
            matchingExpresion            : [''],
            symbology                    : [''],
            rxNumberIndex                : [''],
            fillNumberIndex              : [''],
            partialFillNumberIndex       : [''],
            controlledSubstanceIndex     : [''],
            priceIndex                   : [''],
            priceFormat                  : [''],
            controlledSubstanceFormat    : [''],
            controlledSubstanceExpression: [''],
        });

        bcFormatFormGroup.patchValue({
            id                            : barcodeFormat.id,
            organizationId                : barcodeFormat.organizationId,
            locationId                    : barcodeFormat.locationId,
            type                          : barcodeFormat.type,
            matchingExpresion             : barcodeFormat.matchingExpresion,
            symbology                     : barcodeFormat.symbology,
            rxNumberIndex                 : barcodeFormat.rxNumberIndex,
            fillNumberIndex               : barcodeFormat.fillNumberIndex,
            partialFillNumberIndex        : barcodeFormat.partialFillNumberIndex,
            controlledSubstanceIndex      : barcodeFormat.controlledSubstanceIndex,
            priceIndex                    : barcodeFormat.priceIndex,
            priceFormat                   : barcodeFormat.priceFormat,
            controlledSubstanceFormat     : barcodeFormat.controlledSubstanceFormat,
            controlledSubstanceExpression : barcodeFormat.controlledSubstanceExpression,
        });

        return bcFormatFormGroup;
    }

    serializeForm(form: FormGroup): IPNSLocationConfigurationBarcodeFormat[] {

        const serializedForm: IPNSLocationConfigurationBarcodeFormat[] = [];
        this.barcodeFormats                                            = form.get('barcodeFormats') as FormArray;

        for (const bf of this.barcodeFormats['controls']) {
            const serializedBarcodeFormat: Partial<IPNSLocationConfigurationBarcodeFormat> = {
                id                           : bf.get('id').value,
                organizationId               : bf.get('organizationId').value.toString(),
                locationId                   : bf.get('locationId').value.toString(),
                matchingExpresion            : bf.get('matchingExpresion').value,
                controlledSubstanceExpression: bf.get('controlledSubstanceExpression').value,
                rxNumberIndex                : bf.get('rxNumberIndex').value.toString(),
                fillNumberIndex              : bf.get('fillNumberIndex').value.toString(),
                partialFillNumberIndex       : bf.get('partialFillNumberIndex').value ? bf.get('partialFillNumberIndex').value.toString() : '',
                controlledSubstanceIndex     : bf.get('controlledSubstanceIndex').value ? bf.get('controlledSubstanceIndex').value.toString() : '',
                priceIndex                   : bf.get('priceIndex').value ? bf.get('priceIndex').value.toString() : '',
            };

            if (bf.get('type').value !== null
                && bf.get('type').value !== undefined
                && bf.get('type').value !== '') {
                serializedBarcodeFormat.type = bf.get('type').value;
            }

            if (bf.get('priceFormat').value !== null
                && bf.get('priceFormat').value !== undefined
                && bf.get('priceFormat').value !== '') {
                serializedBarcodeFormat.priceFormat = bf.get('priceFormat').value;
            }

            if (bf.get('controlledSubstanceFormat').value !== null
                && bf.get('controlledSubstanceFormat').value !== undefined
                && bf.get('controlledSubstanceFormat').value !== '') {
                serializedBarcodeFormat.controlledSubstanceFormat = bf.get('controlledSubstanceFormat').value;
            }

            if (bf.get('symbology').value !== null
                && bf.get('symbology').value !== undefined
                && bf.get('symbology').value !== '') {
                serializedBarcodeFormat.symbology = bf.get('symbology').value;
            }

            serializedForm.push(serializedBarcodeFormat as IPNSLocationConfigurationBarcodeFormat);
        }

        console.log(serializedForm);

        return serializedForm;
    }

    deleteBarcodeFormat(index: number) {
        this.barcodeFormats   = this.form.get('barcodeFormats') as FormArray;
        const barcodeFormatId = this.barcodeFormats.at(index).get('id').value;
        if (barcodeFormatId !== undefined) {
            this.locationService.deleteBarcodeFormat(barcodeFormatId).subscribe(
                () => {},
                (error) => {
                    catchError(() => of(new ErrorActions.APIError({error})));
                },
            );
        }

        this.barcodeFormats.removeAt(index);
    }

    addBarcodeFormat() {
        this.barcodeFormats = this.form.get('barcodeFormats') as FormArray;
        this.barcodeFormats.push(this.createBarcodeFormat());
    }

    addWalgreensBarcodeFormat() {
        this.barcodeFormats = this.form.get('barcodeFormats') as FormArray;

        const bcFormatFormGroup = this.createBarcodeFormat();
        bcFormatFormGroup.patchValue({
            organizationId   : this.data.organizationId,
            locationId       : this.data.locationId,
            type             : 'RX',
            matchingExpresion: '^(\\d{7})(\\d{5})$',
            rxNumberIndex    : 1,
        });

        this.barcodeFormats.push(bcFormatFormGroup);
    }

    updateBarcodeFormats() {
        const barcodeFormats = this.serializeForm(this.form);

        for (const bcFormat of barcodeFormats) {
            if (bcFormat.id === undefined || bcFormat.id === null || bcFormat.id === '') {

                // remove empty id
                delete bcFormat.id;

                this.locationService.addBarcodeFormat(bcFormat).subscribe(() => {
                    this.dialogRef.close();
                });
            } else {
                console.log(bcFormat);
                this.locationService.editBarcodeFormat(bcFormat).subscribe(() => {
                    this.dialogRef.close();
                });
            }
        }
    }
}
