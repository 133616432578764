import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PNSOrganizationAddFormComponent} from '@portal-ng/PNSOrganization/components/PNSOrganization-add-form/pnsorganization-add-form.component';
import {PNSAuthenticationGuard} from '@portal-ng/PNSShared/guards/PNSAuthentication.guard';
import {PNSAuthorizationGuard} from '@portal-ng/PNSShared/guards/PNSAuthorization.guard';
import {PNSOrganizationResolver} from '@portal-ng/PNSShared/resolvers/PNSOrganizaton.resolver';
import {PNSOrganizationListComponent} from '@portal-ng/PNSOrganization/components/PNSOrganization-list/PNSOrganization-list.component';

export const organizationRoutes: Routes = [
  {
    path: 'list',
    component: PNSOrganizationListComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    resolve: [PNSOrganizationResolver],
  },
  {
    path: 'add',
    component: PNSOrganizationAddFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthorizationGuard],
    resolve: [PNSOrganizationResolver],
  },
];

@NgModule({
  imports: [RouterModule.forChild(organizationRoutes)],
  exports: [RouterModule],
})

export class PNSOrganizationRoutingModule {}
