import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as PNSCodeActions from '@portal-ng/PNSAdminEntities/actions/PNSCode.actions';
import {IPNSCode} from '@portal-ng/PNSModels';

export interface IPNSCodeState extends EntityState<IPNSCode> {
  loaded: boolean;
}

export const adapter: EntityAdapter<IPNSCode> = createEntityAdapter<IPNSCode>({
  selectId    : (code: IPNSCode) => code.id,
  sortComparer: false,
});

const initialState: IPNSCodeState = adapter.getInitialState({
  selectedCodeId: null,
  loaded        : false,
});

export function reducer(state: IPNSCodeState = initialState, action: PNSCodeActions.PNSCodeActionsUnion): IPNSCodeState {
  switch (action.type) {
    case PNSCodeActions.PNSCodeActionTypes.PNSCodeLoad:
      return {
        ...state,
        loaded: false,
      };

    case PNSCodeActions.PNSCodeActionTypes.PNSCodeLoadSuccess:
      const updateState = {
        ...state,
        loaded: true,
      };
      return adapter.setAll(action.payload.codes.results, updateState);

    default:
      return state;
  }
}
