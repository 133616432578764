<div id="barcodeConfigForm">
  <form [formGroup]="form">
    <h1 class="mat-h1">Barcode Configurations
      <button mat-raised-button color="primary" (click)="addBarcodeFormat()">
        <mat-icon svgIcon="plus"></mat-icon>
        Add Barcode Config
      </button> &nbsp;
      <button mat-raised-button color="primary" (click)="addWalgreensBarcodeFormat()">
        <mat-icon svgIcon="walgreens"></mat-icon>
        Add Walgreens Barcode Config
      </button>
    </h1>

    <div formArrayName="barcodeFormats"
         *ngFor="let bcConf of this.form.get('barcodeFormats')['controls']; index as barcodeConfigIndex;">
      <div [formGroupName]="barcodeConfigIndex.toString(10)">
        <fieldset>
          <legend class="mat-h4">Barcode Format #{{barcodeConfigIndex}}
            <button mat-icon-button color="warn" (click)="deleteBarcodeFormat(barcodeConfigIndex)">
              <mat-icon svgIcon="delete"></mat-icon>
            </button>
          </legend>
          <input formControlName='id' type="hidden"/>
          <input formControlName='organizationId' type="hidden"/>
          <input formControlName='locationId' type="hidden"/>

          <mat-form-field appearance="fill" class="half-width">
            <mat-label>Barcode Type</mat-label>
            <mat-select formControlName='type' placeholder="{{bcConf.type}}">
              <mat-option *ngFor="let bcType of typeOptions" [value]="bcType">{{bcType}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="half-width">
            <mat-label>Barcode Symbology</mat-label>
            <mat-select formControlName='symbology' placeholder="{{bcConf}}">
              <mat-option *ngFor="let symbology of symbologyOptions" [value]="symbology">{{symbology}}</mat-option>
            </mat-select>

          </mat-form-field>
          <br/><br/>
          <h2 class="mat-h2">Regular Expression</h2>
          <mat-form-field class="full-width" appearance="fill">
            <input matInput formControlName='matchingExpresion' type="text"
                   placeholder="{{bcConf['matchingExpresion']}}"/>
            <mat-hint>The regex used to configure a barcode format.</mat-hint>

          </mat-form-field>

          <br/><br/>
          <h2 class="mat-h2">Controlled Substance Expression</h2>
          <mat-form-field class="full-width" appearance="fill">
            <input matInput formControlName='controlledSubstanceExpression' type="text"
                   placeholder="{{bcConf['controlledSubstanceExpression']}}"/>
            <mat-hint>The regex used to configure a controlled substance indicator.</mat-hint>

          </mat-form-field>

          <h2 class="mat-h2">Indexes</h2>

          <mat-form-field appearance="fill" class="indexes">
            <mat-label>rxNumber</mat-label>
            <input matInput formControlName='rxNumberIndex' type="number" min="0" step="any"/>
          </mat-form-field>


          <mat-form-field appearance="fill" class="indexes">
            <mat-label>Price</mat-label>
            <input matInput formControlName='priceIndex' type="number" min="0" step="any"/>
          </mat-form-field>

          <mat-form-field appearance="fill" class="indexes">
            <mat-label>Fill</mat-label>
            <input matInput formControlName='fillNumberIndex' type="number" min="0" step="any"/>
          </mat-form-field>

          <mat-form-field appearance="fill" class="indexes">
            <mat-label>Partial Fill</mat-label>
            <input matInput formControlName='partialFillNumberIndex' type="number" min="0" step="any"/>
          </mat-form-field>

          <mat-form-field appearance="fill" class="indexes">
            <mat-label>Controlled Rx</mat-label>
            <input matInput formControlName='controlledSubstanceIndex' type="number" min="0" step="any"/>
          </mat-form-field>

          <br/><br/>
          <h2 class="mat-h2">Formats</h2>

          <mat-form-field appearance="fill" class="half-width">
            <mat-label>Controlled Substance</mat-label>
            <mat-select formControlName='controlledSubstanceFormat' placeholder="{{bcConf.controlledSubstanceFormat}}">
              <mat-option *ngFor="let controlFMT of controlFMTOptions" [value]="controlFMT">{{controlFMT}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="half-width">
            <mat-label>Price</mat-label>
            <mat-select formControlName='priceFormat' placeholder="{{bcConf.priceFormat}}">
              <mat-option *ngFor="let priceFMT of priceFMTOptions" [value]="priceFMT">{{priceFMT}}</mat-option>

            </mat-select>
          </mat-form-field>
        </fieldset>
        <br/><br/>
      </div>
      <button mat-raised-button color="primary" (click)="updateBarcodeFormats()">
        <mat-icon svgIcon="save"></mat-icon>
        Save Barcode Configuration
      </button>
    </div>

  </form>
</div>
