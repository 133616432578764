import {HttpParams} from '@angular/common/http';
import {IPNSPatient} from '@portal-ng/PNSModels/';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';


export enum VisitSearchType {
  Current,
  Historic
}

@Injectable({
  providedIn: 'root',
})

export class PNSPatientService {

  constructor(private PNS: PNSService) {
  }

  getPatientFromPatientId(patientId: string, locationId: string): Observable<IPNSPatient> {
    const params: HttpParams = new HttpParams();
    params.append('locationId', locationId);
    return this.PNS.authenticateGet(/patients/ + patientId, params );
  }
}
