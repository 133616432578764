import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'portal-ng-bare',
  templateUrl: 'bare.layout.html',
  styleUrls: ['bare.layout.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class BareLayoutComponent implements OnInit, AfterViewInit {
  env: any;
  page: string;

  constructor(
    private store: Store<fromRoot.IAppState>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.env = environment;
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/custom-icons/mdi.svg'));
    this.matIconRegistry.addSvgIcon(`throughput`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/throughput.svg'));

  }

  ngAfterViewInit() {
  }

}
