import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {INewRelicSummary} from '@portal-ng/PNSModels';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class NewRelicService {

  private apiKey: string = environment.NewRelic.APIKey;
  private url = 'https://api.newrelic.com/v2/applications';
  private urlSuffix = '.json';

  constructor(private http: HttpClient) {}

    public getAllApplicationStatus(): Observable<INewRelicSummary> {
      if (this.apiKey !== undefined && this.apiKey !== null) {
        const headers = new HttpHeaders().set('X-api-key', this.apiKey);
        return this.http.get<INewRelicSummary>(this.url + this.urlSuffix, {headers});
      }
    }
  public getApplicationStatus(applicationID: number): Observable<INewRelicSummary> {
    if (this.apiKey !== undefined && this.apiKey !== null) {
      const headers = new HttpHeaders().set('X-api-key', this.apiKey);
      return this.http.get<INewRelicSummary>(this.url + '/' + applicationID.toString(10) + this.urlSuffix, {headers});
    }
  }
}
