import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ConfigurationFormComponent} from '@portal-ng/PNSLocation/components/configuration-form/configuration-form.component';
import {PNSBarcodeConfigFormComponent} from '@portal-ng/PNSLocation/components/pnsbarcode-config-form/pnsbarcode-config-form.component';
import {PNSGenerosityAddFormComponent} from '@portal-ng/PNSLocation/components/pnsgenerosity-add-form/pnsgenerosity-add-form.component';
import {PNSLocationAddFormComponent} from '@portal-ng/PNSLocation/components/pnslocation-add-form/pnslocation-add-form.component';
import {PNSLocationDataQualityFormComponent} from '@portal-ng/PNSLocation/components/pnslocation-data-quality-form/pnslocation-data-quality-form.component';
import {PNSLocationFacilityListComponent} from '@portal-ng/PNSLocation/components/pnslocation-facility-list/pnslocation-facility-list.component';
import {PNSLocationProviderAffiliateComponent} from '@portal-ng/PNSLocation/components/pnslocation-provider-affiliate/pnslocation-provider-affiliate.component';
import {PNSLocationUnitStatusBoardComponent} from '@portal-ng/PNSLocation/components/pnslocation-unit-status-board/pnslocation-unit-status-board.component';
import {PNSLocationUnitStatusComponent} from '@portal-ng/PNSLocation/components/pnslocation-unit-status/pnslocation-unit-status.component';
import {PNSLocationUnitComponent} from '@portal-ng/PNSLocation/components/pnslocation-unit/pnslocation-unit.component';
import {PNSLocationEffects} from '@portal-ng/PNSLocation/effects/PNSLocation.effects';
import {PNSLocationRoutingModule} from '@portal-ng/PNSLocation/PNSLocation-routing.module';
import {reducers} from '@portal-ng/PNSLocation/reducers';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {SharedModule} from '@portal-ng/PNSShared/shared.module';
import {PNSFormsModule} from '@portal-ng/PNSForms/PNSForms.module';
import {CommonModule} from '@angular/common';
import {PNSLocationFormDialogComponent} from '@portal-ng/PNSLocation/components/pnslocation-form-dialog/p-n-s-location-form-dialog.component';

export const COMPONENTS = [
  PNSLocationFacilityListComponent,
  PNSLocationAddFormComponent,
  PNSLocationUnitComponent,
  PNSLocationUnitStatusComponent,
  PNSLocationUnitStatusBoardComponent,
  PNSLocationProviderAffiliateComponent,
  PNSLocationFormDialogComponent,
  PNSGenerosityAddFormComponent,
  PNSLocationDataQualityFormComponent,
  ConfigurationFormComponent,
  PNSBarcodeConfigFormComponent,
];

@NgModule({
  imports: [
      CommonModule,
    PNSFormsModule,
    SharedModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    StoreModule.forFeature('location', reducers),
    EffectsModule.forFeature([PNSLocationEffects]),
    PNSLocationRoutingModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class PNSLocationModule {
}
