import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as ErrorActions from '@portal-ng/PNSCore/actions/error.actions';
import {IPNSOrganization, IPNSOrganizationSearch} from '@portal-ng/PNSModels';
import {PNSOrganizationService} from '@portal-ng/PNSServices/PNSOrganization.service';
import {IState} from '@portal-ng/PNSUser/reducers';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {PNSOrganizationActions} from '../actions';

@Injectable()
export class PNSOrganizationEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<IState>,
    private matDialog: MatDialog,
    private organizationService: PNSOrganizationService,
  ) {}

  @Effect()
  loadOrganizations$ =
    this.actions$.pipe(ofType<PNSOrganizationActions.LoadPNSOrganizations>(PNSOrganizationActions.PNSOrganizationActionTypes.LoadPNSOrganizations),
      map((action) => action.payload),
      exhaustMap((search: IPNSOrganizationSearch) =>
        this.organizationService.loadOrganizations(search).pipe(
          map((response) => new PNSOrganizationActions.LoadPNSOrganizationsSuccess({ response })),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

  @Effect()
  editOrganization$ =
    this.actions$.pipe(ofType<PNSOrganizationActions.EditPNSOrganization>(PNSOrganizationActions.PNSOrganizationActionTypes.EditPNSOrganization),
      map((action) => action.payload),
      exhaustMap((organization: Partial<IPNSOrganization>) =>
        this.organizationService.editOrganization(organization).pipe(
          map((response) => new PNSOrganizationActions.EditPNSOrganizationSuccess({organization: response})),
          tap(() => this.router.navigate(['/admin/organizations/list'])),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

}
