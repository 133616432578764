import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {IJIRAIssue, IJIRAIssueResponse, IPNSProblemReport} from '@portal-ng/PNSModels';
import {EMPTY, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class AtlassianService {

  constructor(private http: HttpClient, private router: Router) {
  }

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(environment.Atlassian.JIRA.Username + ':' + environment.Atlassian.JIRA.APIToken),
    'X-Atlassian-Token': 'no-check',
  });

  private issueCode: IJIRAIssue = {
    fields: {
      project: {id: '10000'},
      summary: '',
      description: {
        type: 'doc',
        version: 1,
        content: [{
          type: 'text',
          text: 'Kibana Link',
          marks: [{
            type: 'link',
            attrs: {href: 'http://google.com', title: 'Kibana Link'},
          }],
        },
          {type: 'hardBreak'},
          {
            type: 'codeBlock',
            attrs: {
              language: 'JSON',
            },
            content: [
              {
                type: 'text',
                text: '',
              },
            ],
          },
        ],
      },
      components: [{name: 'Application Data'}],
      assignee: {name: 'mklemens'},
      issuetype: {name: 'Data Quality'},
    },
  };

  static logTicket(newIssue: IJIRAIssue): void {
    console.log(JSON.stringify(newIssue));
  }

  public createTicket(problemReport: IPNSProblemReport): Observable<any> | void {
    const issue = this.issueCode;

    let kibanaLink = '';

    issue.fields.customfield_10030 = problemReport.facility;
    issue.fields.customfield_10035 = problemReport.facilityID;
    issue.fields.customfield_10031 = problemReport.organization;
    issue.fields.customfield_10032 = problemReport.visits[0].mrn;

    if (problemReport.type === '2 Patients, 1 Bed' && problemReport.visits[1].mrn !== '') {
      issue.fields.customfield_10033 = problemReport.visits[1].mrn;
      issue.fields.summary = problemReport.type + ' for ' + problemReport.facility +
        ' patient ' + problemReport.visits[0].mrn + ', ' + problemReport.visits[1].mrn;

      // tslint:disable-next-line:max-line-length
      kibanaLink = 'http://backend.development.patientengagementadvisors.com:5601/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:b975a320-bff4-11e8-affb-597fcee3ad17,interval:auto,query:(language:lucene,query:\'(MRN:' + problemReport.visits[0].mrn + 'OR+MRN:' + problemReport.visits[1].mrn + ')+AND+facility:' + problemReport.facility + '\'),sort:!(logtime,desc))';
    } else {
      issue.fields.summary = problemReport.type + ' for ' + problemReport.facility + ' patient ' + problemReport.visits[0].mrn;

      // tslint:disable-next-line:max-line-length
      kibanaLink = 'http://backend.development.patientengagementadvisors.com:5601/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:b975a320-bff4-11e8-affb-597fcee3ad17,interval:auto,query:(language:lucene,query:\'MRN:' + problemReport.visits[0].mrn + '+AND+facility:' + problemReport.facility + '\'),sort:!(logtime,desc))';
    }

    issue.fields.customfield_10034 = problemReport.visits[0].unit + ' ' + problemReport.visits[0].room + ' ' + problemReport.visits[0].bed;
    issue.fields.customfield_10036 = { value: problemReport.environment};

    if (problemReport.type === 'Something Worse') {
      issue.fields.description = {
        type: 'doc',
        version: 1,
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: problemReport.description,
              },
            ],
          }],
      };
    } else {

      issue.fields.description = {
        version: 1,
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Reporter: ',
                marks: [{type: 'strong'}],
              },
              {
                type: 'text',
                text: problemReport.reporter,
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Kibana Link',
                marks: [
                  {
                    type: 'link',
                    attrs: {
                      href: kibanaLink,
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'codeBlock',
            attrs: {
              language: 'json',
            },
            content: [
              {
                type: 'text',
                text: JSON.stringify(problemReport, undefined, 2),
              },
            ],
          },
        ],
      };
    }

    this.submitTicket(issue).subscribe((result) => { console.log(result); });
  }

  public submitTicket(newIssue: IJIRAIssue): Observable<any> {
    return this.http.post<IJIRAIssueResponse>(environment.Atlassian.JIRA.URL + 'rest/api/3/issue', newIssue, {headers: this.headers}).pipe(
      tap(() => this.router.navigateByUrl('/admin/locations/facility/' + newIssue.fields.customfield_10035 + '/dataQualityIssue')),
      catchError((error: HttpErrorResponse) => {
        AtlassianService.logTicket(newIssue);
        console.error('An error occurred:', error);
        return EMPTY;
      }));
  }

  public getTicket(issueKey: string): Observable<any> {
    return this.http.get(environment.Atlassian.JIRA.URL + 'rest/api/3/issue/' + issueKey, {headers: this.headers});
  }
}
