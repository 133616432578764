import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as ErrorActions from '@portal-ng/PNSCore/actions/error.actions';
import {IPNSLocation, IPNSLocationSearch} from '@portal-ng/PNSModels';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {IState} from '@portal-ng/PNSUser/reducers';
import {of} from 'rxjs';
import {catchError, concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {PNSLocationActions} from '../actions';

@Injectable()
export class PNSLocationEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<IState>,
    private matDialog: MatDialog,
    private locationService: PNSLocationService,
  ) {}





  @Effect()
  loadLocations$ =
    this.actions$.pipe(
      ofType<PNSLocationActions.LoadPNSLocations>(PNSLocationActions.PNSLocationActionTypes.LoadPNSLocations),
      map((action) => action.payload),
      concatMap((search: IPNSLocationSearch) =>
        this.locationService.loadLocations(search).pipe(
          map((locations) => {
            if (locations !== undefined) {
              return new PNSLocationActions.LoadPNSLocationsSuccess({ locations });
            }
          }),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

  @Effect()
  addLocation$ =
    this.actions$.pipe(ofType<PNSLocationActions.AddPNSLocation>(PNSLocationActions.PNSLocationActionTypes.AddPNSLocation),
      map((action) => action.payload),
      exhaustMap((location: Partial<IPNSLocation>) =>
        this.locationService.addLocation(location).pipe(
          map((response) => new PNSLocationActions.AddPNSLocationSuccess({location: response})),
          tap(() => this.router.navigate(['/admin/locations/facility/list'])),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

      @Effect()
  editLocation$ =
    this.actions$.pipe(ofType<PNSLocationActions.EditPNSLocation>(PNSLocationActions.PNSLocationActionTypes.EditPNSLocation),
      map((action) => action.payload),
      exhaustMap((location: Partial<IPNSLocation>) =>
        this.locationService.editLocation(location).pipe(
          map((response) => new PNSLocationActions.EditPNSLocationSuccess({location: response})),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

}
