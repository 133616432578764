import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';
import {IPNSRole} from '@portal-ng/PNSModels/PNSRole.model';
import {IPNSUser} from '@portal-ng/PNSModels/PNSUser.model';
import {PNSUserService} from '@portal-ng/PNSServices/PNSUser.service';
import {AddPNSUser, EditPNSUser} from '@portal-ng/PNSUser/actions/PNSUser.action';
import {PNSUserEffects} from '@portal-ng/PNSUser/effects/PNSUser.effects';
import * as fromUser from '@portal-ng/PNSUser/reducers';

import {MicrosoftService} from '@portal-ng/PNSServices/Microsoft.service';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'portal-ng-pnsuser-form',
  templateUrl: './pnsuser-form.component.html',
  styleUrls: ['./pnsuser-form.component.scss'],
})
export class PNSUserFormComponent implements OnInit, OnDestroy {

  roles$: Subscription;
  locs$: Subscription;
  user$: Subscription;
  route$: Subscription;
  ready2Load: Subscription;

  // Active Directory Lookup
  ADUserRecord: MicrosoftGraph.User;
  userInAD = false;

  saveLabel = '';
  cancelLabel = '';
  verb = '';

  id = '';

  formUser$: Partial<IPNSUser> = {
    id                     : this.id,
    updatedBy              : '',
    forcePasswordReset     : false,
    username               : '',
    firstName              : '',
    lastName               : '',
    email                  : '',
    name                   : '',
    phoneNumber            : '',
    roles                  : [],
    locations              : [],
    custodialOrganizationId: '',
  };

  // Loading indicators
  userLoad = new BehaviorSubject(false);
  roleLoad = new BehaviorSubject(false);
  locLoad = new BehaviorSubject(false);
  userRoleLoad = new BehaviorSubject(false);
  userLocLoad = new BehaviorSubject(false);

  // SHARED MAT-CHIP VARIABLES
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // ROLES
  roleControl = new FormControl();
  filteredRoles: Observable<IPNSRole[]>;
  roles: IPNSRole[] = [];
  potentialRoles: IPNSRole[] = [];
  allRoles: IPNSRole[] = [];

  // LOCATIONS
  locationControl = new FormControl();
  filteredLocations: Observable<IPNSLocation[]>;
  locations: IPNSLocation[] = [];
  potentialLocations: IPNSLocation[] = [];
  allLocations: IPNSLocation[] = [];

  @Input() editMode: boolean;

  @ViewChild('roleInput', {static: true}) roleInput: ElementRef<HTMLInputElement>;
  @ViewChild('locationInput', {static: true}) locationInput: ElementRef<HTMLInputElement>;

  constructor(
    private msService: MicrosoftService,
    private userEffects: PNSUserEffects,
    private userService: PNSUserService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAuthState>,
    private cdf: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {

    // once locations and roles have loaded, init edit mode if applicable.
    this.ready2Load = forkJoin([this.locLoad, this.roleLoad]).subscribe((res) => {

      if (res[0] === true && res[1] === true) {
        if (this.editMode === undefined || this.editMode === null) {
          this.editMode = false;
        } else {
          this.initEditMode();
        }
      }
    });

    this.editMode = this.route.snapshot.data['editMode'];

    this.msService.getToken().subscribe();

    this.roles$ = this.store.pipe(
      select(fromUser.getRoles))
      .subscribe((data) => {
        if (data !== null && data !== undefined && data.length > 0) {

          this.allRoles = [...data].sort((a: IPNSRole, b: IPNSRole) => a.name.localeCompare(b.name));
          this.potentialRoles = this.allRoles;
          this.filteredRoles = this.roleControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterRole(value)),
          );
          this.roleLoad.next(true);
          this.roleLoad.complete();

        }
      });

    this.locs$ = this.store.pipe(
      select(fromLocation.getOperationalLocationByType('FACILITY')))
      .subscribe((data) => {
        if (data !== null && data !== undefined && data.length > 0) {
          this.allLocations = [...data].sort((a: IPNSLocation, b: IPNSLocation) => a.name.localeCompare(b.name));
          this.potentialLocations = this.allLocations;
          this.filteredLocations = this.locationControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterLocations(value)),
          );
          this.locLoad.next(true);
          this.locLoad.complete();
        }
      });

    this.setLabels(this.editMode);
  }

  ngOnDestroy() {
    this.ready2Load.unsubscribe();
    this.locs$.unsubscribe();
    this.roles$.unsubscribe();

    if (this.route$ !== null && this.route$ !== undefined) {
      this.route$.unsubscribe();
    }

    if (this.user$ !== null && this.user$ !== undefined) {
      this.user$.unsubscribe();
    }
    this.cdf.detach();
  }

  // updates name field to reflect API operation.
  updateName() {
    this.formUser$.name = this.formUser$.firstName + ' ' + this.formUser$.lastName;
  }

  addRoleByName(roleName: string): void {
    this.store.pipe(select(fromUser.getRolesByName(roleName))).subscribe((rol) => {
      this.roles.push(rol[0]);
    });
  }

  addAllLocations() {
    this.potentialLocations.forEach((loc) => this.locations.push(loc));
    this.potentialLocations = [];
  }

  clearLocations() {
    this.locations = [];
    this.potentialLocations = this.allLocations;
  }

  findADUser() {
    this.msService.getUser(this.formUser$.username + '@patientengagementadvisors.com').subscribe((result) => {
      this.ADUserRecord = result;
      if (this.ADUserRecord.userPrincipalName === undefined) {
        this.snackBar.open('User Not Found <mat-icon svgIcon="sad"></mat-icon>', 'OK');
      } else {
        if (this.ADUserRecord.department !== null && this.potentialLocations.map((loc) => loc.name).includes(this.ADUserRecord.department)) {
          this.store.pipe(select(fromLocation.getLocationByName(this.ADUserRecord.department))).subscribe((loc) => {
            this.locations.push(loc[0]);
          });
        }
        if (this.ADUserRecord.jobTitle !== null && this.roles !== undefined && this.roles.map((role) => role.name).includes(this.ADUserRecord.jobTitle)) {
          this.addRoleByName(this.ADUserRecord.jobTitle);
        }
        if (this.ADUserRecord.jobTitle !== null) {
          switch (this.ADUserRecord.jobTitle) {
            case 'Transition Specialist Supervisor':
              this.addRoleByName('Transition Specialist');
              this.addRoleByName('Admin');
              break;
            case 'Transition Specialist':
              this.addRoleByName('Admin');
              break;
            default:
              // nothing
              break;
          }
        }
        if (this.ADUserRecord.mobilePhone !== null) {
          this.formUser$.phoneNumber = this.ADUserRecord.mobilePhone;
        }
        this.formUser$.email = this.ADUserRecord.mail;
        this.formUser$.firstName = this.ADUserRecord.givenName;
        this.formUser$.lastName = this.ADUserRecord.surname;
        this.formUser$.name = this.ADUserRecord.displayName;
        this.cdf.detectChanges();
      }
    });
  }

  setLabels(editMode) {
    this.verb = editMode === true ? 'Edit' : 'Add';
    this.saveLabel = editMode === true ? 'Save User' : 'Add User';
    this.cancelLabel = 'Cancel';
  }

  checkArrayForID(array: any[], id: string) {
    let isUnique = true;
    array.forEach((item) => {
      if (item.id.toString() === id.toString()) {
        isUnique = false;
      }
    });
    return isUnique;
  }

  getLocationFromID(id: string): IPNSLocation {
    return this.allLocations.filter((x) => x.id.toString() === id).pop();
  }

  getRoleFromID(id: string): IPNSRole {
    const roleId: string = id;
    return this.allRoles.filter((x) => x.id === roleId).pop();
  }

  initEditMode() {
    this.route$ = this.route.params.subscribe((params) => this.id = params.id);
    if (this.id !== undefined && this.id !== null) {
      this.user$ = this.store.pipe(select(fromUser.getUserById(this.id))).subscribe((user) => {
        if (user !== undefined && user !== null && user.id !== '') {
          this.formUser$ = {...user};

          this.userLoad.next(true);
          if (this.formUser$.roles !== undefined && this.formUser$.roles.length !== 0) {
            this.formUser$.roles.map((role) => role.id).forEach((roleID) => {
              const role = this.getRoleFromID(roleID);
              if (role !== undefined && role !== null && this.checkArrayForID(this.roles, roleID)) {
                this.roles.push(role);
                this.potentialRoles.splice(this.potentialRoles.indexOf(role), 1);
                this.roles = this.roles.sort((a, b) => a.name.localeCompare(b.name));
                this.roleControl.setValue('');
              }
            });
            this.userRoleLoad.next(true);
            this.cdf.detectChanges();
          }
          if (this.formUser$.locationIds !== undefined && this.formUser$.locationIds.length !== 0) {
            this.formUser$.locationIds.forEach((locationID) => {
              const location = this.getLocationFromID(locationID);
              if (location !== undefined && location !== null && this.checkArrayForID(this.locations, locationID)) {
                this.locations.push(location);
                this.potentialLocations.splice(this.potentialLocations.indexOf(location), 1);
                this.locations = this.locations.sort((a, b) => a.name.localeCompare(b.name));
                this.locationControl.setValue('');
              }
            });
            this.userLocLoad.next(true);
            this.cdf.detectChanges();
          }
        }
      });
      if (this.userLoad.getValue() === true) {
        this.locationControl.setValue('');
        this.roleControl.setValue('');
        this.user$.unsubscribe();
      }
    }
  }

  saveUser(form: NgForm): void {
    const v = form.value;
    const formUser: Partial<IPNSUser> = {
      id                     : this.id,
      username               : v.username,
      firstName              : v.firstName,
      lastName               : v.lastName,
      email                  : v.email,
      phoneNumber            : v.phoneNumber,
      locations              : this.locations,
      locationIds            : this.locations.map((loc) => loc.id.toString()),
      roles                  : this.roles,
      enabled                : true,
      custodialOrganizationId: v.custodialOrganizationId,
    };
    if (this.editMode === true) {
      this.store.dispatch(new EditPNSUser(formUser));
    } else {
      this.store.dispatch(new AddPNSUser(formUser));
    }
    this.router.navigate(['/admin/users/list']);
  }

  // LOCATION FUNCTIONS
  addLocation(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim() && this.locations.find((location) => location.name === value) === undefined) {
      this.locations.push(this.allLocations.find((obj) => obj.name === value));
      this.locations = this.locations.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (input) {
      input.value = '';
    }
    this.locationControl.setValue(null);
  }

  removeLocation(location: IPNSLocation): void {
    this.potentialLocations.push(location);
    this.potentialLocations = this.potentialLocations.sort((a, b) => a.name.localeCompare(b.name));
    const index = this.locations.map((loc) => loc.name).indexOf(location.name);
    if (index >= 0) {
      this.locations.splice(index, 1);
    }
  }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {

    if (this.locations.indexOf(event.option.value) === -1) {
      this.locations.push(event.option.value);
      this.locations = this.locations.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.potentialLocations = this.potentialLocations
      .filter((x) => x.id !== event.option.value.id)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.locationControl.setValue('');
  }

  private _filterLocations(value: any): IPNSLocation[] {
    let filterValue = '';
    if (value !== undefined && value !== null) {
      filterValue = value.toString().toLowerCase();
    }

    return this.potentialLocations.filter((loc) => loc.name.toLowerCase().indexOf(filterValue) === 0)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  // ROLE FUNCTIONS
  addRole(event: MatChipInputEvent): void {
    const input = event.input;
    const value = JSON.parse(event.value);
    if ((value || '').trim() && this.roles.find((role) => role.id === value.id) === undefined) {
      this.roles.push(this.allRoles.find((obj) => obj.id === value.id));
      this.roles = this.roles.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (input) {
      input.value = '';
    }

    this.roleControl.setValue(null);
  }

  removeRole(role: IPNSRole): void {
    this.potentialRoles.push(role);
    this.potentialRoles = this.potentialRoles.sort((a, b) => a.name.localeCompare(b.name));
    const index = this.roles.map((r) => r.id).indexOf(role.id);
    if (index >= 0) {
      this.roles.splice(index, 1);
    }
  }

  selectedRole(event: MatAutocompleteSelectedEvent): void {
    const selectedRole: IPNSRole = event.option.value;
    if (this.roles === undefined || this.roles === null) {
      this.roles = [selectedRole];
    } else if (this.roles.indexOf(selectedRole) === -1) {
      this.roles = this.roles.concat([selectedRole]);
      this.roles = this.roles.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.potentialRoles = this.potentialRoles
      .filter((x) => x.id !== selectedRole.id)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.roleControl.setValue('');
    this._filterRole('');
  }

  private _filterRole(value: any): IPNSRole[] {
    let filterValue = '';
    if (value !== undefined && value !== null) {
      filterValue = value.toString().toLowerCase();
    }
    return this.potentialRoles.filter((role) => role.name.toLowerCase().indexOf(filterValue) === 0)
      .filter((role) => this.roles.indexOf(role) === -1)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
