export const environment = {
  production: true,
  type      : 'PROD',
  app       : 'Portal',
  name      : 'Patient Navigation System - RELEASE',
  version   : '14',
  Portal    : {
    defaultLoggedInRoute: '/home',
  },
  PNS       : {
    searchLimit         : 1200,
    slowQuerySearchLimit: 100,
    endpoint            : 'https://prod-api.pns.patientengagementadvisors.com/v4/',
  },
  Auth : {
    baseURL: 'https://prod-login.pns.patientengagementadvisors.com',
    pubKeyURL: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_dyVOK8Y4Z/.well-known/jwks.json',
    loginURL: '/login',
    authURL: '/oauth2/token/',
    logoutURL: '/logout',
    region: 'us-east-1',
    userPoolId: 'us-east-1_dyVOK8Y4Z',
    clientId : '1pftbtg918mk5cbcs231icjakj',
    responseType: 'code',
    portalAccessRole: 'Access Portal',
    refreshTokenInterval: (5 * 60 * 1000),

  },
  GoogleMaps: {
    APIKey: '.',
  },
  Microsoft : {
    ClientSecret: '..',
    TenantID    : '..',
    ClientID    : '..',
    Scope2      : '..',
    Scope       : '..',
    GrantType   : '..',
  },
  Atlassian : {
    JIRA: {
      URL     : 'https://...atlassian.net',
      APIToken: '..',
      Username: '..@patientengagementadvisors.com',
    },
  },
  NewRelic  : {
    applicationId: '.',
    licenseKey   : '.',
    APIKey       : '.',
  },
  AWS       : {
    accessKeyId      : '..',
    secretAccessKey  : '..',
    releaseNoteBucket: '.........com',
    region           : 'us-east-1',
  }
};
