import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSJobs} from '@portal-ng/PNSModels';
import * as PNSJobsActions from '../actions/PNSJobs.action';

export interface IPNSJobsState extends EntityState<IPNSJobs> {
  loaded: boolean;
  selectedId: string;
}

export const adapter: EntityAdapter<IPNSJobs> = createEntityAdapter<IPNSJobs>({
  selectId: (jobs: IPNSJobs) => jobs.id,
  sortComparer: false,
});

const initialState: IPNSJobsState = adapter.getInitialState({
  selectedId: null,
  loaded: false,
});

export function reducer(state: IPNSJobsState = initialState, action: PNSJobsActions.PNSJobsActionTypeUnion): IPNSJobsState {
  switch (action.type) {

    case PNSJobsActions.PNSJobsActionTypes.LoadPNSJobsSuccess:
      const updateState = {...state,
        loaded: true,
      };
      const searchResults: IPNSJobs[] = action.payload.jobs.results;
      return adapter.setAll(searchResults, updateState);

    default:
      return state;
  }
}

export const getSelectedJobsId = (state: IPNSJobsState) => state.selectedId;
export const getJobsLoaded = (state: IPNSJobsState) => state.loaded;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectJobsIds = selectIds;

// select the dictionary of user entities
export const selectJobsEntities = selectEntities;

// select the array of users
export const selectAllJobs = selectAll;

// select the total user count
export const selectJobsTotal = selectTotal;
