import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {PNSAuthActions} from '@portal-ng/PNSAuth/actions';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';

@Injectable({
  providedIn: 'root',
})

export class PNSAuthenticationGuard implements CanActivate {
  constructor(private store: Store<IAuthState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.isAuthenticated),
      map((authenticated) => {
        if (authenticated === false) {
          this.store.dispatch(new PNSAuthActions.Logout({url: route.url.toString()}));
          return false;
        }
        return true;
      }),
      take(1),
    );
  }
}
