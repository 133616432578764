import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';
import {EffectsModule} from '@ngrx/effects';
import {MinimalRouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {PNSAuthEffects} from '@portal-ng/PNSAuth/effects/PNSAuth.effects';
import {PNSAuthModule} from '@portal-ng/PNSAuth/PNSAuth.module';
import {CoreComponent} from '@portal-ng/PNSCore/components/core/core.component';
import {CoreRoutingModule} from '@portal-ng/PNSCore/core-routing.module';
import {CoreModule} from '@portal-ng/PNSCore/core.module';
import {ErrorEffects} from '@portal-ng/PNSCore/effects/Error.effects';
import {reducers} from '@portal-ng/PNSCore/reducers';
import {PNSLocationModule} from '@portal-ng/PNSLocation/PNSLocation.module';
import {PNSOrganizationModule} from '@portal-ng/PNSOrganization/PNSOrganization.module';
import {AtlassianService} from '@portal-ng/PNSServices/Atlassian.service';
import {GoogleGeocodingService} from '@portal-ng/PNSServices/Google.Geocoding';
import {MicrosoftService} from '@portal-ng/PNSServices/Microsoft.service';
import {NewRelicService} from '@portal-ng/PNSServices/NewRelic.service';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {PNSApplicationService} from '@portal-ng/PNSServices/PNSApplication.service';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {PNSCodeService} from '@portal-ng/PNSServices/PNSCode.service';
import {PNSConnectionService} from '@portal-ng/PNSServices/PNSConnectionService';
import {PNSWebFormService} from '@portal-ng/PNSServices/PNSWebForm.service';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {PNSNavService} from '@portal-ng/PNSServices/PNSNav.service';
import {PNSOrganizationService} from '@portal-ng/PNSServices/PNSOrganization.service';
import {PNSPatientService} from '@portal-ng/PNSServices/PNSPatient.service';
import {PNSProviderService} from '@portal-ng/PNSServices/PNSProvider.service';
import {PNSUserService} from '@portal-ng/PNSServices/PNSUser.service';
import {PNSVisitService} from '@portal-ng/PNSServices/PNSVisit.service';
import {PNSUserModule} from '@portal-ng/PNSUser/PNSUser.module';
import {environment} from '../environments/environment';
import {PNSWhatsNewService} from '@portal-ng/PNSServices/PNSWhatsNew.service';
import {PNSFormTemplateService} from './PNSServices/PNSFormTemplate.service';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {PEATreeService} from '@portal-ng/PNSServices/PEATree.service';
import {localStorageSync} from 'ngrx-store-localstorage';
import {PNSApplicationResolver} from '@portal-ng/PNSShared/resolvers/PNSApplication.resolver';
import {PNSRoleResolver} from '@portal-ng/PNSShared/resolvers/PNSRole.resolver';
import {PNSUserResolver} from '@portal-ng/PNSShared/resolvers/PNSUser.resolver';
import {PNSOrganizationResolver} from '@portal-ng/PNSShared/resolvers/PNSOrganizaton.resolver';
import {PNSLocationResolver} from '@portal-ng/PNSShared/resolvers/PNSLocation.resolver';
import {PNSLocationUnitResolver} from '@portal-ng/PNSShared/resolvers/PNSLocationUnit.resolver';
import {PNSJobsGuard} from '@portal-ng/PNSShared/resolvers/PNSJobs.guard';
import {PNSJobExecutionsGuard} from '@portal-ng/PNSShared/resolvers/PNSJobExecutions.guard';
import {PNSConnectionResolver} from '@portal-ng/PNSShared/resolvers/PNSConnection.resolver';
import {PNSFormTemplateResolver} from '@portal-ng/PNSShared/resolvers/PNSFormTemplate.resolver';
import {PNSJobTypesResolver} from '@portal-ng/PNSShared/resolvers/PNSJobTypes.resolver';
import {PNSAuthenticationGuard} from '@portal-ng/PNSShared/guards/PNSAuthentication.guard';
import {PNSAuthorizationGuard} from '@portal-ng/PNSShared/guards/PNSAuthorization.guard';
import {PNSFormsModule} from '@portal-ng/PNSForms/PNSForms.module';
import {PNSFormTemplateEffects} from '@portal-ng/PNSForms/effects/PNSFormTemplate.effects';


/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */

const providers = [
    AtlassianService,
    MaterialModule,
    PNSApplicationResolver,
    PNSRoleResolver,
    PNSUserResolver,
    PNSOrganizationResolver,
    PNSLocationResolver,
    PNSLocationUnitResolver,
    PNSRoleResolver,
    PNSJobsGuard,
    PNSJobExecutionsGuard,
    PNSConnectionResolver,
    PNSFormTemplateResolver,
    PNSJobTypesResolver,
    PNSAuthenticationGuard,
    PNSAuthorizationGuard,
    GoogleGeocodingService,
    MicrosoftService,
    NewRelicService,
    PNSService,
    PNSApplicationService,
    PNSConnectionService,
    PNSAuthService,
    PNSCodeService,
    PNSWebFormService,
    PNSLocationService,
    PNSNavService,
    PNSFormTemplateService,
    PNSOrganizationService,
    PNSPatientService,
    PNSProviderService,
    PNSWhatsNewService,
    PNSUserService,
    PNSVisitService,
    PEATreeService,
];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: ['auth', 'locations', 'users', 'forms', 'pnsux', 'router', 'organization', 'adminEntities', 'error'], rehydrate: false})(reducer);
}

const metaReducers: MetaReducer<any, any>[] = [localStorageSyncReducer];

@NgModule({
    imports     : [
        MaterialModule,
        NgxJsonViewerModule,
        FlexLayoutModule,
        CommonModule,
        BrowserModule,
        MaterialModule,
        HttpClientModule,
        PNSAuthModule,
        PNSOrganizationModule,
        PNSFormsModule,
        PNSLocationModule,
        CoreRoutingModule,
        StoreModule.forRoot(reducers, {metaReducers, runtimeChecks: {strictStateImmutability: true, strictActionImmutability: true}}),
        StoreRouterConnectingModule.forRoot({serializer: MinimalRouterStateSerializer}),
        StoreDevtoolsModule.instrument({name: environment.name, logOnly: environment.production}),
        EffectsModule.forRoot([PNSAuthEffects, ErrorEffects, PNSFormTemplateEffects]),
        CoreModule,
        PNSUserModule,
    ],
    bootstrap   : [CoreComponent],
    providers,
    declarations: [],
})

export class AppModule {}
