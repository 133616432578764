import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSLocation, IPNSUser} from '@portal-ng/PNSModels';
import {PNSUserService} from '@portal-ng/PNSServices/PNSUser.service';
import {EditPNSUser, LoadPNSUsers, RemovePNSUserConfirm, ResetPNSUserPasswordConfirm} from '@portal-ng/PNSUser/actions/PNSUser.action';
import * as fromUser from '@portal-ng/PNSUser/reducers';
import {Subscription} from 'rxjs';

export interface IPNSUserListFilter {
  name: string;
  enabled: string;
}

export interface IPNSUserListRow {
  multiselect: boolean;
  id: string;
  username: string;
  signedInAt: string;
  name: string;
  actions: any;
}

@Component({
  selector: 'portal-ng-pnsuser-list',
  templateUrl: './pnsuser-list.component.html',
  styleUrls: ['./pnsuser-list.component.scss'],
})

export class PNSUserListComponent implements OnInit, OnDestroy, AfterViewInit {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  filter: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'actions', 'name', 'username', 'email', 'delete']; // multiselect

  toggledUser$: Partial<IPNSUser> = {
    id: '',
    updatedBy: '',
    enabled: true,
    locked: false,
    forcePasswordReset: false,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    name: '',
  };

  toggleSelectAll = false;
  selectedRows: string[] = [];
  user$: Subscription;
  locs: IPNSLocation[];
  filterLocationID: string;
  selected: boolean;
  locs$: Subscription;

  // FILTER
  nameFilter = new FormControl('');
  enabledFilter = new FormControl('');

  filterValues: IPNSUserListFilter = {
    name: '',
    enabled: 'true',
  };

  constructor(
    private store: Store<IAuthState>,
    private cdf: ChangeDetectorRef,
    private router: Router,
    private userService: PNSUserService,
  ) {
    this.dataSource = new MatTableDataSource<IPNSUser>();
  }

  ngOnInit() {
    this.locs$ = this.store.pipe(select(fromLocation.getOperationalLocation)).subscribe((locs) => {
      this.locs = locs.sort((a: IPNSLocation, b: IPNSLocation) => a.name.localeCompare(b.name));
    });

    this.nameFilter.valueChanges.subscribe(
      (name) => {
        this.filterValues.name = name;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      },
    );

    this.enabledFilter.valueChanges.subscribe(
      (enabled) => {
        this.filterValues.enabled = enabled;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      },
    );

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = JSON.stringify(this.filterValues);
    this.dataSource.filterPredicate = (item: any, filterData: any) => {
      const filterObject = JSON.parse(filterData);

      const nameFilterTerm = filterObject.name.toLowerCase().trim();
      const enabledFilterTerm = filterObject.enabled;

      const nameFilter = item.name.toLowerCase().trim().indexOf(nameFilterTerm) !== -1
        || item.firstName.toString().trim().toLowerCase().indexOf(nameFilterTerm) !== -1
        || item.lastName.toLowerCase().trim().indexOf(nameFilterTerm) !== -1
        || item.username.toLowerCase().trim().indexOf(nameFilterTerm) !== -1;

      let enabledFilter: boolean;
      switch (enabledFilterTerm) {
        case 'true' :
          enabledFilter = item.enabled;
          break;
        case 'false':
          enabledFilter = !item.enabled;
          break;
        default:
          enabledFilter = true;
          break;
      }
      return nameFilter && enabledFilter;

    };

    this.dataSource.sortingDataAccessor = (item: IPNSUser, property) => {
      switch (property) {
        case 'lastLogin':
          if (item.signedInAt !== undefined && item.signedInAt !== '' && new Date(item.signedInAt) !== undefined) {
            return new Date(item.signedInAt);
          } else {
            return new Date(1900, 0, 1);
          }
        default:
          return item[property];
      }
    };

    this.updateListUsers();
    this.enabledFilter.setValue('true');
    this.cdf.detectChanges();
  }

  ngOnDestroy() {
    // this.user$.unsubscribe();
  }

  toggleSelectAllRows() {
    if (!this.toggleSelectAll) {
      this.dataSource.connect().value.forEach((row: IPNSUserListRow) => {
        this.selectedRows.push(row.id);
        row.multiselect = true;
      });
    } else {
      this.selectedRows = [];
      this.dataSource.connect().value.forEach((row: IPNSUserListRow) => {
        row.multiselect = false;
      });
    }
  }

  toggleSelectId(id: string) {
    if (this.selectedRows.includes(id)) {
      this.selectedRows.splice(this.selectedRows.indexOf(id), 1);

      // disable selectAll toggle if checked.
      if (this.toggleSelectAll === true) {
        this.toggleSelectAll = false;
      }
    } else {

      this.selectedRows.push(id);

      // if we've selected every item, re-check select all.

      this.checkSelectAll();

    }
    this.cdf.detectChanges();
  }

  reloadUser(username: string): void {
      this.userService.reloadUser(username);
  }

  deselectAll() {
    this.selectedRows = [];
    this.toggleSelectAll = false;
    this.dataSource.connect().value.forEach((row: IPNSUserListRow) => {
      row.multiselect = false;
    });
  }

  bulkDisable() {
    this.selectedRows.forEach((userID) => {
      console.info('Disabling ' + userID);
      this.toggleEnable(userID, true);
    });
    this.deselectAll();
  }

  checkSelectAll() {
    this.toggleSelectAll = this.dataSource.connect().value.map((a: IPNSUserListRow) => a.id).sort().join() === this.selectedRows.sort().join();
  }

  // pass id and true to disable or id and false (default) to toggle state
  toggleEnable(id: string, disable = false) {

    if (id !== undefined && id !== null) {
      this.user$ = this.store.pipe(select(fromUser.getUserById(id))).subscribe((user) => {
        this.toggledUser$ = user;
      });
    }
    if (disable) {
      if (this.toggledUser$.enabled !== false) {
        const updatedUser = {
          ...this.toggledUser$,
          enabled: false,
        };
        this.store.dispatch(new EditPNSUser(updatedUser));
      }
    } else {
      const updatedUser = {
        ...this.toggledUser$,
        enabled: !this.toggledUser$.enabled,
      };
      this.store.dispatch(new EditPNSUser(updatedUser));
    }
  }

  deleteUser(username: string, id: string) {
    this.store.dispatch(new RemovePNSUserConfirm({username, id}));
  }

  resetPassword(username: string) {
    this.store.dispatch(new ResetPNSUserPasswordConfirm({username}));
  }

  reloadUserList() {
    this.store.dispatch(new LoadPNSUsers(PNSUserService.getDefaultSearch()));
    this.updateListUsers();
  }

  refresh() {
    this.checkSelectAll();
  }

  editUser(userID) {
    if (this.selectedRows.length > 1) {
      return false;
    } else {
      this.router.navigate(['/admin/users/edit/' + userID]);
    }
  }

  updateListUsers() {
    if (this.filterLocationID !== undefined && this.filterLocationID !== null) {
      this.store.pipe(select(fromUser.getUsersByLocationID(this.filterLocationID))).subscribe((data) => {
        this.dataSource.data = data;
        this.refresh();
      });
    } else {
      this.store.pipe(select(fromUser.getAllUsers)).subscribe((data) => {
        this.dataSource.data = data;
        this.refresh();
      });
    }
  }
}
