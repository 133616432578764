import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

class IMicrosoftAccessToken {
  token_type: string;
  expires_in: number;
  access_token: string;
}

@Injectable()
export class MicrosoftService {

  userFields: string[] =
    ['businessPhones',
      'displayName',
      'department',
      'givenName',
      'id',
      'jobTitle',
      'mail',
      'mobilePhone',
      'officeLocation',
      'preferredLanguage',
      'surname',
      'userPrincipalName'];

  token: IMicrosoftAccessToken = {
    token_type: '',
    expires_in: 0,
    access_token: '',
  };

  constructor(
    private http: HttpClient) {
  }

  TokenURL = 'https://login.microsoftonline.com/' + environment.Microsoft.TenantID + '/oauth2/token';
  GraphURL = 'https://graph.microsoftonline.com/';

  private headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

  public getToken(): Observable<any> {

    const body = new URLSearchParams();
    body.set('client_id', (environment.Microsoft.ClientID));
    body.set('Resource', (environment.Microsoft.Scope));
    body.set('client_secret', (environment.Microsoft.ClientSecret));
    body.set('grant_type', (environment.Microsoft.GrantType));

    return this.http.post<IMicrosoftAccessToken>(this.TokenURL, body.toString(), {headers: this.headers}).pipe(
      map((response) => this.token = response),
      catchError((error) => {
        return EMPTY;
      }));
  }

  public getUserPhoto(userPrincipalName: string): Observable<any> {
    return this.authenticatedGet(this.GraphURL + '/users/' + userPrincipalName + '/photos/120x120/$value', 'blob');
  }

  public getUser(userPrincipalName: string): Observable<any> {
    return this.authenticatedGet(this.GraphURL + '/users/' + userPrincipalName + '?$select=' + this.userFields.join(','));
  }

  public authenticatedGet(url: string, response?: string): Observable<any> {

    let options = {};

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token.access_token,
    });

    if (response === 'blob') {
      options = {headers, responseType: 'blob'};
    } else {
      options = {headers};
    }

    if (this.token.access_token === '') {
      this.getToken().subscribe((results) => {
        this.token = results;
        return this.http.get(url, options);
      });
    } else {
      return this.http.get(url, options);
    }
  }
}
