<div *ngIf="this.whatsNewItem$ | async as whatsNewItem">
  <div *ngIf="this.format == 'html' || this.format == 'embed'">
    <div id="whatsNewPage" #whatsNewPage [class]="whatsNewItem.appIdentifier === 'announcement' ? 'announcement' : this.build">
      <br/>
      <img *ngIf="this.format === 'html' || (this.format == 'embed' && whatsNewItem.appIdentifier == 'announcement')" id='appIcon' [class]="this.app" alt='the application icon.'
           [src]="getAppIconURL(whatsNewItem.appIdentifier)"/>
      <div id="whatsNewList" #whatsNewList>
        <h1 class="mat-h1" *ngIf="whatsNewItem.appIdentifier !== 'announcement'">What's New In {{this.whatsNewService.normalizeAppCase(whatsNewItem.appIdentifier)}} {{whatsNewItem.displayVersion}}</h1>
        <h1 class="mat-h1" *ngIf="whatsNewItem.appIdentifier == 'announcement'">{{whatsNewItem.announcementTitle}}</h1>
        <p class="mat-body-1 overview">{{whatsNewItem.overview}}</p>
        <mat-divider></mat-divider>
        <div *ngFor="let releaseItem of whatsNewItem.releaseItems">
          <div
            *ngIf="(this.tenant && releaseItem.tenants && releaseItem.tenants.length > 0 && releaseItem.tenants.includes(this.tenant)) || (!this.tenant || !releaseItem.tenants || this.tenant == 'all')">
            <div fxLayout="row" fxLayoutAlign="start center" href="releaseItem.media">
              <div  *ngIf="releaseItem.icon">
                <mat-icon color="primary" >{{releaseItem.icon}}</mat-icon>
              </div>
              <div *ngIf="releaseItem.svgIcon">
                <mat-icon color="primary" [svgIcon]="releaseItem.svgIcon"></mat-icon>
              </div>
              <div fxFill>
                <h2 class="mat-h2">{{releaseItem.title}}</h2>

                <p class="mat-body-1">
                  {{releaseItem.description}}
                </p>

                <p class="mat-body-2">
              <span *ngIf="releaseItem.reportedBy && releaseItem.reportedBy.length > 0">
              <mat-icon class='props' svgIcon="lightbulb-on"> </mat-icon>
                {{releaseItem.reportedBy.join(', ')}}
            </span>
                  <span *ngIf="releaseItem.tenants && releaseItem.tenants.length > 0">
              <mat-icon class='location' svgIcon="map-marker"> </mat-icon>
                    {{releaseItem.tenants.join(', ')}}
            </span>
                </p>
                <br/>
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>

        <ng-template #loading>
          <div *ngIf="isError">
            <h1 class="mat-h1">Error!</h1>
            <p>{{errorDescription}}</p>
          </div>
          <div>Loading...
            <mat-spinner color="primary"></mat-spinner>
          </div>
        </ng-template>
      </div>
      <div *ngIf="format !== 'embed'">
           <p class="footer">&copy; Patient Engagement Advisors</p><br/><br/>
      </div>
    </div>
  </div>
  <div *ngIf="this.format == 'markdown'">
    <pre (click)="showCopyMessage()">{{formattedDoc}}</pre>

  </div>
  <div *ngIf="this.format == 'email'">
    <pre (click)="showCopyMessage()">{{formattedDoc}}</pre>
  </div>
</div>
