import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {PNSOrganizationActionTypeUnion} from '@portal-ng/PNSOrganization/actions/PNSOrganization.action';
import * as fromOrganization from './PNSOrganization.reducer';

export interface IPNSOrganizationState {
  organization: fromOrganization.IPNSOrganizationState;
}

export const reducers: ActionReducerMap<IPNSOrganizationState, PNSOrganizationActionTypeUnion> = {
  organization: fromOrganization.reducer,
};

export const getOrgEntityState = createFeatureSelector<fromOrganization.IPNSOrganizationState>('organization');

export const getOrgModuleState = createFeatureSelector<IPNSOrganizationState>('organization');
export const getOrgState       = createSelector(getOrgModuleState, (state: IPNSOrganizationState) => state.organization);

export const getEntityById = (id: string) => (state: IPNSOrganizationState) => state.organization[id];
export const getOrganizationEntityByID = (id: string) => createSelector(getOrgModuleState, getEntityById(id));

export const { selectIds, selectEntities, selectAll } = fromOrganization.adapter.getSelectors(getOrgEntityState);
export const getOrganizationEntities  = createSelector(getOrgEntityState , selectEntities);
export const getAllOrganizations      = createSelector(getOrgEntityState , selectAll);
export const getOrganizationsLoaded   = createSelector(getOrgState, (state) => state.loaded);
export const getOrganizationByID      = (id: string) => createSelector(getOrganizationEntities, (user) => user[id]);
export const getOrganizationByName    = (name: string) => createSelector(getAllOrganizations, (user) => user.filter((x) => x.name === name));
export const getAllOrganizationsArray = createSelector(getOrganizationEntities, (orgs) => {
  return Object.keys(orgs).map((id) => orgs[id]);
});
