import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {ErrorActions} from '@portal-ng/PNSCore/actions';
import {DialogComponent} from '@portal-ng/PNSCore/components/dialog/dialog.component';
import {IPNSUser} from '@portal-ng/PNSModels/PNSUser.model';
import {IPNSUserSearch} from '@portal-ng/PNSModels/PNSUserSearch.model';
import * as fromUsers from '@portal-ng/PNSUser/reducers';
import {IState} from '@portal-ng/PNSUser/reducers';
import {of} from 'rxjs';
import {catchError, concatMap, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {PNSUserService} from '../../PNSServices/PNSUser.service';
import {PNSUserActions} from '../actions';

@Injectable()
export class PNSUserEffects {

  @Effect()
  loadUsers$ =
    this.actions$.pipe(ofType<PNSUserActions.LoadPNSUsers>(PNSUserActions.PNSUserActionTypes.LoadPNSUsers),
      map((action) => action.payload),
      exhaustMap((search: IPNSUserSearch) =>
          this.userService.loadUsers(search).pipe(
            map((response) => new PNSUserActions.LoadPNSUsersSuccess({ response })),
            catchError((error) => of(new ErrorActions.APIError( {error} ))),
          ),
        ),
    );

  @Effect()
  addUsers$ =
    this.actions$.pipe(ofType<PNSUserActions.AddPNSUser>(PNSUserActions.PNSUserActionTypes.AddPNSUser),
      map((action) => action.payload),
      concatMap((user: Partial<IPNSUser>) =>
        this.userService.addUser(user).pipe(
          map((response) => new PNSUserActions.AddPNSUserSuccess({user: response})),
          tap(() => this.router.navigate(['/admin/users/list'])),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

  @Effect()
  userPasswordReset$ =
    this.actions$.pipe(ofType<PNSUserActions.ResetPNSUserPassword>(PNSUserActions.PNSUserActionTypes.ResetPNSUserPassword),
      map((action) => action.payload.username),
      exhaustMap((payload) =>
        this.userService.resetUserPassword(payload).pipe(
          map((response) => new PNSUserActions.ResetPNSUserPasswordSuccess({ username: response })),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
          tap(() => this.matDialog.closeAll()),
        )));

  @Effect( {dispatch: false} )
  userDeleteCancel$ =
    this.actions$.pipe(ofType<PNSUserActions.RemovePNSUserCancel>(PNSUserActions.PNSUserActionTypes.RemovePNSUserCancel),
      tap(() => this.matDialog.closeAll()),
    );

  @Effect()
  userDelete$ =
    this.actions$.pipe(ofType<PNSUserActions.RemovePNSUser>(PNSUserActions.PNSUserActionTypes.RemovePNSUser),
      map((action) => action.payload),
      concatMap((payload) =>
        this.userService.deleteUser(payload.username).pipe(
          map(() => new PNSUserActions.RemovePNSUserSuccess({ username: payload.username, id: payload.id} )),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
          tap(() => this.router.navigate(['/admin/users/list'])),
        )));

  @Effect( {dispatch: false} )
  confirmUserDelete$ =
    this.actions$.pipe(ofType<PNSUserActions.RemovePNSUserConfirm>(PNSUserActions.PNSUserActionTypes.RemovePNSUserConfirm),
      map((action) => action.payload),
      tap((payload) => {
        this.matDialog.open(DialogComponent, {
          data: {
            title          : 'Delete User',
            message        : 'Are you sure you would like to delete this user?',
            confirmAction  : new PNSUserActions.RemovePNSUser( {username: payload.username, id: payload.id} ),
            cancelAction   : new PNSUserActions.RemovePNSUserCancel(),
            cancelColor    : '',
            confirmPayload : payload.username,
            confirmColor   : 'warn',
            cancelLabel    : 'Cancel',
            confirmLabel   : 'Delete User',
          },
        });
      }),
      tap(() => this.router.navigate(['/admin/users/list'])),
      );

  @Effect( {dispatch: false} )
  confirmPasswordReset$ =
    this.actions$.pipe(ofType<PNSUserActions.ResetPNSUserPasswordConfirm>(PNSUserActions.PNSUserActionTypes.ResetPNSUserPasswordConfirm),
      map((action) => action.payload),
      tap((payload) => {
        this.matDialog.open(DialogComponent, {
          data: {
            title          : 'Reset Password',
            message        : 'Are you sure you would like to reset the user\'s password?',
            confirmAction  : new PNSUserActions.ResetPNSUserPassword({username: payload.username}),
            cancelAction   : new PNSUserActions.ResetPNSUserPasswordCancel(),
            confirmColor   : 'primary',
            cancelLabel    : 'Cancel',
            confirmLabel   : 'Reset Password',
          },
        });
      }),
      tap(() => this.router.navigate(['/admin/users/list'])),
    );

  // @ts-ignore
    @Effect()
  loadRoles$ =
    this.actions$.pipe(ofType<PNSUserActions.LoadPNSRoles>(PNSUserActions.PNSUserActionTypes.LoadPNSRoles),
      withLatestFrom(this.store.pipe(select(fromUsers.getRolesLoaded))),
      exhaustMap(() =>
        this.userService.loadRoles().pipe(
          map((roles) => {
              return new PNSUserActions.LoadPNSRolesSuccess({roles});
            },
          ),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    );

  @Effect()
  editUser$ =
  this.actions$.pipe(ofType<PNSUserActions.EditPNSUser>(PNSUserActions.PNSUserActionTypes.EditPNSUser),
    map((action) => action.payload),
    concatMap((user: Partial<IPNSUser>) =>
      this.userService.editUser(user).pipe(
        map((response) => new PNSUserActions.EditPNSUserSuccess({user: response})),
        catchError((error) => of(new ErrorActions.APIError( {error} ))),
      ),
    ),
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<IState>,
    private matDialog: MatDialog,
    private userService: PNSUserService,
  ) {}
}
