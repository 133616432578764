import {createSelector} from '@ngrx/store';
import * as fromFormTemplate from './PNSFormTemplate.reducer';
import {IPNSFormModuleState} from './PNSFormTemplate.reducer';
import {IAppState} from '@portal-ng/PNSCore/reducers';

export const getFormState = (state: IAppState) => state.forms;

export const {
                 selectIds  : getFormTemplateIds,
                 selectAll  : getAllFormTemplates,
                 selectTotal: getTotalFormTemplates
             } = fromFormTemplate.adapter.getSelectors(getFormState);

export const getFormTemplates = createSelector(getAllFormTemplates, (state => state));
export const getFormTemplateEntity    = createSelector(getFormState, (state) => state.entities);
export const getFormTemplatesLoaded   = createSelector(getFormState, (state: IPNSFormModuleState) => state.loaded);
export const getFormTemplateByID      = (id: string) => createSelector(getFormState, (formTemplate) => formTemplate.entities[id]);
export const getAllFormTemplatesArray = createSelector(getFormState, (FormTemplates) => {
    return Object.keys(FormTemplates).map((id) => FormTemplates[id]);
});

export const getFormTemplatesForLocationId = (facilityId: string) => createSelector(getAllFormTemplates, (f) => f);
