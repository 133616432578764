import {Action} from '@ngrx/store';
import {IPNSSearchResults} from '@portal-ng/PNSModels';

export enum PNSJobsActionTypes {
  LoadPNSJobs = '[PNSJobs] Retrieve',
  LoadPNSJobsSuccess = '[PNSJobs] Retrieve Success',
}

// Loading

export class LoadPNSJobs implements Action {
  readonly type = PNSJobsActionTypes.LoadPNSJobs;
  constructor() {}
}

export class LoadPNSJobsSuccess implements Action {
  readonly type = PNSJobsActionTypes.LoadPNSJobsSuccess;
  constructor(public payload: {jobs: IPNSSearchResults} ) {}
}

export type PNSJobsActionTypeUnion =
  LoadPNSJobs
  | LoadPNSJobsSuccess;
