<div id="user-list-page">
    <h1 class="mat-display-1">Organizations
        <button mat-icon-button color="primary">
            <mat-icon aria-label="Reload Organization List" svgIcon="cached"
                      (click)="updateOrganizationList()"></mat-icon>
        </button>
    </h1>

    <div *ngIf="dataSource.data != null">
        <div id="filter">
            <mat-form-field class="filter">
                <input matInput (keyup)="applyFilter($event.target)" placeholder="Filter"></mat-form-field>
            <button mat-icon-button routerLink="/admin/organizations/add">
                <mat-icon svgIcon="plus"></mat-icon>
            </button>
        </div>




















        <div class="mat-elevation-z8">
            <table mat-table #table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization ID</th>
                    <td mat-cell *matCellDef="let row">{{row.id}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button [routerLink]="['/admin/locations/facility/add',row.id]">
                            <mat-icon svgIcon="hospital-building"></mat-icon>
                        </button>
                        <button mat-icon-button [routerLink]="['/admin/organization/',row.id,'applications']">
                            <mat-icon svgIcon="connection"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Name</th>
                    <td mat-cell *matCellDef="let row">{{row.name | nullPipe}}</td>
                </ng-container>

                <ng-container matColumnDef="abbreviation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Abbreviation</th>
                    <td mat-cell *matCellDef="let row">{{row.abbreviation | nullPipe}}</td>
                </ng-container>

                <ng-container matColumnDef="externalID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>External ID</th>
                    <td mat-cell *matCellDef="let row">{{row.externalId | nullPipe}}</td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Type</th>
                    <td mat-cell *matCellDef="let row">{{row.type | nullPipe}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator #paginator
                           [length]="dataSource.data.length"
                           [pageIndex]="0"
                           [pageSize]="100"
                           [pageSizeOptions]="[25, 50, 100, 250]">
            </mat-paginator>
        </div>
    </div>
</div>
