import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Action, Store} from '@ngrx/store';
import {IAppState} from '@portal-ng/PNSCore/reducers';

@Component({
  selector: 'portalNG-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  cancelAction: Action;
  confirmAction: Action;
  cancelColor: string;
  confirmColor: string;
  title: string;
  message: string;
  cancelLabel: string;
  confirmLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private store: Store<IAppState>,  private mdDialogRef: MatDialogRef<DialogComponent>) {
    this.title = data.title;
    this.message = data.message;
    this.confirmAction = data.confirmAction;
    this.cancelAction = data.cancelAction;
    this.cancelColor = data.cancelColor;
    this.confirmColor = data.confirmColor;
    this.cancelLabel = data.cancelLabel;
    this.confirmLabel = data.confirmLabel;
  }

  public cancel() {
    this.store.dispatch(this.data.cancelAction);
    this.close();
  }

  public confirm() {
    this.store.dispatch(this.data.confirmAction);
    this.close();
  }

  public close() {
    this.mdDialogRef.close();
  }

  ngOnInit() {
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close();
  }
}
