import {Action} from '@ngrx/store';
import {IPNSUser, SAMLRefreshTokenResponsePayload, SAMLTokenRequestPayload, SAMLTokenResponsePayload} from '@portal-ng/PNSModels';
import {HttpErrorResponse} from '@angular/common/http';
import {SAMLTokenExpiredPayload} from '@portal-ng/PNSModels/PEAAuthTypes.model';

export enum PNSAuthActionTypes {
    AuthenticationRequired   = '[AUTH] Authentication Required',
    AuthSetPostAuthURL       = '[AUTH] Set Post-Auth URL',
    LoginAttempt             = '[Auth] Login Attempt',
    LoginSuccess             = '[Auth] Login Success',
    SAMLError                = '[Auth] SAML Error',
    Logout                   = '[Auth] Logout',
    SAMLTokenRequest         = '[Auth] SAML Token Request',
    SAMLTokenResponse        = '[Auth] SAML Token Response',
    SAMLTokenError           = '[Auth] SAML Token Error',
    SAMLTokenExpired         = '[Auth] SAML Token Expired',
    SAMLRefreshTokenRequest  = '[Auth] SAML Refresh Token Request',
    SAMLRefreshTokenResponse = '[Auth] SAML Refresh Token Response',
    SAMLRefreshTokenError    = '[Auth] SAML Refresh Token Error',
    SAMLRefreshTokenSuccess  = '[AUTH] SAML Refresh Token Success',
    PNSWhoamiRequest         = '[Auth] PNS Whoami Request',
    PNSWhoamiResponse        = '[Auth] PNS Whoami Response',
    PNSWhoamiError           = '[Auth] PNS Whoami Error',
}

export class AuthenticationRequired implements Action {
    readonly type = PNSAuthActionTypes.AuthenticationRequired;
    constructor() {}
}

export class LoginAttempt implements Action {
    readonly type = PNSAuthActionTypes.LoginAttempt;
    constructor() {}
}

export class LoginSuccess implements Action {
    readonly type = PNSAuthActionTypes.LoginSuccess;
    constructor() {}
}

export class Logout implements Action {
    readonly type = PNSAuthActionTypes.Logout;
    constructor(public payload: { url: string }) {}
}

export class AuthSetPostAuthURL implements Action {
    readonly type = PNSAuthActionTypes.AuthSetPostAuthURL;
    constructor(public payload: { url: string }) {}
}

export class SAMLTokenRequest implements Action {
    readonly type = PNSAuthActionTypes.SAMLTokenRequest;

    constructor(public payload: SAMLTokenRequestPayload) {}
}

export class SAMLTokenResponse implements Action {
    readonly type = PNSAuthActionTypes.SAMLTokenResponse;

    constructor(public payload: SAMLTokenResponsePayload) {}
}

export class SAMLTokenError implements Action {
    readonly type = PNSAuthActionTypes.SAMLTokenError;

    constructor() {}
}

export class SAMLTokenExpired implements Action {
    readonly type = PNSAuthActionTypes.SAMLTokenExpired;

    constructor(public payload: SAMLTokenExpiredPayload) {}
}

export class SAMLRefreshTokenRequest implements Action {
    readonly type = PNSAuthActionTypes.SAMLRefreshTokenRequest;
    constructor() {}
}

export class SAMLRefreshTokenResponse implements Action {
    readonly type = PNSAuthActionTypes.SAMLRefreshTokenResponse;
    constructor(public payload: SAMLRefreshTokenResponsePayload) {}
}

export class SAMLRefreshTokenError implements Action {
    readonly type = PNSAuthActionTypes.SAMLRefreshTokenError;
    constructor() {}
}

export class SAMLRefreshTokenSuccess implements Action {
    readonly type = PNSAuthActionTypes.SAMLRefreshTokenSuccess;
    constructor() {}
}

export class SAMLError implements Action {
    readonly type = PNSAuthActionTypes.SAMLError;
    constructor(public payload: HttpErrorResponse) {}
}

export class PNSWhoamiRequest implements Action {
    readonly type = PNSAuthActionTypes.PNSWhoamiRequest;
    constructor() {}
}

export class PNSWhoamiResponse implements Action {
    readonly type = PNSAuthActionTypes.PNSWhoamiResponse;
    constructor(public payload: IPNSUser) {}
}

export class PNSWhoamiError implements Action {
    readonly type = PNSAuthActionTypes.PNSWhoamiError;
    constructor() {}
}

export type PNSAuthActionsUnion =
    AuthenticationRequired
    | AuthSetPostAuthURL
    | LoginAttempt
    | LoginSuccess
    | SAMLTokenError
    | SAMLTokenRequest
    | SAMLTokenResponse
    | SAMLTokenExpired
    | SAMLError
    | Logout
    | SAMLRefreshTokenRequest
    | SAMLRefreshTokenResponse
    | SAMLRefreshTokenError
    | SAMLRefreshTokenSuccess
    | PNSWhoamiRequest
    | PNSWhoamiResponse
    | PNSWhoamiError;
