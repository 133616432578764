import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSLocation, IPNSProblemReport, IPNSVisitSummary} from '@portal-ng/PNSModels';
import {AtlassianService} from '@portal-ng/PNSServices/Atlassian.service';
import {VisitSearchType} from '@portal-ng/PNSServices/PNSPatient.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {PNSVisitService} from '@portal-ng/PNSServices/PNSVisit.service';

interface IPNSDataQualityProblemType {
  image: string;
  type: string;
  label: string;
}

@Component({
  selector: 'portalNG-pnslocation-data-quality-form',
  templateUrl: './pnslocation-data-quality-form.component.html',
  styleUrls: ['./pnslocation-data-quality-form.component.scss'],
})
export class PNSLocationDataQualityFormComponent implements OnInit {
  loadingPatientData: boolean[] =  [false, false];
  mrnNotFound: boolean[] = [false, false];
  visitNumberNotFound: boolean[] = [false, false];
  locationID: string;
  loc$: Subscription;
  location$: IPNSLocation;
  authenticatedUsername: string;
  authenticatedUsername$: Subscription;

  problem: IPNSProblemReport = {
    reporter: '',
    facility: '',
    facilityID: '',
    organization: '',
    type: '',
    visits: [
      {mrn: '', visitNumber: '', name: '', admitDate: '', unit: '', room: '', bed: ''},
      {mrn: '', visitNumber: '', name: '', admitDate: '', unit: '', room: '', bed: ''},
    ],
    description: 'So this is what happened...',
    environment: environment.type,
  };

  problemTypes: IPNSDataQualityProblemType[] = [
    {image: 'assets/images/dataQualityButtons/2Patients1Bed.png', type: '2Patients1Bed', label: '2 Patients, 1 Bed'},
    {image: 'assets/images/dataQualityButtons/NotInCensus.png', type: 'NotInCensus', label: 'Not In Census'},
    {image: 'assets/images/dataQualityButtons/NotInC2D.png', type: 'NotInC2D', label: 'Not In C2D'},
    {image: 'assets/images/dataQualityButtons/ApptProblems.png', type: 'ApptProblems ', label: 'Appointment Woes'},
    {image: 'assets/images/dataQualityButtons/PatDataMissing.png', type: 'PatDataMissing', label: 'Patient Data Missing'},
    {image: 'assets/images/dataQualityButtons/SomethingWorse.png', type: 'SomethingWorse', label: 'Something Worse'},
  ];

  constructor(
    private store: Store<IAuthState>,
    private route: ActivatedRoute,
    private router: Router,
    private atlassianService: AtlassianService,
    private visitService: PNSVisitService ,
    private cdf: ChangeDetectorRef,
  ) {
  }

  changeProblemType(type: string) {
    this.problem = {
      reporter: this.authenticatedUsername,
      facility: this.location$.name,
      facilityID: this.location$.locationId,
      organization: this.location$.organizationName,
      type,
      visits: [
        {mrn: '', visitNumber: '', name: '', admitDate: '', unit: '', room: '', bed: ''},
        {mrn: '', visitNumber: '', name: '', admitDate: '', unit: '', room: '', bed: ''},
      ],
      description: 'So this is what happened...',
      environment: environment.type,
    };

    this.visitNumberNotFound = [false, false];
    this.mrnNotFound = [false, false];
    this.loadingPatientData = [false, false];
  }

  submit() {
    this.atlassianService.createTicket(this.problem);
  }

  mapFoundVisit(type: string, index: number, result: IPNSVisitSummary) {

    this.problem.visits[index].mrn = result.mrn;
    this.problem.visits[index].visitNumber = result.visitId.toString();

    // Remove 'Z' from date formatting.
    this.problem.visits[index].admitDate = new Date(result.visit.startDate).toISOString().slice(0, -1);
    this.problem.visits[index].bed = result.visit.bed;
    this.problem.visits[index].name = result.fullName2;
    this.problem.visits[index].room = result.visit.room;
    this.problem.visits[index].unit = result.visit.unit;

    this.loadingPatientData[index] = false;

    if (this.problem.type === 'Appointment Woes') {
      this.problem.appointmentDetails = result.scheduledAppointment;
    }

    this.cdf.detectChanges();
    this.cdf.detectChanges();
  }

  lookupVisit(i: number) {
    this.loadingPatientData[i] = true;
    this.visitService.getVisitSummaryByVisitID(this.problem.facilityID, this.problem.visits[i].visitNumber)
      .subscribe((result) => {
        if (result.visitId !== undefined && result.visitId.toString() === this.problem.visits[i].visitNumber) {
          this.mapFoundVisit('vn', i, result);
          this.visitNumberNotFound[i] = false;
        } else {
          this.loadingPatientData[i] = false;
          this.visitNumberNotFound[i] = true;
          this.cdf.detectChanges();
        }
      });
  }

  lookupMRN(i: number) {
    this.loadingPatientData[i] = true;
    this.visitService.findVisitSummaryByMRN(this.problem.facilityID, this.problem.visits[i].mrn, VisitSearchType.Historic)
      .subscribe((result) => {
        if (result !== undefined && result.totalRecords !== 0) {
          this.mapFoundVisit('mrn', i, result.patientSummaries[0]);
          this.mrnNotFound[i] = false;
        } else {
          this.loadingPatientData[i] = false;
          this.mrnNotFound[i] = true;
          this.cdf.detectChanges();
        }
      });
  }

  ngOnInit() {
    this.locationID = this.route.snapshot.params['locationID'];
    this.authenticatedUsername$ = this.store.pipe(select(fromAuth.getAuthenticatedUsername)).subscribe( (username) => {
      this.authenticatedUsername = username;
      this.cdf.detectChanges();
    });

    this.loc$ = this.store.pipe(select(fromLocation.getLocationByID(this.locationID))).subscribe((loc) => {
      this.location$ = loc;
      if (loc !== undefined && loc.name !== undefined && loc.organizationName !== undefined) {
        this.problem.facility = loc.name;
        this.problem.facilityID = loc.locationId;
        this.problem.organization = loc.organizationName;
        this.cdf.detectChanges();
      }
    });
  }

}
