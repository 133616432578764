<table class="mat-typography">
      <thead>
      <tr>
        <th >DEVELOPMENT</th>
        <th>PRODUCTION</th>
      </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngIf="devAPI$ | async as devAPI"><portalNG-status-badge-component appName="API" [application]="devAPI.application"></portalNG-status-badge-component></td>
          <td *ngIf="prodAPI$ | async as prodAPI"><portalNG-status-badge-component appName="API" [application]="prodAPI.application"></portalNG-status-badge-component></td>
        </tr>
      <tr>
        <td *ngIf="devGateway$ | async as devGateway"><portalNG-status-badge-component appName="Gateway" [application]="devGateway.application"></portalNG-status-badge-component></td>
        <td *ngIf="prodGateway$ | async as prodGateway"><portalNG-status-badge-component appName="Gateway" [application]="prodGateway.application"></portalNG-status-badge-component></td>
      </tr>
      </tbody>
    </table>
