import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSLocation, IPNSLocationConfigurationBarcodeFormat, IPNSLocationSearch, IPNSLocationSearchResults, LocationType} from '@portal-ng/PNSModels';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PNSLocationService {

  static getDefaultSearch(): IPNSLocationSearch {
    const defaultSearch                 = PNSService.defaultSearch() as IPNSLocationSearch;
    defaultSearch.type                  = LocationType.FACILITY;
    defaultSearch.populateConfiguration = true;
    return defaultSearch;
  }

  static getUnitSearch(locID: string): IPNSLocationSearch {
    const unitSearch = PNSService.defaultSearch() as IPNSLocationSearch;
    unitSearch.type = LocationType.UNIT;
    unitSearch.parentId = locID;
    unitSearch.limit = 350;
    return unitSearch;
  }

  constructor(
    private PNS: PNSService) {
  }

  // CRUD functions
  editLocation(location: Partial<IPNSLocation>): Observable<any> {
    return this.PNS.authenticatePut('locations/' + location.id, location);
  }

  addLocation(location: Partial<IPNSLocation>): Observable<any> {
    return this.PNS.authenticatePost('locations', location);
  }

  loadLocation(locationID: string): Observable<IPNSLocation> {
    const params = new HttpParams();

    return this.PNS.authenticateGet('locations/' + locationID, params);
  }

  // Barcode Formats
  editBarcodeFormat(barcode: Partial<IPNSLocationConfigurationBarcodeFormat>): Observable<any> {
    return this.PNS.authenticatePut('barcodes/' + barcode.id, barcode);
  }

  addBarcodeFormat(barcode: Partial<IPNSLocationConfigurationBarcodeFormat>): Observable<any> {
    return this.PNS.authenticatePost('barcodes', barcode);
  }

  deleteBarcodeFormat(barcodeId: string): Observable<any> {
    return this.PNS.authenticateDelete('barcodes/' + barcodeId);
  }

  loadBarcodeFormat(barcodeID: string): Observable<IPNSLocationConfigurationBarcodeFormat> {
    const params = new HttpParams();

    return this.PNS.authenticateGet('barcodes/' + barcodeID, params);
  }

  reloadLocation(locationID: string): void {
    this.loadLocation(locationID).subscribe((loc) => {
      this.editLocation({id: loc.id, updatedBy: 'portalRefresh'}).subscribe((x) => console.log(x));
    });
  }

  loadLocations(payload: IPNSLocationSearch): Observable<IPNSLocationSearchResults> {
    let params = new HttpParams();

    if ('type' in payload) {
      params = params.set('type', payload.type.toString());
    }

    if ('parentId' in payload) {
      params = params.set('parentId', payload.parentId.toString());
    }

    if ('populateConfiguration' in payload) {
      params = params.set('populateConfiguration', payload.populateConfiguration.toString());
    }

    params = PNSService.setCommonSearchParams(params, payload);
    return this.PNS.authenticateGet('locations', params);
  }

}
