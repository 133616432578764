import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSFormTemplate} from '@portal-ng/PNSModels';
import * as PNSFormTemplateActions from '../actions/PNSFormTemplate.action';

export const adapter: EntityAdapter<IPNSFormTemplate> = createEntityAdapter<IPNSFormTemplate>({
  selectId: (formTemplate: IPNSFormTemplate) => formTemplate.id,
  sortComparer: false,
});

export interface IPNSFormModuleState extends EntityState<IPNSFormTemplate>
{
  selectedFormTemplateId: string;
  loaded: boolean;
}

const initialState: IPNSFormModuleState = adapter.getInitialState({
  selectedFormTemplateId: null,
  loaded: false,
});

export function reducer(state: IPNSFormModuleState = initialState, action: PNSFormTemplateActions.PNSFormTemplateActionTypeUnion): IPNSFormModuleState {
  switch (action.type) {

    case PNSFormTemplateActions.PNSFormTemplateActionTypes.EditPNSFormTemplateSuccess:
      const changes = action.payload.formTemplate;

      const id = changes.id;
      return adapter.updateOne({id, changes}, state);

    case PNSFormTemplateActions.PNSFormTemplateActionTypes.LoadPNSFormTemplatesSuccess:
      const updateState = {...state,
        loaded: true,
      };
      return adapter.setAll(action.payload.response, updateState);

    default:
      return state;
  }
}


