import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'emailAddress',
})
export class EmailAddressPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== null) {
      return value;
    } else {
      return 'Not Found';
    }
  }
}
