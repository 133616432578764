import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IPNSWhatsNewItem} from '@portal-ng/PNSModels';
import {ActivatedRoute} from '@angular/router';
import {PNSWhatsNewService} from '@portal-ng/PNSServices/PNSWhatsNew.service';
import {Observable, of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Clipboard} from '@angular/cdk/clipboard';
import {environment} from '../../../../environments/environment';

export enum whatsNewFormats {
    html     = 'html',
    markdown = 'markdown',
    email    = 'email',
    embed    = 'embed',
}

export enum appBuildTypes {
    dev  = 'DEV',
    prod = 'PROD'
}

@Component({
    selector   : 'portal-ng-pnswhats-new',
    templateUrl: './PNSWhatsNew.component.html',
    styleUrls  : ['./PNSWhatsNew.component.scss'],
})

export class PNSWhatsNewComponent implements OnInit, OnChanges {
    whatsNewItem$: Observable<IPNSWhatsNewItem>;

    @Input() document?: IPNSWhatsNewItem;
    @Input() format?: whatsNewFormats;
    @Input() app?: string;
    @Input() version?: string;
    @Input() tenant?: string;
    @Input() build?: string;
    @ViewChild('whatsNewList') whatsNewList: ElementRef;
    @ViewChild('whatsNewPage') whatsNewPage: ElementRef;

    isError = false;
    errorDescription: string;
    appIconUrl: string;

    moreContentIndicatorVisible = false;
    upDownIndicator             = false; // false -> down

    formattedDoc: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        public whatsNewService: PNSWhatsNewService,
        private cdf: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private clipboard: Clipboard) {
    }

    ngOnInit(): void {
        this.initDocument();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.document) {
            this.document = changes.document.currentValue;
        }
        this.initDocument();
    }

    public showCopyMessage() {
        this.clipboard.copy(this.formattedDoc);
        this.snackBar.open('Release Notes Copied to Clipboard', 'close', {
            duration: 5000
        });
    }

    getAppIconURL(app: string) {
        return '/assets/AppIcons/' + app.toLowerCase() + '.svg';
    }

    initDocument() {
        if (this.document) {
            this.whatsNewItem$ = of(this.document);
            this.version       = this.document.displayVersion;
            this.app           = this.document.appIdentifier;
            this.build         = this.document.buildType;
            this.formattedDoc  = this.formatItem(this.format, this.document);
        }

        if (this.app === undefined) {
            this.app = this.activatedRoute.snapshot.params.app;
        }

        if (this.build === undefined) {
            this.build = environment.type;
        }

        if (this.format === undefined) {
            if (this.activatedRoute.snapshot.params.format === undefined || this.activatedRoute.snapshot.params.format === null) {
                this.format = whatsNewFormats.html;
            } else {
                this.format = this.activatedRoute.snapshot.params.format;
            }
        }

        if (this.version === undefined) {
            if (this.activatedRoute.snapshot.params.version === undefined) {
                this.version = 'LATEST';
            } else {
                this.version = this.activatedRoute.snapshot.params.version;
            }
        }

        if (this.activatedRoute.snapshot.params.tenet !== undefined) {
            this.tenant = this.activatedRoute.snapshot.params.tenet;
        }

        if (!(this.format in whatsNewFormats)) {
            this.isError          = true;
            this.errorDescription = 'Invalid Format.';
        }

        if (!(this.build in appBuildTypes)) {
            this.isError          = true;
            this.errorDescription = 'Invalid app build type!';
        }

        if (this.app === undefined || this.app === null) {
            this.isError          = true;
            this.errorDescription = 'App Not Found!';
        }

        if (this.version === undefined || this.version === null) {
            this.isError          = true;
            this.errorDescription = 'Version Not Found!';
        }

        if (this.document === undefined) {
            if (this.version === 'LATEST') {
                this.whatsNewService.getLatestWhatsNewItem(this.app, this.build).subscribe((result) => {
                    console.log('RESULTS:');
                    console.log(result);
                    const whatsNewItem = JSON.parse(result);
                    this.whatsNewItem$ = of(whatsNewItem);
                    this.format        = whatsNewFormats.embed;
                    this.cdf.detectChanges();
                });
            } else {
                this.whatsNewService.getWhatsNewItem(this.app, this.build, this.version).subscribe((result) => {
                    const whatsNewItem = JSON.parse(result);
                    this.whatsNewItem$ = of(whatsNewItem);
                    this.cdf.detectChanges();
                });
            }
        }
        this.cdf.detectChanges();
    }

    formatItem(format: whatsNewFormats, whatsNewItem: IPNSWhatsNewItem): string {
        let output = '';
        let label: string;

        if (whatsNewItem.appIdentifier === 'announcement') {
            label = 'On';
        } else {
            label = 'In';
        }

        switch (format) {
            case whatsNewFormats.markdown: {

                output += `# Whats New ${label} ${whatsNewItem.displayVersion}
${whatsNewItem.overview}`;

                for (const item of whatsNewItem.releaseItems) {
                    let reportedByString = '';
                    if (item.reportedBy !== undefined && item.reportedBy.length > 0) {
                        reportedByString = '*** Thanks To: ' + item.reportedBy.join(', ') + '***';
                    }

                    output += `## ${item.title}
${item.description}
${reportedByString}
`;
                }
                break;
            }
            case whatsNewFormats.email : {
                let color = 'navy';
                if (this.build === appBuildTypes.dev) {
                    color = '#357B55';
                } else {
                    color = '#15385F';
                }

                output += `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html lang="en-us">
<head><title>Release Notes</title><meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
</head><body>
<div style="background-color: ${color}; height: 100%; width: 100%"><br />
<center><img src="https://portal.development.patientengagementadvisors.com/assets/AppIcons/${whatsNewItem.appIdentifier.valueOf()}.png" width="100" height="100" style="display: block; width: 100px; text-align: center; margin: 10px auto;" alt="App Icon" /></center>
<center><h2><a style="color:white;" href="https://portal2.pns.patientengagementadvisors.com/whatsNewIn/${this.app}/${this.build}/${this.version}/all/html">View Release Notes In Your Browser!</a></h2></center>
<div style="background-color: white; width: 600px; padding: 10px; margin: 20px auto 10px">
        <h1>What's New In ${whatsNewItem.displayVersion}</h1>`;

                for (const item of whatsNewItem.releaseItems) {
                    let reportedByString = '';
                    if (item.reportedBy !== undefined) {
                        reportedByString = '<p style="margin:0"><b><em> Thanks To: ' + item.reportedBy.join(', ') + '</em></b></p>';
                    }

                    output += `

            <h3 style="margin:0">${item.title}</h3>
            <p style="margin: 2px">${item.description}</p>
            ${reportedByString}
            <br /><hr /><br />`;
                }

                output += `<div><p style="color: ${color}; text-align: center;">Email sent on behalf of Patient Engagement Advisors.<br/>&copy; Patient Engagement Advisors.</p></div></body></html>`;
                break;
            }
            default :
                break;
        }

        return output;
    }
}
