import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {EditPNSLocation} from '@portal-ng/PNSLocation/actions/PNSLocation.action';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSUser} from '@portal-ng/PNSModels';
import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'portal-ng-pnslocation-unit',
  templateUrl: './pnslocation-unit.component.html',
  styleUrls: ['./pnslocation-unit.component.scss'],
})
export class PNSLocationUnitComponent implements OnInit, OnDestroy {
  operationalFilter: boolean;
  locationID: string;
  loc$: Subscription;
  dataSource = new MatTableDataSource();
  toggledLocation$: Partial<IPNSLocation> = {
    id: null,
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filter: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'actions', 'name', 'abbreviation', 'operational'];

  authenticatedUser$: Subscription;
  private authUser: Partial<IPNSUser>;

  constructor(
    private store: Store<IAuthState>,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.operationalFilter = true;
    this.locationID = this.route.snapshot.params['locationID'];
    this.refresh();

    this.authenticatedUser$ = this.store.pipe(select(fromAuth.getAuthenticatedUser))
      .subscribe((authUser) => {
        this.authUser = authUser;
      });
  }

  toggleOperational(id: string) {
    if (id !== undefined && id !== null) {
      this.loc$ = this.store.pipe(select(fromLocation.getLocationByID(id))).subscribe((location) => {
        if (location !== undefined && location !== null) {
          this.toggledLocation$ = location;
        }
      });
    }

    const updatedLocation = {
      ...this.toggledLocation$,
      operational: !this.toggledLocation$.operational,
    };

    this.store.dispatch(new EditPNSLocation(updatedLocation));
  }

  ngOnDestroy() {
    // this.user$.unsubscribe();
  }

  applyFilter(filter: EventTarget) {
    const element = filter as HTMLInputElement;
    let filterValue: string;

    if (element.value !== undefined) {
      filterValue = element.value.trim().toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    } else {
      this.refresh();
    }
  }

  refresh() {
    if (this.operationalFilter) {
      this.store.pipe(select(fromLocation.getOperationalLocationUnits(this.locationID)))
        .subscribe((data) => {
          console.log(data);
          this.dataSource = new MatTableDataSource<IPNSLocation>(data);

          setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filter = this.filter;
          });

        });
    } else {
      this.store.pipe(select(fromLocation.getLocationUnits(this.locationID)))
        .subscribe((data) => {
          console.log(data);
          this.dataSource = new MatTableDataSource<IPNSLocation>(data);

          setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filter = this.filter;
          });

        });
    }

  }

}
