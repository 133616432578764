import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IPNSTableButton, IPNSTableColumnConfiguration, IPNSTableFilter, PNSColumnType, PNSFilterType} from '@portal-ng/PNSModels';
import {PNSVisitService} from '@portal-ng/PNSServices/PNSVisit.service';
@Component({
  selector: 'portalNG-PNSTable',
  templateUrl: './PNSTable.component.html',
  styleUrls: ['./PNSTable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PNSTableComponent implements OnInit, OnChanges {

  @Input() tableData: any[];
  @Input() filters: IPNSTableFilter[];
  @Input() button: IPNSTableButton;
  @Input() columnConfiguration: IPNSTableColumnConfiguration[];

  @Output() refresh = new EventEmitter<boolean>();
  @Output() complexFilter = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columnType = PNSColumnType;
  filterType = PNSFilterType;

  displayedColumns: string[];
  filter: string;
  dataSource: MatTableDataSource<any>;

  constructor() {
  }

  getVisitStatus(data) {
    PNSVisitService.getVisitStatus(data);
  }

  getLocation(data) {
    return PNSVisitService.getPatientLocationFromVisitSummary(data);
  }

  displayUser(data: any) {
     console.log(data);
  }

  applyFilter(filter: EventTarget) {
    const element = filter as HTMLInputElement;
    let filterValue: string;

    if (element.value !== undefined) {
      filterValue = element.value.trim().toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    } else {
      this.refresh.emit(true);
    }
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sortingDataAccessor = this.pathDataAccessor;
    this.dataSource.sort = this.sort;
    this.displayedColumns = this.columnConfiguration.map((a) => a.name);
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = this.pathDataAccessor;
    this.dataSource.sort = this.sort;
    this.displayedColumns = this.columnConfiguration.map((a) => a.name);

  }

  pathDataAccessor(item: any, path: string): any {
    const x = path.split('.')
      .reduce((accumulator: any, key: string) => {
        return accumulator ? accumulator[key] : undefined;
      }, item);
    console.log('PATH DATA ACCESSOR: ' + x);
    return x;
  }

  concatPathDataAccessor(item: any, paths: string[], delimiter: string): any {
    let value = '';
    paths.forEach((path) => {
      if (this.pathDataAccessor(item, path) !== null) {
        value = value.concat(this.pathDataAccessor(item, path) + delimiter);
      }
    });
    return value.substr(0, (value.length - delimiter.length));
  }
}
