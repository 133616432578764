import {Component, Input, OnInit} from '@angular/core';
import {INewRelicApplication} from '@portal-ng/PNSModels';

@Component({
  selector: 'portalNG-status-badge-component',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnInit {
  @Input() application: INewRelicApplication;
  @Input() appName: string;

  constructor() { }

  ngOnInit() {}

  generateStatus(appHealth: string): string {
    let status: string;

    if (appHealth === 'green') {
      status = 'OK';
    } else if (appHealth === 'orange') {
      status = 'WARNING';
    } else if (appHealth === 'red') {
      status = 'ERROR';
    } else if (appHealth === 'unknown') {
      status = 'UNKNOWN';
    } else {
      status = 'NO DATA';
    }

    return this.appName + ' ' + status;

  }

}
