<div id="page">

  <h1 class="mat-display-1"><button mat-mini-fab color="accent" routerLink="/admin/locations/facility/{{locationID}}/units/">
    <mat-icon svgIcon="arrow_back"></mat-icon></button>Unit Status Board <button mat-icon-button><mat-icon aria-label="Reload Unit Status Board" (click)="refresh()" >cached</mat-icon></button></h1>

<div *ngIf="polledSummaries$ !== null && polledSummaries$ !== undefined && unit$ !== undefined">
  <h1 class="mat-h1">{{this.unit$.name}} Care360 Status <button mat-icon-button><mat-icon aria-label="Reload Unit Status Board" (click)="refresh()" >cached</mat-icon></button></h1>
  <div *ngFor="let ps of polledSummaries$ | async">
    <div [class]="this.getStatus(ps)"><h2 class="status"> {{ps.visit.room | nullPipe}} &middot; {{ps.visit.bed | nullPipe}} </h2></div>
  </div>
</div>
</div>
