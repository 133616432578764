<div id="user-list-page"><h1 class="mat-display-1">Facility List <button mat-icon-button><mat-icon aria-label="Reload Facility List" (click)="refresh()" >cached</mat-icon></button></h1>

<div *ngIf="dataSource.data != null">
  <div id="filter">
    <mat-form-field class="filter">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target)" placeholder="Filter">
    </mat-form-field>
    <mat-checkbox selected="true" [(ngModel)]="operationalFilter" (change)="refresh()">Operational Only</mat-checkbox>
    <button mat-icon-button routerLink="/admin/locations/facility/add"><mat-icon svgIcon="plus"></mat-icon></button>
  </div>
  <div class="mat-elevation-z8">
      <table mat-table #table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Location ID</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <ng-container matColumnDef="operational">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Operational</th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary" (click)='toggleOperational(row.id)'
                          [checked]="row.operational"></mat-checkbox>
          </td>
        </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Name</th>
        <td mat-cell *matCellDef="let row">{{row.name | nullPipe }}</td>
      </ng-container>

      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
        <td mat-cell *matCellDef="let row">{{row.organizationName | nullPipe }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Type</th>
        <td mat-cell *matCellDef="let row">{{row.type | nullPipe}}</td>
      </ng-container>

      <ng-container matColumnDef="configure">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" (click)="reloadLocation(row.id)"><mat-icon svgIcon="reload"></mat-icon></button>
            <button mat-icon-button color="primary" (click)="openFormDialog(row.id)"><mat-icon svgIcon="clipboard-list-outline"></mat-icon></button>
            <button mat-icon-button routerLink="/admin/locations/facility/{{row.id}}/configure/basic" color="primary"><mat-icon svgIcon="cog"></mat-icon></button>
          <button mat-icon-button routerLink="/admin/locations/facility/{{row.id}}/units" color="primary"><mat-icon svgIcon="view-comfy"></mat-icon></button>
          <button mat-icon-button routerLink="/admin/locations/facility/{{row.id}}/dataQualityIssue" color="primary"><mat-icon svgIcon="clipboard-alert"></mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <!--suppress JSUnusedLocalSymbols -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="100"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </div>
</div>
</div>
