import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {AdminEntityActionTypeUnion} from '@portal-ng/PNSAdminEntities/actions/';
import * as fromApplication from './PNSApplication.reducer';
import * as fromCode from './PNSCode.reducer';
import * as fromConnection from './PNSConnection.reducer';
import * as fromExecutions from './PNSExecutions.reducer';
import * as fromJobs from './PNSJobs.reducer';
import * as fromJobTypes from './PNSJobTypes.reducer';

export interface IPNSAdminEntityState {
  connections: fromConnection.IPNSConnectionState;
  executions: fromExecutions.IPNSExecutionsState;
  jobs: fromJobs.IPNSJobsState;
  jobTypes: fromJobTypes.IPNSJobTypesState;
  code: fromCode.IPNSCodeState;
  application: fromApplication.IPNSApplicationState;
}

export const reducers: ActionReducerMap<IPNSAdminEntityState, AdminEntityActionTypeUnion> = {
  jobs       : fromJobs.reducer,
  jobTypes   : fromJobTypes.reducer,
  connections: fromConnection.reducer,
  executions : fromExecutions.reducer,
  code       : fromCode.reducer,
  application: fromApplication.reducer,
};

export const getTasksState = createFeatureSelector<IPNSAdminEntityState>('adminEntities');

export const getJobsState     = createSelector(getTasksState, (state: IPNSAdminEntityState) => state.jobs);
export const getJobTypesState = createSelector(getTasksState, (state: IPNSAdminEntityState) => state.jobTypes);

export const getConnectionState = createSelector(getTasksState, (state: IPNSAdminEntityState) => state.connections);
export const getExecutionsState = createSelector(getTasksState, (state: IPNSAdminEntityState) => state.executions);

export const getApplicationsState = createSelector(getTasksState, (state: IPNSAdminEntityState) => state.application);

export const getJobsLoaded = createSelector(getJobsState, (state: fromJobs.IPNSJobsState) => state.loaded);
export const getAllJobs    = createSelector(getJobsState, fromJobs.selectAllJobs);
export const getJobsByID   = (id: string) => createSelector(getJobsState, (job) => job[id]);

export const getJobTypesLoaded = createSelector(getJobTypesState, (state: fromJobTypes.IPNSJobTypesState) => state.loaded);
export const getAllJobTypes    = createSelector(getJobTypesState, fromJobTypes.selectAllJobTypes);
export const getJobTypesByID   = (id: string) => createSelector(getJobTypesState, (jobType) => jobType[id]);

export const getConnectionsLoaded = createSelector(getConnectionState, (state: fromConnection.IPNSConnectionState) => state.loaded);
export const getAllConnections    = createSelector(getConnectionState, fromConnection.selectAllConnections);

export const getExecutionsLoaded = createSelector(getExecutionsState, (state: fromExecutions.IPNSExecutionsState) => state.loaded);
export const getAllExecutions    = createSelector(getExecutionsState, fromExecutions.selectAllExecutions);

export const getApplicationsLoaded = createSelector(getApplicationsState, (state: fromApplication.IPNSApplicationState) => state.loaded);
export const getAllApplications    = createSelector(getApplicationsState, fromApplication.selectAllApplications);
export const getApplicationByType  = (type: string) =>
  createSelector(getAllApplications, (app) => (app.filter((x) => x.type === type)));
