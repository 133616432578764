import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSOrganization} from '@portal-ng/PNSModels';
import * as PNSOrganizationActions from '../actions/PNSOrganization.action';

export interface IPNSOrganizationState extends EntityState<IPNSOrganization> {
  loaded: boolean;
}

export const adapter: EntityAdapter<IPNSOrganization> = createEntityAdapter<IPNSOrganization>({
  selectId: (organization: IPNSOrganization) => organization.id,
  sortComparer: false,
});

const initialState: IPNSOrganizationState = adapter.getInitialState({
  selectedOrganizationId: null,
  loaded: false,
});

export function reducer(state: IPNSOrganizationState = initialState, action: PNSOrganizationActions.PNSOrganizationActionTypeUnion): IPNSOrganizationState {
  switch (action.type) {
    case PNSOrganizationActions.PNSOrganizationActionTypes.LoadPNSOrganizations:
      return {
        ...state,
      };

    case PNSOrganizationActions.PNSOrganizationActionTypes.EditPNSOrganizationSuccess:
      const changes = action.payload.organization;

      const id = Number.parseInt(changes.id, 10);
      return adapter.updateOne({id, changes}, state);

    case PNSOrganizationActions.PNSOrganizationActionTypes.LoadPNSOrganizationsSuccess:
      const updateState = {...state,
        loaded: true,
      };
      return adapter.setAll(action.payload.response.results, updateState);

    default:
      return state;
  }
}
