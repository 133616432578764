import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Action, MemoizedSelector, select, Store} from '@ngrx/store';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import {LoadPNSOrganizations} from '@portal-ng/PNSOrganization/actions/PNSOrganization.action';
import * as fromOrganization from '@portal-ng/PNSOrganization/reducers';
import {PNSOrganizationService} from '@portal-ng/PNSServices/PNSOrganization.service';
import {take} from 'rxjs/operators';

@Injectable()
export class PNSOrganizationResolver implements Resolve<void> {

  constructor(private store: Store<IAppState>) { }

  entityName = 'Organizations';
  selectMethod: MemoizedSelector<any, any> = fromOrganization.getOrganizationsLoaded;
  selectAction: Action = new LoadPNSOrganizations(PNSOrganizationService.getDefaultSearch());

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.pipe(select(this.selectMethod), take(1)).subscribe((hasLoaded) => {
      if (!hasLoaded) {
        this.store.dispatch(this.selectAction);
      } else {
        console.info(this.entityName + ' loading skipped, ' + this.entityName + ' are already loaded.');
      }
    });
  }
}
