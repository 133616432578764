import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSCode, IPNSCodeSearchResults} from '@portal-ng/PNSModels';
import {IPNSCodeSearch} from '@portal-ng/PNSModels/PNSCodeSearch.model';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class PNSCodeService {

  static locationId: string;

  constructor(
    private store: Store<IAuthState>,
    private auth: PNSAuthService,
    private PNS: PNSService) {
  }

  static getDefaultSearch(): IPNSCodeSearch {
    return this.defaultSearch() as IPNSCodeSearch;
  }

  static searchByCategory(searchCategory: string) {
    return {
      category: searchCategory,
      active  : true,
      limit   : environment.PNS.searchLimit,
      offset  : 0,
    };
  }

  static defaultSearch(): IPNSCodeSearch {
    return {
      category: 'VENDOR',
      limit   : environment.PNS.searchLimit,
      offset  : 0,
    };
  }

  static parameterizeSearch(search: IPNSCodeSearch): HttpParams {
    let params: HttpParams;
    if (search.active !== undefined) {
      params = new HttpParams()
        .set('category', search.category)
        .set('limit', environment.PNS.searchLimit.toString(10))
        .set('offset', search.offset.toString(10))
        .set('active', String(search.active));
    } else {
      params = new HttpParams()
        .set('category', search.category)
        .set('limit', environment.PNS.searchLimit.toString(10))
        .set('offset', search.offset.toString(10));
    }
    return params;
  }

  getCode(id: string): Observable<IPNSCode> {
    return this.PNS.authenticateGet('codes/' + id);
  }

  loadCodes(search: IPNSCodeSearch): Observable<IPNSCodeSearchResults> {
    return this.PNS.authenticateGet('codes', PNSCodeService.parameterizeSearch(search));
  }

}
