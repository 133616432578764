import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSSearchResults, IPNSVisit, IPNSVisitSummary, IPNSVisitSummarySearch, IPNSVisitSummarySearchResults} from '@portal-ng/PNSModels';
import {IPNSVisitSearch} from '@portal-ng/PNSModels/PNSVisit.model';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable} from 'rxjs';
import {VisitSearchType} from '@portal-ng/PNSServices/PNSPatient.service';

@Injectable({
  providedIn: 'root',
})

export class PNSVisitService {

  static getDefaultSearch(): IPNSVisitSearch {
    return PNSService.defaultSearch() as IPNSVisitSearch;
  }

  static getVisitStatus(x: IPNSVisitSummary): string {
    return x.visitAttributes.filter((summary) => summary.attributeId === 6)[0].valueCode.title;
  }

  static getPatientLocationFromVisitSummary(x: IPNSVisitSummary) {
    const formattedArray: string[] = [];

    if (x.visit.room !== null) {
      formattedArray.push(x.visit.room);
    }

    if (x.visit.bed !== null) {
      formattedArray.push(x.visit.bed);
    }

    return formattedArray.join(' | ');
  }


  static getDefaultSummarySearch(locationId: string): IPNSVisitSummarySearch {
    const defaultSearch = PNSService.defaultSearch() as IPNSVisitSummarySearch;
    defaultSearch.locationId = locationId;
    defaultSearch.searchTerm = '';
    defaultSearch.sortField = 'scheduledAppointment.date';
    defaultSearch.sortOrder = 'desc';

    return defaultSearch;
  }

  static getSingleSearch(locationId: string, MRN: string): IPNSVisitSummarySearch {
    const defaultSearch = PNSService.defaultSearch() as IPNSVisitSummarySearch;
    defaultSearch.locationId = locationId;
    defaultSearch.searchTerm = MRN;
    defaultSearch.sortField = 'scheduledAppointment.date';
    defaultSearch.sortOrder = 'desc';

    return defaultSearch;
  }

  static getUnitSearch(locationId: string, unitID: string): IPNSVisitSummarySearch {
    const defaultSearch = PNSService.defaultSearch() as IPNSVisitSummarySearch;
    defaultSearch.locationId = locationId;
    defaultSearch.unitID = unitID;
    defaultSearch.sortField = 'visit.room';
    defaultSearch.sortOrder = 'asc';

    return defaultSearch;
  }

  constructor(
    private PNS: PNSService) {
  }




  // CRUD functions
  getVisit(visit: Partial<IPNSVisit>): Observable<any> {
    return this.PNS.authenticateGet('visits/' + visit.id);
  }

  getVisitById(visitId: string): Observable<any> {
    return this.PNS.authenticateGet('visits/' + visitId);
  }

  addVisit(Visit: Partial<IPNSVisit>): Observable<any> {
    return this.PNS.authenticatePost('Visits', Visit);
  }

  getVisitSummaryFromVisitId(visitId: string): Observable<any> {
    return this.PNS.authenticateGet('visits/' + visitId + '/patientsummary');
  }

  visitSearch(payload: IPNSVisitSearch): Observable<IPNSSearchResults> {

    let params = new HttpParams();

    if ('externalId' in payload) {
      params = params.set('externalId', payload.externalId.toString());
    }

    params = PNSService.setCommonSearchParams(params, payload);
    return this.PNS.authenticateGet('visits', params);
  }

  loadVisits(payload: IPNSVisitSearch): Observable<IPNSSearchResults> {
    let params = new HttpParams();

    // if ('parentId' in payload) {
    //    params = params.set('parentId', payload.parentId.toString());
    // }

    params = PNSService.setCommonSearchParams(params, payload);
    return this.PNS.authenticateGet('visit', params);
  }

  getVisitSummariesByUnitID(locationID: string, unitID: string) {
    let params = new HttpParams();

    const search: IPNSVisitSummarySearch = PNSVisitService.getUnitSearch(locationID, unitID);

    params = PNSService.setCommonSearchParams(params, search);

    params = params.set('locationId', search.locationId)
      .set('sortField', search.sortField)
      .set('unitId', search.unitID)
      .set('sortOrder', search.sortOrder);
    return this.PNS.authenticateGet('pns/dashboard', params);

  }

  getActiveVisitSummariesByUnitID(locationID: string, unitID: string) {
    let params = new HttpParams();

    const search: IPNSVisitSummarySearch = PNSVisitService.getUnitSearch(locationID, unitID);

    params = PNSService.setCommonSearchParams(params, search);

    params = params
      .set('locationId', search.locationId)
      .set('sortField', search.sortField)
      .set('unitId', search.unitID)
      .set('sortOrder', search.sortOrder)
      .set('includeStatus', 'Active');

    return this.PNS.authenticateGet('pns/dashboard', params);

  }

  // Takes location, MRN and either current or historic search.

  findVisitSummaryByMRN(locationID: string, MRN: string, type: VisitSearchType): Observable<IPNSVisitSummarySearchResults> {
    let params = new HttpParams();

    const search: IPNSVisitSummarySearch = PNSVisitService.getSingleSearch(locationID, MRN);

    params = PNSService.setCommonSearchParams(params, search);
    params = params.set('locationId', search.locationId)
      .set('sortField', search.sortField)
      .set('searchTerm', search.searchTerm)
      .set('sortOrder', search.sortOrder);

    if (type === VisitSearchType.Historic) {
      params = params.set('oldVisits', 'true')
        .set('includePnsStatus', 'new,active,closed')
        .set('sortField', 'startDate')
        .set('sortOrder', 'desc'); // asc or desc
    }

    return this.PNS.authenticateGet('pns/dashboard', params);

  }

  getVisitSummaryByVisitID(locationId: string, visitID: string): Observable<IPNSVisitSummary> {
    return this.PNS.authenticateGet('visits/' + visitID + '/patientsummary');
  }



}
