import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSJobTypes} from '@portal-ng/PNSModels';
import * as PNSJobTypesActions from '../actions/PNSJobTypes.action';

export interface IPNSJobTypesState extends EntityState<IPNSJobTypes> {
  loaded: boolean;
  selectedId: string;
}

export const adapter: EntityAdapter<IPNSJobTypes> = createEntityAdapter<IPNSJobTypes>({
  selectId: (jobTypes: IPNSJobTypes) => jobTypes.id,
  sortComparer: false,
});

const initialState: IPNSJobTypesState = adapter.getInitialState({
  selectedId: null,
  loaded: false,
});

export function reducer(state: IPNSJobTypesState = initialState, action: PNSJobTypesActions.PNSJobTypesActionTypeUnion): IPNSJobTypesState {
  switch (action.type) {

    case PNSJobTypesActions.PNSJobTypesActionTypes.LoadPNSJobTypesSuccess:
      const updateState = {...state,
        loaded: true,
      };
      const searchResults: IPNSJobTypes[] = action.payload.jobTypes;
      return adapter.setAll(searchResults, updateState);

    default:
      return state;
  }
}

export const getSelectedJobTypesId = (state: IPNSJobTypesState) => state.selectedId;
export const getJobTypesLoaded = (state: IPNSJobTypesState) => state.loaded;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectJobTypesIds = selectIds;

// select the dictionary of user entities
export const selectJobTypesEntities = selectEntities;

// select the array of users
export const selectAllJobTypes = selectAll;

// select the total user count
export const selectJobTypesTotal = selectTotal;
