import {PEALabeledValue} from '@portal-ng/PNSModels/PEABasicTypes.model';

export enum IPNSAppIdentifier {
  'announcement' = 'announcement',
  'warning' = 'warning',
  'runner' = 'runner',
  'c2d'    = 'c2d',
  'pos'    = 'pos',
  'portal' = 'portal',
}

export const AppDirectories: PEALabeledValue[] = [
  {label: 'Announcement', value: 'announcement'},
  {label: 'Warning', value: 'warning'},
  {label: 'C2D', value: 'c2d'},
  {label: 'Portal', value: 'portal'},
  {label: 'POS', value: 'pos'},
  {label: 'Runner', value: 'runner'},
];

export const buildTypes: PEALabeledValue[]     = [
  {label: 'PROD', value: 'PROD'},
  {label: 'DEV', value: 'DEV'},
];

export const announcementType: PEALabeledValue[]     = [
  {label: 'Active', value: 'Active'},
  {label: 'Inactive', value: 'Inactive'},
];

export const tenants: PEALabeledValue[]        = [
  {label: 'Advent', value: 'Advent'},
  {label: 'Walgreens', value: 'Walgreens'},
];

export interface IPNSWhatsNewItem {
  appIdentifier: IPNSAppIdentifier;
  buildType: string;
  majorVersionNumber?: string;
  minorVersionNumber?: string;
  announcementTitle?: string;
  patchNumber?: string;
  buildNumber?: string;
  active?: boolean;
  announcementType?: string;
  versionNumber: string;
  displayVersion: string;
  releaseDate?: string;
  overview: string;
  releaseItems: IPNSReleaseItem[];
}

export interface IPNSReleaseItem {
  tenants?: string[];
  icon?: string;
  svgIcon?: string;
  title: string;
  description: string;
  media?: string;
  reportedBy?: string[];
}
