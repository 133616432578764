import {Injectable} from '@angular/core';
import {PNSTreeSelectListData, PNSTreeSelectListNode} from '@portal-ng/PNSShared/components/PNSTreeSelectList/PNSTreeSelectList.model';
import {BehaviorSubject} from 'rxjs';

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */

@Injectable({providedIn: 'root'})

export class PNSTreeSelectListController {
  dataChange = new BehaviorSubject<PNSTreeSelectListNode[]>([]);

  get data(): PNSTreeSelectListNode[] {
    return this.dataChange.value;
  }

  constructor() {
  }

  loadData(treeData: PNSTreeSelectListData) {
    // Build the tree nodes from Json object. The result is a list of `PNSTreeSelectListNode` with nested
    //     file node as children.
    const data = this.buildFileTree(treeData.ROOT, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `PNSTreeSelectListNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): PNSTreeSelectListNode[] {

    return Object.keys(obj).reduce<PNSTreeSelectListNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new PNSTreeSelectListNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.value = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to tree */
  insertItem(parent: PNSTreeSelectListNode, name: string) {
    if (parent.children) {
      parent.children.push({item: name} as PNSTreeSelectListNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: PNSTreeSelectListNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}
