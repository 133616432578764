import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSLocation, IPNSSearchResults} from '@portal-ng/PNSModels';
import {IPNSApplicationSearch} from '@portal-ng/PNSModels/PNSApplicationSearch.model';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class PNSConnectionService {

  static parameterizeSearch(search: IPNSApplicationSearch): HttpParams {
    return new HttpParams()
      .append('limit', environment.PNS.searchLimit.toString(10))
      .append('offset', search.offset.toString(10));
  }

  static defaultSearch(): IPNSApplicationSearch {
    return {
      locationId    : '',
      limit         : environment.PNS.searchLimit,
      offset        : 0,
    };
  }

  constructor(
    private PNS: PNSService,
  ) {}

  static searchByLocation(location: IPNSLocation): IPNSApplicationSearch {
    return {
      locationId    : location.id,
      limit         : environment.PNS.searchLimit,
      offset        : 0,
    };
  }

  load(search: IPNSApplicationSearch): Observable<IPNSSearchResults> {
    const url = this.PNS.getEndpoint() + 'applications';
    return this.PNS.authenticateGet(url, PNSConnectionService.parameterizeSearch(search));
  }

}
