import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {IPNSFormTemplate, IPNSLocation} from '@portal-ng/PNSModels';
import {Store} from '@ngrx/store';
import {PNSFormTemplateService} from '../../../PNSServices/PNSFormTemplate.service';
import {MatTableDataSource} from '@angular/material/table';
import {getFormTemplates} from '@portal-ng/PNSForms/reducers';

@Component({
  selector   : 'portalNG-PNSFormsIndex',
  templateUrl: './PNSFormTemplateList.component.html',
  styleUrls  : ['./PNSFormTemplateList.component.scss']
})
export class PNSFormTemplateListComponent implements OnInit {
  tableData: MatTableDataSource<IPNSFormTemplate>;
  selectedLocationId: string;
  loaded = false;
  locations: IPNSLocation[];
  activeOnly                                          = false;
  columnsToDisplay = ['id', 'displayName', 'topic'];

  constructor(
      private store: Store,
      private formService: PNSFormTemplateService,
      private cdf: ChangeDetectorRef) { }

  filter(event) {
    this.refresh();
  }

  refresh() {

    this.store.select(getFormTemplates).subscribe((result) => {
      this.tableData.data = result;
      this.cdf.detectChanges();
      this.loaded = true;
    });
  }

  ngOnInit(): void {
    this.tableData = new MatTableDataSource();
    this.refresh();
  }
}
