import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSJobExecutionRequest, IPNSJobTypes, IPNSSearchResults} from '@portal-ng/PNSModels/';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PNSTaskService {

  constructor(
    private PNS: PNSService,
  ) {}

  loadConnections(): Observable<IPNSSearchResults> {
    return this.PNS.authenticateGet( 'connections');
  }

  loadExecutions(): Observable<IPNSSearchResults> {
    const params = PNSService.setCommonSearchParams(new HttpParams(), PNSService.defaultSearch());
    return this.PNS.authenticateGet('jobs/executions', params);
  }

  loadJobs(): Observable<IPNSSearchResults> {
    return this.PNS.authenticateGet('jobs');
  }

  loadJobTypes(): Observable<IPNSJobTypes[]> {
    return this.PNS.authenticateGet('jobs/types');
  }

  executeJob(job: IPNSJobExecutionRequest): Observable<any> {
    return this.PNS.authenticatePost('jobs/executions/jsonexecutor', job);
  }

}
