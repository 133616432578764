import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';

export const reducer = fromAuth.reducer;

export const getAuthModuleState = createFeatureSelector<IAuthState>('auth');
export const getPreAuthenticationURL  = createSelector(getAuthModuleState, (state: IAuthState) => state.hasOwnProperty('preAuthenticationURL') ? state.preAuthenticationURL : '');
export const getPostAuthenticationURL = createSelector(getAuthModuleState, (state: IAuthState) => state.hasOwnProperty('postAuthenticationURL') ? state.postAuthenticationURL : '');

export const getAuthenticatedUser     = createSelector(getAuthModuleState, (state: IAuthState) => state.authenticatedUser);
export const getAuthenticationNeeded  = createSelector(getAuthModuleState, (state: IAuthState) => state.needsAuthentication);
export const isAuthenticated          = createSelector(getAuthModuleState, (state: IAuthState) => state.authenticated);
export const getIDToken               = createSelector(getAuthModuleState, (state: IAuthState) => state.authentication.id_token);
export const getAuthenticatedUsername = createSelector(getAuthModuleState, (state: IAuthState) => state.authenticatedUser, (state, user) => user.username);
export const getUserPrivileges        = createSelector(getAuthModuleState, (state: IAuthState) => state.authenticatedUser, (state, user) => user.privilegeNames);
