<mat-toolbar color="primary">
  <span><mat-icon svgIcon="insights"></mat-icon>PNS Data Viewer</span>
  <span class="spacer"></span>
  <a mat-icon-button class="example-icon favorite-icon" aria-label="Download" [href]='downloadData'
     download="download.json">
    <mat-icon svgIcon="cloud_download"></mat-icon>
  </a>
</mat-toolbar>
<div id="consolas">
  <ngx-json-viewer [json]="data"></ngx-json-viewer>
</div>
