import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nullPipe',
})
export class NullPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value === undefined || value === null || value.toString().toLowerCase() === 'null' || value === '') {
      if (args !== undefined) {
        return args;
      } else {
        return '-';
      }
    } else {
      return value;
    }
  }

}
