import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSLocation, IPNSUser} from '@portal-ng/PNSModels';
import {MicrosoftService} from '@portal-ng/PNSServices/Microsoft.service';
import {PNSUserService} from '@portal-ng/PNSServices/PNSUser.service';
import {PNSUserEffects} from '@portal-ng/PNSUser/effects/PNSUser.effects';
import * as fromUser from '@portal-ng/PNSUser/reducers';
import {Subscription} from 'rxjs';

@Component({
  selector: 'portalNG-PNGUser-audit',
  templateUrl: './pnsuser-audit.component.html',
  styleUrls: ['./pnsuser-audit.component.scss'],
})
export class PNSUserAuditComponent implements OnInit, OnDestroy {

  id = '';
  userLoaded: boolean;

  route$: Subscription;
  user$: Subscription;
  locs$: Subscription;

  allLocations: IPNSLocation[] = [];

  auditUser$: Partial<IPNSUser> = {
    id: this.id,
    updatedBy: '',
    forcePasswordReset: false,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    name: '',
    roles: [],
    locations: [],
  };

  constructor(
    private msService: MicrosoftService,
    private userEffects: PNSUserEffects,
    private userService: PNSUserService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAuthState>,
    private cdf: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.route$ = this.route.params.subscribe((params) => this.id = params.id);

    if (this.auditUser$ !== undefined) {

      if (this.id !== undefined && this.id !== null) {
        this.user$ = this.store.pipe(select(fromUser.getUserById(this.id))).subscribe((user) => {
          if (user !== undefined && user !== null) {
            this.userLoaded = true;
            this.auditUser$ = user;
            this.cdf.markForCheck();
          }
        });
      }

      this.locs$ = this.store.pipe(
        select(fromLocation.getAllLocations))
        .subscribe((data) => {
          if (data !== null && data !== undefined) {
            this.allLocations = data;
            this.cdf.markForCheck();
          }
        });
    }
  }

  ngOnDestroy(): void {

  }

  getLocationFromID(id: string): IPNSLocation {
    return this.allLocations.filter((x) => x.id.toString() === id).pop();
  }

}
