export class PNSTreeSelectListNode {
    children!: PNSTreeSelectListNode[];
    item: string;
    value: string;
}


export class PNSTreeSelectListFlatNode {
    item: string;
    value: string;
    level: number;
    expandable: boolean;
}

export class PNSTreeSelectListData {
    ROOT: any;
}

export class PNSEnvironmentDataMap {
    DEV?: PNSTreeSelectListData;
    PROD?: PNSTreeSelectListData;
    TEST?: PNSTreeSelectListData;
}

export class PNSTreeSelectListNodeSelection {
    selected: boolean;
    node: PNSTreeSelectListFlatNode;
}
