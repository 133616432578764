<div id="user-list-page">
    <h1 class="mat-display-1">Users
        <button mat-icon-button color="primary">
            <mat-icon aria-label="Reload Userlist" (click)="reloadUserList()" svgIcon="cached"></mat-icon>
        </button>
        <button mat-icon-button color="primary">
            <mat-icon aria-label="Add User" routerLink="/admin/users/add" svgIcon="account-plus"></mat-icon>
        </button>
    </h1>

    <div *ngIf="dataSource.data != null">

        <div id="filter">
            <mat-form-field class="filter">
                <mat-label>Filter by Name</mat-label>
                <input matInput [formControl]="nameFilter" placeholder="Filter">
            </mat-form-field>
            <mat-form-field class="filter">
                <mat-label>Filter by Location</mat-label>
                <mat-select [(value)]="selected" name="filterLocationID" [(ngModel)]="filterLocationID"
                            (selectionChange)="updateListUsers()">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let loc of locs" [value]="loc.id">{{loc.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="shortfilter">
                <mat-label>Filter by User Status</mat-label>
                <mat-select [formControl]="enabledFilter" [(ngModel)]="filterValues.enabled">
                    <mat-option value="none">All Users</mat-option>
                    <mat-option value="true">Enabled Users</mat-option>
                    <mat-option value="false">Disabled Users</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="mat-elevation-z8">
            <table mat-table #table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.id}} </td>
                </ng-container>


                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
                    <td mat-cell *matCellDef="let row">{{row.username}}</td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let row">
                        <a [href]="['mailto://' + row.email]" matTooltip="Email User">{{row.email | emailAddress}}</a>
                        </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let row">{{row.name}}</td>
                </ng-container>

                <ng-container matColumnDef="locked">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Locked</th>
                    <td mat-cell *matCellDef="let row"><i *ngIf="row.locked === true"
                                                          class="material-icons">lock</i><i
                            *ngIf="row.locked === false" class="material-icons" color="primary">lock_open</i></td>
                </ng-container>

                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngIf="row.phoneNumber !== undefined">
                            <a [href]="'tel:' + row.phoneNumber">
                                <mat-icon svgIcon="phone"></mat-icon>
                                <span class="aligned-with-icon">{{row.phoneNumber | phoneNumber}}</span></a>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>

                    <td mat-cell *matCellDef="let row">
                        <a mat-icon-button color="primary" class="teamsLink" target="_blank" (click)="$event.stopPropagation();" [href]="['https://teams.microsoft.com/l/chat/0/0?users=' + row.email]" matTooltip="Teams Chat with User">
                            <mat-icon svgIcon="microsoft-teams"></mat-icon>
                        </a>
                        <a mat-icon-button (click)="$event.stopPropagation();"  [href]="['tel://' + row.phoneNumber]" matTooltip="Call User">
                            <mat-icon svgIcon="phone"></mat-icon>
                        </a>
                        <button mat-icon-button color="primary" (click)="reloadUser(row.username)"
                                matTooltip="Reload User Location Configuration">
                            <mat-icon svgIcon="reload"></mat-icon>
                        </button>
                        <button *ngIf="row.enabled" color="green" mat-icon-button (click)="$event.stopPropagation(); toggleEnable(row.id, true)"><mat-icon svgIcon="account-check"></mat-icon></button>
                        <button *ngIf="!row.enabled" color="warn" mat-icon-button (click)="$event.stopPropagation(); toggleEnable(row.id, false)"><mat-icon svgIcon="account-cancel"></mat-icon></button>

                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row"><button mat-icon-button color="primary" (click)='deleteUser(row.username, row.id)'><mat-icon color="warn" svgIcon="delete"></mat-icon></button></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editUser(row.id)"
                    [ngClass]="{disabled: (row.enabled === false)}"></tr>
            </table>

            <mat-paginator #paginator
                           [length]="dataSource.data.length"
                           [pageIndex]="0"
                           [pageSize]="100"
                           [pageSizeOptions]="[25, 50, 100, 250]">
            </mat-paginator>
        </div>
    </div>
</div>
