import {PNSUXActions} from '@portal-ng/PNSCore/actions';
import {IPNSMenuStatus, PNSStatusType} from '@portal-ng/PNSModels/PNSUX.model';

export interface IPNSUXState {
  drawer: IPNSMenuStatus;
  status: PNSStatusType;
}

export const initialState: IPNSUXState = {
  drawer: {
    admin: false,
  },
  status: PNSStatusType.disconnected,
};

// Pass in Error actions and Auth actions so Errors can be reset on Logout

export function reducer(
  state = initialState,
  action: PNSUXActions.PNSUXActionsUnion): IPNSUXState {
  switch (action.type) {
    case PNSUXActions.PNSUXActionTypes.PNSUXModifyDrawer: {
      return {
        ...state,
        drawer: action.payload.drawer,
      };
    }

    case PNSUXActions.PNSUXActionTypes.PNSUXSetStatus: {
      return {
        ...state,
        status: action.payload.status,
      };
    }

    default: {
      return state;
    }
  }
}

export const getDrawer = (state: IPNSUXState) => state.drawer;
export const getStatus = (state: IPNSUXState) => state.status;
