import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {PNSLocationActionTypeUnion} from '@portal-ng/PNSLocation/actions/PNSLocation.action';
import {IPNSLocation} from '@portal-ng/PNSModels';
import * as fromLocation from './PNSLocation.reducer';

export interface IPNSLocationModuleState {
    location: fromLocation.IPNSLocationState;
}

export const reducers: ActionReducerMap<IPNSLocationModuleState, PNSLocationActionTypeUnion> = {
    location: fromLocation.reducer,
};

export const getLocEntityState = createFeatureSelector<fromLocation.IPNSLocationState>('location');

export const getLocModuleState = createFeatureSelector<IPNSLocationModuleState>('location');
export const getLocState       = createSelector(getLocModuleState, (state: IPNSLocationModuleState) => state.location);

export const getLocationById       = (id: string) => (state: IPNSLocationModuleState) => state.location[id];
export const getLocationEntityByID = (id: string) => createSelector(getLocModuleState, getLocationById(id));

export const {selectIds, selectEntities, selectAll} = fromLocation.adapter.getSelectors(getLocEntityState);
export const getLocationEntities                    = createSelector(getLocEntityState, selectEntities);
export const getAllLocations                        = createSelector(getLocEntityState, selectAll);

export const getLocationUnits = (id: string) => createSelector(getAllLocations,
    (locations: IPNSLocation[]) => locations.filter((x: IPNSLocation) => x.type === 'UNIT' && x.parentId.toString() === id));

export const getOperationalLocationUnits = (id: string) => createSelector(getAllLocations,
    (locations: IPNSLocation[]) => locations.filter((x: IPNSLocation) => x.type === 'UNIT' && x.operational === true && x.parentId.toString() === id));

export const getOperationalLocation = createSelector(getAllLocations, (locations: IPNSLocation[]) => locations.filter((x) => x.operational));
export const getLocationsLoaded     = createSelector(getLocState, (state) => state.loaded);
export const getLocationByID        = (id: string) =>
    createSelector(getLocationEntities, (loc) => loc[id]);

export const getLocationByName = (name: string) =>
    createSelector(getAllLocations, (loc) => loc.filter((x) => x.name === name));

// If operational = null, return all locations regardless of status, otherwise match status as given
export const getLocationByType = (type: string) =>
    createSelector(getAllLocations, (loc) => (loc.filter((x) => x.type === type)));

export const getLocationByOrgId = (orgId: string) =>
    createSelector(getAllLocations, (loc) => (loc.filter((x) => x.organizationId === orgId)));

export const getOperationalLocationByOrgId = (orgId: string) =>
    createSelector(getOperationalLocation, (loc) => (loc.filter((x) => x.organizationId === orgId)));

export const getOperationalLocationByType = (type: string) =>
    createSelector(getOperationalLocation, (loc) => (loc.filter((x) => x.type === type)));

export const getAllLocationsArray = createSelector(getLocationEntities, (loc) => {
    return Object.keys(loc).map((id) => loc[id]);
});
