<div id="page">
  <h1 class="mat-h1">Add Location</h1>
<form #locationForm="ngForm" name="form" (ngSubmit)="addLocation()">
  <mat-form-field class="full-width">
    <input matInput placeholder="name" [(ngModel)]="addLoc$.name"  name="locationname">
    <mat-hint align="start"><strong>The location's name</strong> </mat-hint>
    <mat-hint align="end">{{addLoc$.name.length}} / 256</mat-hint>
    <mat-error *ngIf="locationForm.hasError('name') && !locationForm.hasError('name')">
      Please enter a valid Location name.
    </mat-error>
    <mat-error *ngIf="locationForm.hasError('required')">
      Location name is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <mat-label>Parent Organization</mat-label>
    <mat-select required name="orgId" [(value)]="addLoc$.organizationId" >
      <mat-option *ngFor="let org of orgs" [value]="org.id.toString()">{{org.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input matInput required placeholder="abbreviation" [(ngModel)]="addLoc$.abbreviation" name="abbreviation"/>
    <mat-hint align="start">The Location's abbreviation.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-card>
    <mat-card-header>
      <mat-card-title><mat-icon svgIcon="location_on"></mat-icon> Facility Address</mat-card-title>
  </mat-card-header>
    <mat-card-content>
  <mat-form-field class="full-width">
    <input required matInput placeholder="123 Anywhere Lane" [(ngModel)]="addLoc$.address.street1" name="street1"/>
    <mat-hint align="start">The Location's address line.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input matInput placeholder="Unit 2" [(ngModel)]="addLoc$.address.street2" name="street2"/>
    <mat-hint align="start">The Location's second address line.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input required matInput placeholder="Anywhere" [(ngModel)]="addLoc$.address.city" name="city"/>
    <mat-hint align="start">The Location's city.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input required matInput placeholder="NJ" [(ngModel)]="addLoc$.address.state" name="state"/>
    <mat-hint align="start">The Location's state.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input required matInput placeholder="08502" [(ngModel)]="addLoc$.address.zip" name="zip"/>
    <mat-hint align="start">The Location's zipcode.</mat-hint>
  </mat-form-field>

      <div *ngIf="this.addrValidated">
        <mat-form-field class="full-width" appearance="outline" >
          <input readonly required class="inline"  matInput placeholder="" [(ngModel)]="addLoc$.address.latitude" name="lat"/>
          <input readonly required class="inline" matInput placeholder="" [(ngModel)]="addLoc$.address.longitude" name="lng"/>
          <mat-hint align="start">The Location's Latitude and Longitude.</mat-hint>
        </mat-form-field>
        <br /><br />
        <mat-form-field class="full-width" appearance="outline">
          <input  matInput placeholder="" [(ngModel)]="addLoc$.timeZone" name="timezone"/>
          <mat-hint align="start">The Location's Timezone.</mat-hint>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button type='button' (click)="validateAddress(locationForm)">VALIDATE ADDRESS</button>
    </mat-card-actions>
  </mat-card>
  <br />  <br />
  <mat-form-field class="full-width">
    <mat-label>Copy Config From&hellip;</mat-label>
    <mat-select appearance="outline" required name="locId" [(value)]="copyLocConfigFrom" >
      <mat-option *ngFor="let loc of locs" [value]="loc">{{loc.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input matInput required placeholder="" [(ngModel)]="addLoc$.externalId" name="externalId"/>
    <mat-hint align="start">The Location's External identifier.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field  class="full-width">
    <label>Location Type</label>
    <mat-select required name="orgType" [(ngModel)]="addLoc$.type">
      <option value="FACILITY">Facility</option>
    </mat-select>
  </mat-form-field>

  <div class="form-actions">
    <button type="button" mat-raised-button [routerLink]="['/admin/locations/facility/list']">Cancel Adding Location</button>
    <button type="submit" mat-raised-button color="primary" disabled cdkFocusInitial [disabled]="!locationForm.valid" >Add Location</button>


  </div>
</form>
</div>
