import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Action, select, Store} from '@ngrx/store';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {take} from 'rxjs/operators';
import {LoadPNSLocations} from '../../PNSLocation/actions/PNSLocation.action';

@Injectable()
export class PNSLocationResolver implements Resolve<void> {

  constructor(private store: Store<IAppState>) {}

  entityName = 'Locations';
    selectMethod = fromLocation.getLocationsLoaded;
    selectAction: Action = new LoadPNSLocations(PNSLocationService.getDefaultSearch());

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.pipe(select(this.selectMethod), take(1)).subscribe((hasLoaded) => {
      if (!hasLoaded) {
        this.store.dispatch(this.selectAction);
      } else {
        console.info(this.entityName + ' loading skipped, ' + this.entityName + ' are already loaded.');
      }
    });
  }

}
