import {Action} from '@ngrx/store';
import {IPNSMenuStatus, PNSStatusType} from '@portal-ng/PNSModels/PNSUX.model';

export enum PNSUXActionTypes {
  PNSUXModifyDrawer = '[PNSUX] ModifyDrawer',
  PNSUXSetStatus = '[PNSUX] Set Status',

}

export class PNSUXModifyDrawer implements Action {
  readonly type = PNSUXActionTypes.PNSUXModifyDrawer;

  constructor(public payload: { drawer: IPNSMenuStatus }) {}
}

export class PNSUXSetStatus implements Action {
  readonly type = PNSUXActionTypes.PNSUXSetStatus;

  constructor(public payload: { status: PNSStatusType }) {}
}

export type PNSUXActionsUnion = PNSUXModifyDrawer | PNSUXSetStatus;
