import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as PNSApplicationActions from '@portal-ng/PNSAdminEntities/actions/PNSApplication.actions';
import {IPNSApplication} from '@portal-ng/PNSModels';

export interface IPNSApplicationState extends EntityState<IPNSApplication> {
  loaded: boolean;
  selectedApplicationId: string;
}

export const adapter: EntityAdapter<IPNSApplication> = createEntityAdapter<IPNSApplication>({
  selectId    : (application: IPNSApplication) => application.id,
  sortComparer: false,
});

const initialState: IPNSApplicationState = adapter.getInitialState({
  selectedApplicationId: null,
  loaded: false,
});

export function reducer(state: IPNSApplicationState = initialState, action: PNSApplicationActions.PNSApplicationActionsUnion): IPNSApplicationState {
  switch (action.type) {

    case PNSApplicationActions.PNSApplicationActionTypes.LoadPNSApplicationsSuccess:
      const updateState = {...state, loaded: true};
      return adapter.setAll(action.payload, updateState);

    default:
      return state;
  }
}

// get the selectors
const {selectIds, selectEntities, selectAll, selectTotal} = adapter.getSelectors();

// select the array of user ids
export const selectApplicationIds = selectIds;

// select the dictionary of user entities
export const selectApplicationEntities = selectEntities;

// select the array of users
export const selectAllApplications = selectAll;

// select the total user count
export const selectApplicationsTotal = selectTotal;
