import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import * as ErrorActions from '@portal-ng/PNSCore/actions/error.actions';
import {ErrorActionTypes, ErrorNotification} from '@portal-ng/PNSCore/actions/error.actions';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ErrorEffects {

  @Effect()
  error$: Observable<Action> = this.actions$.pipe(
      ofType<ErrorActions.APIError>(ErrorActionTypes.APIError),
      map((action) => {
        switch (action.payload.error.status) {
          case 401:
             // return new Logout({url: this.router.url});
          default:
            return new ErrorNotification( {error: action.payload.error });
        }
      }));

  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}
}
