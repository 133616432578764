import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Action, MemoizedSelector, select, Store} from '@ngrx/store';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import {PNSUserService} from '@portal-ng/PNSServices/PNSUser.service';
import * as fromUser from '@portal-ng/PNSUser/reducers';
import {take} from 'rxjs/operators';
import {LoadPNSUsers} from '../../PNSUser/actions/PNSUser.action';

@Injectable()
export class PNSUserResolver implements Resolve<void> {

  constructor(private store: Store<IAppState>) {}

  entityName = 'Users';
  selectMethod: MemoizedSelector<any, any> = fromUser.getUsersLoaded;
  selectAction: Action = new LoadPNSUsers(PNSUserService.getDefaultSearch());

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.pipe(select(this.selectMethod), take(1)).subscribe((hasLoaded) => {
      if (!hasLoaded) {
        this.store.dispatch(this.selectAction);
      } else {
        console.info(this.entityName + ' loading skipped, ' + this.entityName + ' are already loaded.');
      }
    });
  }

}
