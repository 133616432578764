import {Location} from '@angular/common';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {EditPNSLocation} from '@portal-ng/PNSLocation/actions/PNSLocation.action';
import {PNSBarcodeConfigFormComponent} from '@portal-ng/PNSLocation/components/pnsbarcode-config-form/pnsbarcode-config-form.component';
import {PNSLocationEffects} from '@portal-ng/PNSLocation/effects/PNSLocation.effects';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSCode, IPNSLocation, IPNSLocationConfigurationBarcodeFormat, IPNSWebForm, IPNSWebFormFieldStatus} from '@portal-ng/PNSModels';
import {PNSWebFormService} from '@portal-ng/PNSServices/PNSWebForm.service';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {Subscription} from 'rxjs';
import * as fromConfModels from '../../configurationModels';
import {PNSCodeService} from '@portal-ng/PNSServices/PNSCode.service';

@Component({
  selector   : 'portal-ng-pnslocation-configure-form',
  templateUrl: './configuration-form.component.html',
  styleUrls  : ['./configuration-form.component.scss'],
})

export class ConfigurationFormComponent implements OnInit, OnDestroy {

  configFieldStatus = IPNSWebFormFieldStatus;

  showDeprecatedFields = false;

  codes: Map<string, IPNSCode[]> = new Map();

  formType: string;
  formData: IPNSWebForm;
  route$: Subscription;
  location$: Subscription;
  locID: string;
  loc: IPNSLocation;
  configID: string;
  configForm: FormGroup;
  formReady = false;
  updatedBarcodeFormats: IPNSLocationConfigurationBarcodeFormat[];

  constructor(
    private locEffects: PNSLocationEffects,
    private locService: PNSLocationService,
    private codeService: PNSCodeService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private store: Store<IAuthState>,
    private cdf: ChangeDetectorRef,
    private _fb: FormBuilder,
    private dialog: MatDialog,
  ) {}


  ngOnDestroy() {
    this.route$.unsubscribe();
  }

  ngOnInit() {

    this.route$ = this.route.params.subscribe((params) => {
      this.locID    = params.locationID as string;
      this.configID = params.configurationID as string;
    });

    this.formData = fromConfModels.PNSLocationConfiguration;
    this.loadCodes(this.getRequiredCodeCategories(this.formData));

    if (this.locID !== undefined && this.locID !== null) {
      this.location$ = this.store.pipe(select(fromLocation.getLocationByID(this.locID))).subscribe((loc) => {
        if (loc !== undefined && loc !== null) {
          this.configForm = PNSWebFormService.createForm(loc, fromConfModels.PNSLocationConfiguration);
          this.loc        = loc;
          this.formReady  = true;
          this.cdf.detectChanges();
        }
      });
    }
  }

  setShowDeprecated(checked: boolean) {
    this.showDeprecatedFields = checked;
  }

  getRequiredCodeCategories(form: IPNSWebForm): string[] {

    const codeCategories: string[] = Array();

    for (const formCategory of form.configurationGroups) {
      for (const formItem of formCategory.configurationItems) {
        if (formItem.codeCategory !== undefined) {
          codeCategories.push(formItem.codeCategory);
        }
      }
    }
    return codeCategories;
  }

  getCodes(category: string) {
    return this.codes.get(category);
  }

  loadCodes(categories: string[]) {

    for (const categoryString of categories) {

      this.codeService.loadCodes(PNSCodeService.searchByCategory(categoryString)).subscribe((result) => {
        this.codes.set(categoryString, result.results);
        this.cdf.detectChanges();

      });
    }
  }

  openBarcodeConfig() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus    = true;
    dialogConfig.height       = '80%';
    dialogConfig.width        = '50%';
    dialogConfig.data         = {data: this.loc};

    this.dialog.open(PNSBarcodeConfigFormComponent, dialogConfig).afterClosed().subscribe(() => {
      this.locService.reloadLocation(this.locID);
    });

  }

  compareCodes(a: IPNSCode, b: IPNSCode) {
    return (a.id === b.id);
  }

  addArrayItem(form: FormGroup, formGroupName: string, formItemName: string): void {
    const formArray = form.get(formGroupName + '.' + formItemName) as FormArray;
    formArray.push(this._fb.control(' '));
  }

  removeArrayItem(form: FormGroup, formGroupName: string, formItemName: string, index: number): void {
    const formArray = form.get(formGroupName + '.' + formItemName) as FormArray;
    formArray.removeAt(index);
  }

  saveForm(form: FormGroup) {
    const editedLocation: IPNSLocation = PNSWebFormService.updateObject(this.loc, this.formData, form);
    this.store.dispatch(new EditPNSLocation(editedLocation));
    this.router.navigate(['/admin/locations/facility/list']);
  }

}
