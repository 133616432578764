import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {PNSOrganizationAddFormComponent} from '@portal-ng/PNSOrganization/components/PNSOrganization-add-form/pnsorganization-add-form.component';
import {PNSOrganizationListComponent} from '@portal-ng/PNSOrganization/components/PNSOrganization-list/PNSOrganization-list.component';
import {PNSOrganizationEffects} from '@portal-ng/PNSOrganization/effects/PNSOrganization.effects';
import {PNSOrganizationRoutingModule} from '@portal-ng/PNSOrganization/PNSOrganization-routing.module';
import {reducers} from '@portal-ng/PNSOrganization/reducers';
import {SharedModule} from '@portal-ng/PNSShared/shared.module';

export const COMPONENTS = [
  PNSOrganizationListComponent,
  PNSOrganizationAddFormComponent,
];

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    StoreModule.forFeature('organization', reducers),
    EffectsModule.forFeature([PNSOrganizationEffects]),
    PNSOrganizationRoutingModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class PNSOrganizationModule {
}
