import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {SharedModule} from '@portal-ng/PNSShared/shared.module';
import {PNSUserAuditComponent} from '@portal-ng/PNSUser/components/pnsuser-audit/pnsuser-audit.component';
import {PNSUserEffects} from '@portal-ng/PNSUser/effects/PNSUser.effects';
import {PNSUserRoutingModule} from '@portal-ng/PNSUser/PNSUser-routing.module';
import {reducers} from '@portal-ng/PNSUser/reducers';
import {PNSUserFormComponent} from './components/pnsuser-form/pnsuser-form.component';
import {PNSUserListComponent} from './components/pnsuser-list/pnsuser-list.component';

export const COMPONENTS = [
  PNSUserListComponent,
  PNSUserAuditComponent,
  PNSUserFormComponent,
];

@NgModule({
  imports: [
    SharedModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    StoreModule.forFeature('users', reducers),
    EffectsModule.forFeature([PNSUserEffects]),
    PNSUserRoutingModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class PNSUserModule {
}
