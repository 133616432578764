import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PNSAuthRoutingModule} from '@portal-ng/PNSAuth/PNSAuth-routing.module';
import {MaterialModule} from '@portal-ng/PNSMaterial';
import {SharedModule} from '@portal-ng/PNSShared/shared.module';
import {SamlLoginComponent} from '@portal-ng/PNSAuth/components/saml-login/saml-login.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PNSAuthEffects} from '@portal-ng/PNSAuth/effects/PNSAuth.effects';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {FlexLayoutModule} from '@angular/flex-layout';

export const COMPONENTS = [
  SamlLoginComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    StoreModule.forFeature('auth', fromAuth.reducer),
    EffectsModule.forFeature([PNSAuthEffects]),
    FormsModule,
    ReactiveFormsModule,
    PNSAuthRoutingModule,
    FlexLayoutModule,
  ],
  declarations: COMPONENTS,
  exports     : COMPONENTS,
})

export class PNSAuthModule {}
