import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'EnumToArray',
})
export class EnumToArrayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== null) {
      const keys = Object.keys(value);
      return keys.slice(keys.length / 2);
    }
  }
}
