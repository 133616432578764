import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {PNSAuthActions} from '@portal-ng/PNSAuth/actions';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import * as fromRoot from '@portal-ng/PNSCore/reducers';
import {IPNSNavItem, IPNSUser} from '@portal-ng/PNSModels';
import {MicrosoftService} from '@portal-ng/PNSServices/Microsoft.service';
import {PNSNavService} from '@portal-ng/PNSServices/PNSNav.service';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {PNSStatusType} from '@portal-ng/PNSModels/PNSUX.model';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';

@Component({
    selector   : 'portal-ng-admin-layout',
    templateUrl: 'admin.layout.component.html',
    styleUrls  : ['admin.layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AdminLayoutComponent implements OnInit, AfterViewInit {


    get matIconRegistry(): MatIconRegistry {
        return this._matIconRegistry;
    }

    set matIconRegistry(value: MatIconRegistry) {
        this._matIconRegistry = value;
    }

    uxStatus$: Observable<PNSStatusType>;
    auth$: Subscription;

    sideNavVisible: boolean;
    currentYear: string = new Date().getFullYear().toString(10);
    authenticatedUser: Partial<IPNSUser>;
    isAuthenticated     = false;
    idToken: string;
    env: any;
    page: string;

    randIcons: string[] = [
        'thumb-up',
        'flash-circle',
        'paw',
        'star',
        'rowing',
        'key',
        'briefcase',
        'camera',
        'face',
    ];

    iconPath = 'assets/custom-icons/';

    svgIcons: string[] = [
        'lock-reset',
        'walgreens',
        'throughput',
        'monday-icon',
        'active-directory',
        'charity',
        'hospital-building',
        'textbox-password',
    ];

    randIcon: string = this.randIcons[Math.floor(Math.random() * this.randIcons.length)];

    @ViewChild('appDrawer', {static: true}) appDrawer: ElementRef;

    navItems: IPNSNavItem[] = [
        {displayName: 'Home', iconName: 'home', route: '/home', requiredPrivilegeName: 'Access Portal'},
        {displayName: 'Users', iconName: 'account-circle', route: '/admin/users/list', requiredPrivilegeName: 'Manage User'},
        {displayName: 'Custodial Organizations', iconName: 'account-group', route: '/admin/tasks/custodialOrganizations', requiredPrivilegeName: 'Manage Location'},
        {displayName: 'Organizations', iconName: 'domain', route: '/admin/organizations/list', requiredPrivilegeName: 'Manage Location'},
        {displayName: 'Facilities', iconName: 'hospital-building', route: '/admin/locations/facility/list', requiredPrivilegeName: 'Manage Location'},
        {displayName: 'Forms', iconName: 'clipboard-list-outline', route: '/admin/forms/', requiredPrivilegeName: 'Manage Form'},
        {displayName: 'Codes', iconName: 'view-list', route: '/admin/tasks/code-editor', requiredPrivilegeName: 'Manage Code'},

        {displayName: 'Providers', disabled: false, iconName: 'account-group', route: '/admin/tasks/providers', requiredPrivilegeName: 'Manage Provider'},
        {displayName: 'Needs', disabled: true, iconName: 'puzzle', route: '', requiredPrivilegeName: 'Manage Need'},
        {displayName: 'Workflows', disabled: true, iconName: 'sitemap', route: '', requiredPrivilegeName: 'Manage Workflow'},
        {displayName: 'StatusIcons', disabled: true, iconName: 'view-module', route: '', requiredPrivilegeName: 'Manage Status Icon'},
        {displayName: 'Release Notes', iconName: 'file-document-edit', route: '/admin/tasks/versions', requiredPrivilegeName: 'Operational Support'},

        {displayName: 'Attribute Editor', iconName: 'table-edit', route: '/admin/tasks/attributeEditor', requiredPrivilegeName: 'Bulk Editing'},
        {displayName: 'Support Tools', iconName: 'tools', route: '/admin/tasks/support', requiredPrivilegeName: 'Operational Support'},
        {displayName: 'Backend Tasks', iconName: 'engine', route: '/admin/tasks/index', requiredPrivilegeName: 'Manage Backend Task'},
    ];

    constructor(
        private store: Store<fromRoot.IAppState>,
        private navService: PNSNavService,
        private msService: MicrosoftService,
        private _matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
    ) {}

    ngOnInit() {

        this.env = environment;
        this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/custom-icons/mdi.svg'));
        this.auth$ = this.store.pipe(select(fromAuth.getAuthModuleState))
            .subscribe((auth: IAuthState) => {
                if (auth && auth.authenticatedUser) {
                    this.authenticatedUser = auth.authenticatedUser;
                    this.isAuthenticated   = true;
                    this.idToken = auth.authentication.id_token;
                }
            });

        this.uxStatus$ = this.store.pipe(select(fromRoot.getPNSUXStatus));

        this.sideNavVisible = true;
        this.svgIcons.forEach((svgIcon) => {
            this._matIconRegistry.addSvgIcon(svgIcon,
                this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconPath + svgIcon + '.svg'));
        });
    }

    logout() {
        this.store.dispatch(new PNSAuthActions.Logout({url: this.router.url}));
    }

    sideNavToggle() {
        this.navService.toggleNav();
    }

    ngAfterViewInit() {
        this.navService.appDrawer = this.appDrawer;
    }

    getStatusIcon(status: PNSStatusType) {
        let icon: string;
        switch (status) {
            case PNSStatusType.connected:
                icon = 'check-decagram';
                break;
            case PNSStatusType.disconnected:
                icon = 'lan-disconnect';
                break;
            case PNSStatusType.refreshing:
                icon = 'refresh';
                break;
            default:
                icon = 'error';
                break;
        }
        return icon;
    }

    public getClass(icon: PNSStatusType) {
        let iconClass: string;
        switch (icon) {
            case PNSStatusType.connected:
                iconClass = '';
                break;
            case PNSStatusType.disconnected:
                iconClass = 'disconnected';
                break;
            case PNSStatusType.refreshing:
                iconClass = 'spinner';
                break;
            default:
                iconClass = 'error';
                break;
        }
        return iconClass;
    }
}
