<div id="page">

  <h1 class="mat-display-1"><button mat-mini-fab color="accent" routerLink="/admin/locations/facility/list/">
    <mat-icon svgIcon="arrow_back"></mat-icon></button>Units <button mat-icon-button><mat-icon aria-label="Reload Unit List" (click)="refresh()" >cached</mat-icon></button></h1>

<div *ngIf="dataSource.data != null">
  <div id="filter">
    <mat-form-field class="filter">
      <label>Filter
      <input matInput (keyup)="applyFilter($event.target)" placeholder="Filter"></label>
    </mat-form-field>
    <mat-checkbox selected="true" [(ngModel)]="operationalFilter" (change)="refresh()">Operational Only</mat-checkbox>
    <button mat-icon-button routerLink="/admin/locations/facility/add"><mat-icon svgIcon="plus"></mat-icon></button>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location ID</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button routerLink="/admin/locations/facility/{{locationID}}/units/{{row.id}}/status" color="primary"><mat-icon svgIcon="image_search"></mat-icon></button>
          <button mat-icon-button routerLink="/admin/locations/facility/{{locationID}}/units/{{row.id}}/statusboard" color="primary"><mat-icon svgIcon="traffic"></mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="operational">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Operational</th>
        <td mat-cell *matCellDef="let row"><mat-checkbox color="primary"  (click)='toggleOperational(row.id)' [checked]="row.operational"></mat-checkbox></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Name</th>
        <td mat-cell *matCellDef="let row">{{row.name | nullPipe }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Name</th>
        <td mat-cell *matCellDef="let row">{{row.visit.status | nullPipe }}</td>
      </ng-container>

      <ng-container matColumnDef="abbreviation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Abbreviation</th>
        <td mat-cell *matCellDef="let row">{{row.abbreviation | nullPipe}}</td>
      </ng-container>

      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>External ID</th>
        <td mat-cell *matCellDef="let row">{{row.organization | nullPipe}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <!--suppress JSUnusedLocalSymbols -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="100"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </div>
</div>
</div>
