import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSSearch} from '@portal-ng/PNSModels';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {SAMLTokenExpired} from '@portal-ng/PNSAuth/actions/PNSAuth.actions';
import {ActivatedRoute} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})

export class PNSService {

  constructor(
    private store: Store<IAuthState>,
    private auth: PNSAuthService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) {}

  endpoint: string = environment.PNS.endpoint;

  static setCommonSearchParams(params: HttpParams, terms: IPNSSearch): HttpParams {
    if (Number.isInteger(Number(terms.offset))) {
      params = params.set('offset', terms.offset.toString(10));
    }

    if (Number(terms.limit) > 0) {
      params = params.set('limit', terms.limit.toString(10));
    }
    return params;
  }

  static defaultSearch(): IPNSSearch {
    return {
      limit : environment.PNS.slowQuerySearchLimit,
      offset: 0,
    };
  }

  getEndpoint(): string {
    return this.endpoint;
  }

  authenticate(): boolean {
    const helper = new JwtHelperService();
    const isExpired = this.auth.isExpired();
    const isValid   = !isExpired;

    if (isExpired) {
      console.log(helper.decodeToken(this.auth.getIDToken()));
      console.log('Expired On: ' + helper.getTokenExpirationDate());
      this.store.dispatch(new SAMLTokenExpired({redirectURL: window.location.href}));
    }

    return (isValid);
  }

  authenticateGet(action: string, params?: HttpParams): Observable<any> {
    const url        = environment.PNS.endpoint + action;
    const parameters = (params === undefined) ? new HttpParams() : params;
    const headers    = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', '*/*').set('Authorization', this.auth.getIDToken());
    if (this.authenticate()) {
      return this.http.get<any>(url, {headers, params: parameters});
    }
  }

  authenticateGetBlob(action: string, params?: HttpParams): Observable<Blob> {
      const url        = environment.PNS.endpoint + action;
      const parameters = (params === undefined) ? new HttpParams() : params;
      const headers    = new HttpHeaders().set('Content-Type', 'application/pdf').set('Accept', '*/*').set('Authorization', this.auth.getIDToken());
      if (this.authenticate()) {
          return this.http.get(url, {headers, observe: 'body', params: parameters, responseType: 'blob'});
      }
  }


  authenticateDelete(action: string): Observable<any> {
    if (this.authenticate()) {
      const url                    = environment.PNS.endpoint + action;
      const headers                = new HttpHeaders().set('Authorization', this.auth.getIDToken());
      const parameters: HttpParams = new HttpParams();
      return this.http.delete(url, {headers, params: parameters});
    }
  }

  authenticatePut(action: string, payload?: any, params?: HttpParams, reqHeaders?: HttpHeaders): Observable<any> {
    let url = '';
    let headers: HttpHeaders;
    if (this.authenticate()) {

      if (reqHeaders === undefined || reqHeaders === null) {
        headers = new HttpHeaders();
      } else {
        headers = reqHeaders;
      }

      if (params === undefined || params === null) {
        url        = environment.PNS.endpoint + action;
      } else {
        const parameters = (params === undefined || params === null) ? new HttpParams() : params;
        url        = environment.PNS.endpoint + action + '?' + parameters.toString();
      }
      headers = headers.append('Authorization', this.auth.getIDToken());
      if (payload !== undefined) {
        return this.http.put<any>(url, payload, {headers});
      } else {
        return this.http.put<any>(url, null, {headers});
      }
    }
  }

  authenticatePost(action: string, payload: any, parameters?: HttpParams): Observable<any> {
    if (this.authenticate()) {
      const params  = (parameters === undefined) ? new HttpParams() : parameters;
      const url     = environment.PNS.endpoint + action + '?' + params.toString();
      const headers = new HttpHeaders().set('Authorization', this.auth.getIDToken());
      return this.http.post<any>(url, payload, {headers});
    }
  }

  public whoami(): Observable<any> {
    return this.authenticateGet('users/whoami');
  }
}
