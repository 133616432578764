import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IPNSNavItem, IPNSUser} from '@portal-ng/PNSModels';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';

@Component({
  selector   : 'portal-ng-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls  : ['./menu-list-item.component.scss'],
  animations : [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)'),
      ),
    ]),
  ],
})
export class PNSMenuListItemComponent implements OnInit {
  expanded = false;
  authenticatedUser: Partial<IPNSUser>;
  isAuthenticated: boolean;

  auth$: Subscription;

  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: IPNSNavItem;
  @Input() depth: number;

  constructor(private store: Store, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  public ngOnInit() {
    this.auth$ = this.store.pipe(select(fromAuth.getAuthModuleState))
      .subscribe((auth: IAuthState) => {
        this.authenticatedUser = auth.authenticatedUser;
        if (auth.authenticatedUser !== null || true) {
        }
        this.isAuthenticated = auth.authenticated;
      });  }

  onItemSelected(item: IPNSNavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
