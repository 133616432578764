import {Component, OnInit} from '@angular/core';

import {INewRelicSummary} from '@portal-ng/PNSModels';
import {NewRelicService} from '@portal-ng/PNSServices/NewRelic.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'portalNG-status-bank',
  templateUrl: './status-bank.component.html',
  styleUrls: ['./status-bank.component.scss'],
})
export class StatusBankComponent implements OnInit {

  devAPI$: Observable<INewRelicSummary>;
  prodAPI$: Observable<INewRelicSummary>;
  devGateway$:  Observable<INewRelicSummary>;
  prodGateway$:  Observable<INewRelicSummary>;

  constructor(private newRelic: NewRelicService) {}

  ngOnInit() {

    this.devAPI$      = this.newRelic.getApplicationStatus(180261816);
    this.prodAPI$     = this.newRelic.getApplicationStatus(31999293);
    this.devGateway$  = this.newRelic.getApplicationStatus(49667055);
    this.prodGateway$ = this.newRelic.getApplicationStatus(50012890);
  }

}
