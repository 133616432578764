import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as ErrorActions from '@portal-ng/PNSCore/actions/error.actions';
import {IPNSFormTemplate, IPNSFormTemplateSearch} from '@portal-ng/PNSModels';
import {IState} from '@portal-ng/PNSUser/reducers';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {PNSFormTemplateActions} from '../actions';
import {PNSFormTemplateService} from '@portal-ng/PNSServices/PNSFormTemplate.service';

@Injectable()
export class PNSFormTemplateEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<IState>,
    private matDialog: MatDialog,
    private formTemplateService: PNSFormTemplateService,
  ) {}

  loadFormTemplates$ = createEffect(() =>
    this.actions$.pipe(ofType<PNSFormTemplateActions.LoadPNSFormTemplates>(PNSFormTemplateActions.PNSFormTemplateActionTypes.LoadPNSFormTemplates),
      map((action) => action.payload),
      exhaustMap((search: IPNSFormTemplateSearch) =>
        this.formTemplateService.loadPNSFormTemplates(search).pipe(
          map((response: IPNSFormTemplate[]) => new PNSFormTemplateActions.LoadPNSFormTemplatesSuccess({ response })),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        ),
      ),
    ),
  );

  editFormTemplate$ = createEffect(() =>
    this.actions$.pipe(ofType<PNSFormTemplateActions.EditPNSFormTemplate>(PNSFormTemplateActions.PNSFormTemplateActionTypes.EditPNSFormTemplate),
      map((action) => action.payload),
      exhaustMap((formTemplate: Partial<IPNSFormTemplate>) =>
        this.formTemplateService.editPNSFormTemplate(formTemplate).pipe(
          map((response) => new PNSFormTemplateActions.EditPNSFormTemplateSuccess({formTemplate: response})),
          tap(() => this.router.navigate(['/admin/FormTemplates/list'])),
          catchError((error) => of(new ErrorActions.APIError( {error} ))),
        )
      )
    )
  );
}
