
<div id="page">
  <div *ngIf="location$ !== undefined">
<h2 class="mat-h1">Provider Affiliation for {{location$.name}}</h2>
  </div>
<div *ngIf="dataSource.data != null">
  <div id="filter">
    <mat-form-field class="filter">
      <input matInput (keyup)="applyFilter($event.target)" placeholder="Filter"></mat-form-field>
    <!-- button -->
  </div>
  <div class="mat-elevation-z8">
    <table mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider ID</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>
      <ng-container matColumnDef="npi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NPI</th>
        <td mat-cell *matCellDef="let row">{{row.npi}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let row">{{row.name | nullPipe }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Physician Location</th>
        <td mat-cell *matCellDef="let row">{{row.locations[0].locationName | nullPipe}}</td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Affiliated?</th>
        <td mat-cell *matCellDef="let row"><mat-checkbox color="primary"  (click)='toggleAffiliated(row.id)' [checked]="isAffiliated(row)"></mat-checkbox></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <!--suppress JSUnusedLocalSymbols -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="100"
                   [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </div>
</div>
</div>
