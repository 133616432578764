
export enum PNSColumnType {
 'string',
 'dateTime',
 'visitStatus',
 'location',
 'concat',
 'visit',
 'number',
 'action',
  'user',
}

export enum PNSFilterType {
  'text',
  'dropDown',
  'checkbox',
}

export interface IPNSColumn {
  name: string;

}

export interface IPNSActionColumn extends IPNSColumn {
  options: IPNSActionColumnButton[];
}

export interface IPNSActionColumnButton {
  name: string;
  tooltip?: string;
  icon?: string;
  svgIcon?: string;
  routerLink?: string;
  click?(): any;
}

export interface IPNSTableFilter {
  name: string;
  type: string;
  options?: { name: string, value: string }[];
}

export interface IPNSTableButton {
  name: string;
  icon: string;
  routerLink: string;
}

export interface IPNSTableColumnConfiguration {
  name: string;
  title: string;
  type: PNSColumnType;
  names?: string[];
  delimiter?: string;
}

export interface IPNSTableConfiguration {
  columns: IPNSTableColumnConfiguration[];
  filters: IPNSTableFilter[];
  button: IPNSTableButton;
}
