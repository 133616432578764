import {Action} from '@ngrx/store';
import {IPNSApplication, IPNSApplicationSearch} from '@portal-ng/PNSModels';

export enum PNSApplicationActionTypes {
  LoadPNSApplications        = '[PNSApplications] Fetch',
  LoadPNSApplicationsSuccess = '[PNSApplications] Fetch Success',
}

export class LoadPNSApplications implements Action {
  readonly type = PNSApplicationActionTypes.LoadPNSApplications;
  constructor(public payload: IPNSApplicationSearch) {}
}

export class LoadPNSApplicationsSuccess implements Action {
  readonly type = PNSApplicationActionTypes.LoadPNSApplicationsSuccess;

  constructor(public payload: IPNSApplication[]) {}
}

export type PNSApplicationActionsUnion =
  LoadPNSApplications
  | LoadPNSApplicationsSuccess;
