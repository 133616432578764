import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfigurationFormComponent} from '@portal-ng/PNSLocation/components/configuration-form/configuration-form.component';
import {PNSLocationAddFormComponent} from '@portal-ng/PNSLocation/components/pnslocation-add-form/pnslocation-add-form.component';
import {PNSLocationDataQualityFormComponent} from '@portal-ng/PNSLocation/components/pnslocation-data-quality-form/pnslocation-data-quality-form.component';
import {PNSLocationFacilityListComponent} from '@portal-ng/PNSLocation/components/pnslocation-facility-list/pnslocation-facility-list.component';
import {PNSLocationProviderAffiliateComponent} from '@portal-ng/PNSLocation/components/pnslocation-provider-affiliate/pnslocation-provider-affiliate.component';
import {PNSLocationUnitStatusBoardComponent} from '@portal-ng/PNSLocation/components/pnslocation-unit-status-board/pnslocation-unit-status-board.component';
import {PNSLocationUnitStatusComponent} from '@portal-ng/PNSLocation/components/pnslocation-unit-status/pnslocation-unit-status.component';
import {PNSLocationUnitComponent} from '@portal-ng/PNSLocation/components/pnslocation-unit/pnslocation-unit.component';
import {PNSAuthenticationGuard} from '@portal-ng/PNSShared/guards/PNSAuthentication.guard';
import {PNSLocationResolver} from '@portal-ng/PNSShared/resolvers/PNSLocation.resolver';
import {PNSLocationUnitResolver} from '@portal-ng/PNSShared/resolvers/PNSLocationUnit.resolver';
import {PNSOrganizationResolver} from '@portal-ng/PNSShared/resolvers/PNSOrganizaton.resolver';
import {PNSUserResolver} from '@portal-ng/PNSShared/resolvers/PNSUser.resolver';
import {PNSFormTemplateResolver} from '@portal-ng/PNSShared/resolvers/PNSFormTemplate.resolver';

export const locationRoutes: Routes = [
  { path: 'locations',
    redirectTo: '/facility/list',
    pathMatch: 'full',
    resolve: [PNSLocationResolver, PNSLocationUnitResolver, PNSUserResolver] },
  {
    path: ':id/affiliateProvider',
    component: PNSLocationProviderAffiliateComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver],
  },
  {
    path: 'facility/list',
    component: PNSLocationFacilityListComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver, PNSUserResolver, PNSFormTemplateResolver],
  },
  {
    path: 'facility/add',
    component: PNSLocationAddFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSOrganizationResolver, PNSLocationResolver],
  },
  {
    path: 'facility/add/:orgId',
    component: PNSLocationAddFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSOrganizationResolver, PNSLocationResolver],
  },
  {
    path: 'facility/:locationID/configure/:configurationID',
    component: ConfigurationFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver],
  },
  {
    path: 'facility/:locationID/units',
    component: PNSLocationUnitComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver, PNSLocationUnitResolver],
  },
  {
    path: 'facility/:locationID/units/:unitID/status',
    component: PNSLocationUnitStatusComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver, PNSLocationUnitResolver],
  },
  {
    path: 'facility/:locationID/units/:unitID/statusboard',
    component: PNSLocationUnitStatusBoardComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver, PNSLocationUnitResolver],
  },
  {
    path: 'facility/:locationID/dataQualityIssue',
    component: PNSLocationDataQualityFormComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    resolve: [PNSLocationResolver],
  },
];

@NgModule({
  imports: [RouterModule.forChild(locationRoutes)],
  exports: [RouterModule],
})

export class PNSLocationRoutingModule {}
