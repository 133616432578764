<div class="all-wrap">
    <div class="page-wrap">
        <header>
            <mat-toolbar color="primary">
                <mat-toolbar-row>
                    <div *ngIf="this.isAuthenticated">
                        <button aria-label="Toggle sidenav" mat-icon-button (click)="sideNavToggle()">
                            <mat-icon aria-label="Side nav toggle icon" color="text" svgIcon="menu"></mat-icon>
                        </button>
                    </div>
                    <h1><span id="appTitle"><mat-icon [svgIcon]="randIcon"></mat-icon>
                        {{ env.name }}</span></h1>
                    <span class="toolbar-spacer">&nbsp;</span>
                    <div *ngIf="this.isAuthenticated; else loggedOut">
                        <mat-icon id="statusIcon" *ngIf="this.uxStatus$ | async as icon"
                                  [svgIcon]="getStatusIcon(icon)"></mat-icon>
                        <button id="userbutton" mat-stroked-button [matMenuTriggerFor]="userMenu"><span><mat-icon
                                svgIcon="account"></mat-icon>
                            {{this.authenticatedUser.username}}</span></button>
                    </div>
                    <ng-template #loggedOut>
                        <button id="loginbutton" mat-stroked-button [matMenuTriggerFor]="userMenu"><span><mat-icon
                                svgIcon="lock"></mat-icon>Login</span></button>
                    </ng-template>

                </mat-toolbar-row>
            </mat-toolbar>
        </header>
        <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #appDrawer mode="side" class="sidenav" [opened]="sideNavVisible" role="navigation">
                <mat-nav-list>
                    <div *ngIf="this.isAuthenticated">
                        <portal-ng-menu-list-item class="sidenav-container" *ngFor="let item of navItems"
                                                  [item]="item"></portal-ng-menu-list-item>
                    </div>
                </mat-nav-list>
            </mat-sidenav>

            <mat-sidenav-content>
                <main class="content">
                    <router-outlet></router-outlet>
                </main>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
    <footer>
        <p class="mat-body-1">©{{currentYear}} Patient Engagement Advisors</p>
    </footer>
</div>

<mat-menu #userMenu>
    <div *ngIf="this.isAuthenticated">
        <button (click)="logout()" mat-menu-item>
            <mat-icon svgIcon="account"></mat-icon>
            <span>Logout</span>
        </button>

        <button *ngIf="this.authenticatedUser.roleNames.includes('Admin')" [cdkCopyToClipboard]="this.idToken" mat-menu-item>
            <mat-icon svgIcon="identifier"></mat-icon>
            <span>Copy ID Token</span>
        </button>

    </div>
    <div *ngIf="!(this.isAuthenticated)">
        <button mat-menu-item routerLink="/login">
            <mat-icon svgIcon="account"></mat-icon>
            <span>Login</span>
        </button>


        <button mat-menu-item routerLink="/info">
            <mat-icon svgIcon="information"></mat-icon>
            <span>Portal Information</span>
        </button>
    </div>
</mat-menu>
