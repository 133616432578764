import {Action} from '@ngrx/store';
import {IPNSLocationSearch} from '@portal-ng/PNSModels/PNSLocationSearch.model';
import {IPNSLocationSearchResults, IPNSRoleSearchResults, IPNSUserSearchResult} from '@portal-ng/PNSModels/PNSSearchResults.model';
import {IPNSUser} from '@portal-ng/PNSModels/PNSUser.model';
import {IPNSUserSearch} from '@portal-ng/PNSModels/PNSUserSearch.model';

export enum PNSUserActionTypes {
    AddPNSUser              = '[PNSUser] Add User',
    AddPNSUserSuccess       = '[PNSUser] Add User Success',

    RemovePNSUserConfirm    = '[PNSUser] Confirm Removal',
    RemovePNSUserCancel     = '[PNSUser] Remove Cancel',
    RemovePNSUser           = '[PNSUser] Remove',
    RemovePNSUserSuccess    = '[PNSUser] Remove Success',

    EditPNSUser             = '[PNSUser] Edit User',
    EditPNSUserSuccess      = '[PNSUser] Edit User Success',

    LoadPNSUsers            = '[PNSUsers] Fetch',
    LoadPNSUsersSuccess     = '[PNSUsers] Fetch Success',

    LoadPNSRoles            = '[PNSRole] Fetch',
    LoadPNSRolesSuccess     = '[PNSRole] Fetch Success',

    LoadPNSUserLocations         = '[PNSLocation] User Location Fetch',
    LoadPNSUserLocationsSuccess  = '[PNSLocation] User Location Fetch Success',

    ResetPNSUserPassword         = '[PNSUser] Password Reset',
    ResetPNSUserPasswordConfirm  = '[PNSUser] Confirm Password Reset',
    ResetPNSUserPasswordCancel   = '[PNSUser] Cancel Password Reset',
    ResetPNSUserPasswordSuccess  = '[PNSUser] Password Reset Success',
}

// Location Loading

export class LoadPNSUserLocations implements Action {
  readonly type = PNSUserActionTypes.LoadPNSUserLocations;
  constructor(public payload: IPNSLocationSearch) {}

}

export class LoadPNSUserLocationsSuccess implements Action {
  readonly type = PNSUserActionTypes.LoadPNSUserLocationsSuccess;
  constructor(public payload: {locations: IPNSLocationSearchResults}) {}
}

// Role Loading

export class LoadPNSRoles implements Action {
  readonly type = PNSUserActionTypes.LoadPNSRoles;
}

export class LoadPNSRolesSuccess implements Action {
  readonly type = PNSUserActionTypes.LoadPNSRolesSuccess;
  constructor(public payload: {roles: IPNSRoleSearchResults}) {}
}

// Adding

export class AddPNSUser implements Action {
    readonly type = PNSUserActionTypes.AddPNSUser;
  constructor(public payload: Partial<IPNSUser>) {}
}

export class AddPNSUserSuccess implements Action {
  readonly type = PNSUserActionTypes.AddPNSUserSuccess;
  constructor(public payload: {user: IPNSUser}) {}
}

// Editing
export class EditPNSUser implements Action {
  readonly type = PNSUserActionTypes.EditPNSUser;
  constructor(public payload: Partial<IPNSUser>) {}
}

export class EditPNSUserSuccess implements Action {
  readonly type = PNSUserActionTypes.EditPNSUserSuccess;
  constructor(public payload: {user: IPNSUser} ) {}
}

// Loading

export class LoadPNSUsers implements Action {
  readonly type = PNSUserActionTypes.LoadPNSUsers;
  constructor(public payload: IPNSUserSearch) {}
}

export class LoadPNSUsersSuccess implements Action {
  readonly type = PNSUserActionTypes.LoadPNSUsersSuccess;
  constructor(public payload: {response: IPNSUserSearchResult} ) {}
}

//   REMOVING   //

export class RemovePNSUserConfirm implements Action {
  readonly type = PNSUserActionTypes.RemovePNSUserConfirm;
  constructor(public payload: { id: string, username: string}) {}
}

export class RemovePNSUser implements Action {
  readonly type = PNSUserActionTypes.RemovePNSUser;
  constructor(public payload: { id: string, username: string}) {}

}

export class RemovePNSUserSuccess implements Action {
  readonly type = PNSUserActionTypes.RemovePNSUserSuccess;
  constructor(public payload: {id: string, username: string}) {}
}

export class RemovePNSUserCancel implements Action {
  readonly type = PNSUserActionTypes.RemovePNSUserCancel;
}

// Resetting Password

export class ResetPNSUserPassword implements Action {
  readonly type = PNSUserActionTypes.ResetPNSUserPassword;
  constructor(public payload: {username: string}) {}
}

export class ResetPNSUserPasswordConfirm implements Action {
  readonly type = PNSUserActionTypes.ResetPNSUserPasswordConfirm;
  constructor(public payload: {username: string}) {}
}

export class ResetPNSUserPasswordCancel implements Action {
  readonly type = PNSUserActionTypes.ResetPNSUserPasswordCancel;
}

export class ResetPNSUserPasswordSuccess implements Action {
  readonly type = PNSUserActionTypes.ResetPNSUserPasswordSuccess;
  constructor(public payload: {username: string}) {}
}

export type PNSUserActionsUnion  =
  LoadPNSUserLocations | LoadPNSUserLocationsSuccess |
  LoadPNSRoles         | LoadPNSRolesSuccess         |
  AddPNSUser           | AddPNSUserSuccess           |
  RemovePNSUser        | RemovePNSUserSuccess        | RemovePNSUserConfirm        | RemovePNSUserCancel |
  EditPNSUser          | EditPNSUserSuccess          |
  LoadPNSUsers         | LoadPNSUsersSuccess         |
  ResetPNSUserPassword | ResetPNSUserPasswordSuccess | ResetPNSUserPasswordConfirm | ResetPNSUserPasswordCancel ;
