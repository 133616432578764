import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class GoogleGeocodingService {

  private apiKey: string = environment.GoogleMaps.APIKey;
  private urlBase = 'https://maps.googleapis.com/maps/api/';
  private dataFormat = '/json';

  constructor(private http: HttpClient) {}

  public geoCodeAddress(address: string): Observable<any> {
    const params = new HttpParams().set('address', address).set('key', this.apiKey);

    return this.http.get(this.urlBase + 'geocode' + this.dataFormat, {params});
  }

  public getTimeZone(lat: string, lon: string): Observable<any> {
    const params = new HttpParams().set('location', lat + ',' + lon).set('timestamp', (Math.floor(Date.now() / 1000)).toString()).set('key', this.apiKey);
    return this.http.get(this.urlBase + 'timezone' + this.dataFormat, {params});

  }
}
