import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Action, MemoizedSelector, select, Store} from '@ngrx/store';
import {LoadPNSJobTypes} from '@portal-ng/PNSAdminEntities/actions/PNSJobTypes.action';
import * as fromAdminEntities from '@portal-ng/PNSAdminEntities/reducers';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import {take} from 'rxjs/operators';

@Injectable()
export class PNSJobTypesResolver implements Resolve<void> {

  constructor(private store: Store<IAppState>) {}

  entityName                               = 'JobTypes';
  selectMethod: MemoizedSelector<any, any> = fromAdminEntities.getJobTypesLoaded;
  selectAction: Action                     = new LoadPNSJobTypes();

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.pipe(select(this.selectMethod), take(1)).subscribe((hasLoaded) => {
      if (!hasLoaded) {
        this.store.dispatch(this.selectAction);
      } else {
        console.info(this.entityName + ' loading skipped, ' + this.entityName + ' are already loaded.');
      }
    });
  }

}
