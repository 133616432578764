import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromTasks from '@portal-ng/PNSAdminEntities/reducers';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSExecution, IPNSLocation, IPNSProvider, IPNSUser} from '@portal-ng/PNSModels';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {PNSProviderService} from '@portal-ng/PNSServices/PNSProvider.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'portal-ng-pnslocation-provider-affiliate',
  templateUrl: './pnslocation-provider-affiliate.component.html',
  styleUrls: ['./pnslocation-provider-affiliate.component.scss'],
})
export class PNSLocationProviderAffiliateComponent implements OnInit, OnDestroy {
  locationId: string;
  loc$: Subscription;
  location$: IPNSLocation;
  workingProvider$: any;
  test: IPNSProvider;
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filter: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'npi', 'actions', 'name', 'location'];

  providers$: Subscription;
  authenticatedUser$: Subscription;
  private authUser: Partial<IPNSUser>;

  constructor(  private store: Store<IAuthState>,
                private providerService: PNSProviderService,
                private route: ActivatedRoute) {
  }

  toggleAffiliated(providerId) {
    this.toggleProviderAffiliation(providerId, this.location$.organizationId);
  }

  toggleProviderAffiliation(providerId: string , organizationId: string) {
    this.providerService.getProvider(providerId, this.locationId).subscribe( (provider: IPNSProvider) => {
      this.workingProvider$ = provider;
      if (this.workingProvider$.affiliatedOrgIds === undefined) {
        this.workingProvider$.affiliatedOrgIds = [organizationId];
      } else if (this.workingProvider$.affiliatedOrgIds.includes(organizationId)) {
        const index = this.workingProvider$.affiliatedOrgIds.indexOf(organizationId);
        this.workingProvider$.affiliatedOrgIds.splice(index, 1);
      } else {
        this.workingProvider$.affiliatedOrgIds.push(organizationId);
      }
    });

    // this.providerService.editProvider(this.workingProvider$)
  }

  ngOnInit() {

    this.locationId = this.route.snapshot.params['id'];
    this.loc$ = this.store.pipe(select(fromLocation.getLocationByID(this.locationId))).subscribe((loc) => {
      this.location$ = loc;
    });

    this.providers$ = this.providerService.loadProvider(PNSService.defaultSearch(), this.locationId).subscribe((data) => {
      this.dataSource = new MatTableDataSource<IPNSProvider>(data.results);
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
      });
    });

    this.dataSource = new MatTableDataSource<IPNSProvider>();

    this.authenticatedUser$ = this.store.pipe(select(fromAuth.getAuthenticatedUser))
      .subscribe((authUser) => {
        this.authUser = authUser;
      });
  }

  isAffiliated(provider: IPNSProvider): boolean {
    return (provider.affiliatedOrgIds !== undefined && provider.affiliatedOrgIds.includes(this.location$.organizationId));
  }

  ngOnDestroy() {
    // this.user$.unsubscribe();
  }

  refresh() {
    this.store.pipe(select(fromTasks.getAllExecutions))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource<IPNSExecution>(data);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filter = this.filter;
        });

      });
  }

  applyFilter(filter: EventTarget) {
    const element = filter as HTMLInputElement;
    let filterValue: string;

    if (element.value !== undefined) {
      filterValue = element.value.trim().toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    } else {
      this.refresh();
    }
  }

}
