import {PNSAuthActions} from '@portal-ng/PNSAuth/actions/';
import {ErrorActions} from '@portal-ng/PNSCore/actions';
import {IPNSHttpError} from '@portal-ng/PNSModels/PNSError.model';
import {Action} from '@ngrx/store';

export interface IErrorState {
  error: IPNSHttpError | null;
  errorAction: Action | null;
}

export const initialState: IErrorState = {
  error: null,
  errorAction: null,
};

// Pass in Error actions and Auth actions so Errors can be reset on Logout

export function reducer(
  state = initialState,
  action: ErrorActions.ErrorActionsUnion | PNSAuthActions.PNSAuthActionsUnion ): IErrorState {
  switch (action.type) {
    case ErrorActions.ErrorActionTypes.APIError: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case ErrorActions.ErrorActionTypes.ResetErrors: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getError        = (state: IErrorState) => state.error;
export const getErrorMessage = (state: IErrorState) => state.error.error.errorDescription;
