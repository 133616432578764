import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSFormTemplate, IPNSFormTemplateSearch} from '@portal-ng/PNSModels';
import {Observable} from 'rxjs';
import {PNSService} from './PNS.service';

@Injectable({providedIn: 'root'})
export class PNSFormTemplateService {

    constructor(
        private PNS: PNSService,
    ) {}

    static parameterizeSearch(search: IPNSFormTemplateSearch): HttpParams {
        let params = new HttpParams()   ;

        for (const property in search) {
            if (search.hasOwnProperty(property)) {
                if (search[property] !== null) {
                    params = params.append(property, search[property]);
                }
            }
        }
        return params;
    }

    public static defaultSearch() {
        return {};
    }

    loadPNSFormTemplates(payload: IPNSFormTemplateSearch): Observable<IPNSFormTemplate[]> {
        return this.PNS.authenticateGet('forms/templates', PNSFormTemplateService.parameterizeSearch(payload));
    }

    editPNSFormTemplate(payload: Partial<IPNSFormTemplate>): Observable<IPNSFormTemplate> {
         return this.PNS.authenticatePut('forms/template/', payload);
    }

    addPNSFormTemplate(payload: IPNSFormTemplate): Observable<IPNSFormTemplate> {
        return this.PNS.authenticatePost('forms/template/', payload);
    }

    getFormTemplatesForFacility(facilityId: string): Observable<IPNSFormTemplate[]> {
        const search: IPNSFormTemplateSearch = { facilityId };
        return this.loadPNSFormTemplates(search);
    }

    getFormTemplatePDF(templateID: string): Observable<Blob> {
        return this.PNS.authenticateGetBlob('forms/templates/' + templateID + '.pdf');
    }

    getFormTemplatePDFWithPrefill(templateId: string, visitId: string): Observable<Blob> {
        return this.PNS.authenticateGetBlob('forms/templates/preview/' + templateId + '.pdf?visitId=' + visitId);

    }
}
