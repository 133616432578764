import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSApplication} from '@portal-ng/PNSModels';
import {IPNSApplicationSearch} from '@portal-ng/PNSModels/PNSApplicationSearch.model';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})

export class PNSApplicationService {

  static parameterizeSearch(search: IPNSApplicationSearch): HttpParams {
    return new HttpParams()
      .append('locationID', search.locationId.toString())
      .append('limit', environment.PNS.searchLimit.toString(10))
      .append('offset', search.offset.toString(10));
  }

  static defaultSearch(): IPNSApplicationSearch {
    return {
      locationId: '11134287',
      limit     : environment.PNS.searchLimit,
      offset    : 0,
    };
  }

  constructor(
    private PNS: PNSService,
  ) {}

  loadApplications(payload: IPNSApplicationSearch): Observable<IPNSApplication[]> {
    return this.PNS.authenticateGet('applications', PNSApplicationService.parameterizeSearch(payload));
  }

}
