import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
    selector   : 'portalNG-raw-search-result-component',
    templateUrl: './raw-search-result.component.html',
    styleUrls  : ['./raw-search-result.component.scss'],
})

export class PNSRawSearchResultComponent implements OnInit {

    public downloadData: SafeUrl;
    public data: any       = '';

    constructor(private sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) data) {
        this.data = data.data;
    }

    public ngOnInit(): void {
        this.downloadData = this.sanitizer.bypassSecurityTrustUrl('data:text/json;charset=UTF-8,' + encodeURIComponent(this.data));
    }
}
