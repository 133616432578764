import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSLocation} from '@portal-ng/PNSModels';
import * as PNSLocationActions from '../actions/PNSLocation.action';

export interface IPNSLocationState extends EntityState<IPNSLocation> {
  loaded: boolean;
}

export const adapter: EntityAdapter<IPNSLocation> = createEntityAdapter<IPNSLocation>({
  selectId: (location: IPNSLocation) => location.id,
  sortComparer: false,
});

const initialState: IPNSLocationState = adapter.getInitialState({
  selectedLocationId: null,
  loaded: false,
});

export function reducer(state: IPNSLocationState = initialState, action: PNSLocationActions.PNSLocationActionTypeUnion): IPNSLocationState {
  switch (action.type) {
    case PNSLocationActions.PNSLocationActionTypes.LoadPNSLocations:
      return {
        ...state,
      };

    case PNSLocationActions.PNSLocationActionTypes.EditPNSLocationSuccess:
      const changes = action.payload.location;

      const id = changes.id;
      return adapter.updateOne({id, changes}, state);

    case PNSLocationActions.PNSLocationActionTypes.LoadPNSLocationsSuccess:
      const updateState = {...state,
        loaded: true,
      };
      return adapter.addMany(action.payload.locations.results, updateState);

    default:
  return state;
  }
}
