<div *ngIf="authenticatedUser.privilegeNames as privileges">
  <a *ngIf="privileges.includes(item.requiredPrivilegeName)" mat-list-item
     [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
     [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': item.expanded, 'disabled': item.disabled}">
  <span *ngIf="item.iconName">
  <mat-icon class="routeIcon" [svgIcon]="item.iconName"></mat-icon>
  </span>
    {{item.displayName}}
    <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
  </a>
  <div *ngIf="item.expanded">
    <portal-ng-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
    </portal-ng-menu-list-item>
  </div>
</div>
