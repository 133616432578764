import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PNSAuthenticationGuard} from '@portal-ng/PNSShared/guards/PNSAuthentication.guard';
import {PNSFormTemplateListComponent} from '@portal-ng/PNSForms/components/PNSForm-Template-List/PNSFormTemplateList.component';
import {PNSFormTemplateEditComponent} from '@portal-ng/PNSForms/components/PNSForm-Template-Edit/PNSForm-template-edit.component';
import {PNSFormTemplateResolver} from '@portal-ng/PNSShared/resolvers/PNSFormTemplate.resolver';

export const formsRoutes: Routes = [
  {
    path      : 'forms',
    redirectTo: '/admin/forms',
    pathMatch : 'full',
  },
  {
    path       : 'admin/forms',
    component  : PNSFormTemplateListComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    pathMatch  : 'full',
    resolve    : [PNSFormTemplateResolver],
  },
  {
    path       : 'edit/:id',
    component  : PNSFormTemplateEditComponent,
    canActivate: [PNSAuthenticationGuard, PNSAuthenticationGuard],
    pathMatch  : 'full',
    resolve    : [PNSFormTemplateResolver],
  },
];

@NgModule({
  imports: [RouterModule.forChild(formsRoutes)],
  exports: [RouterModule],
})

export class PNSFormsRoutingModule {}
