import {Component, OnInit, ViewChild} from '@angular/core';
import {IAppState} from '@portal-ng/PNSCore/reducers';
import {getFormTemplateByID} from '@portal-ng/PNSForms/reducers';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {IPNSFormDeliveryTarget, IPNSFormTemplate} from '@portal-ng/PNSModels';
import {MatTableDataSource} from '@angular/material/table';
import {LOCATION_TREE_DATA} from '@portal-ng/PNSShared/components/PNSTreeSelectList/data/locationtree';
import {PNSTreeSelectListNodeSelection} from '@portal-ng/PNSShared/components/PNSTreeSelectList/PNSTreeSelectList.model';
import {PNSFileService} from '@portal-ng/PNSServices/PNSFile.service';
import {PNSTreeSelectListComponent} from '@portal-ng/PNSShared/components/PNSTreeSelectList/PNSTreeSelectList.component';
import {environment} from '../../../../environments/environment';
import {PNSFormTemplateService} from '@portal-ng/PNSServices/PNSFormTemplate.service';
import {EditPNSFormTemplate} from '@portal-ng/PNSForms/actions/PNSFormTemplate.action';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector   : 'portal-ng-pnsform-edit',
    templateUrl: './PNSForm-template-edit.component.html',
    styleUrls  : ['./PNSForm-template-edit.component.scss']
})

export class PNSFormTemplateEditComponent implements OnInit {
    @ViewChild('locationTree') locationTree: PNSTreeSelectListComponent;

    private newFile: File;
    public topics: Map<string, string> = new Map<string, string>([
        ['pns:permissions', 'Engagement Permission'],
        ['pns:task:transportation', 'Transportation'],
        ['pns:permissions:rx', 'Rx Permission'],
    ]);

    private formTemplateId: string;
    public formTemplate: IPNSFormTemplate;
    public displayedColumns: string[] = ['label', 'address', 'actions'];
    public deliveryTargetDataSource   = new MatTableDataSource<IPNSFormDeliveryTarget>();
    public newFormData: any;
    public locationTreeData           = LOCATION_TREE_DATA[environment.type];
    public readyForUpload: boolean;
    public formLocationIds: string[];
    public fileUrl: SafeResourceUrl;

    constructor(
        private route: ActivatedRoute,
        private formService: PNSFormTemplateService,
        private fileService: PNSFileService,
        private store: Store<IAppState>,
        private sanitizer: DomSanitizer,
    ) { }

    log(event: PNSTreeSelectListNodeSelection) {
      if (this.locationTree) {
          this.formTemplate.facilityIds = this.locationTree.getCurrentSelectionValues();
      }
    }

    ngOnInit(): void {
        this.formTemplateId = this.route.snapshot.params['id'];
        this.store.select(getFormTemplateByID(this.formTemplateId)).subscribe((formTemplate) => {
            this.formTemplate                  = formTemplate;
            this.deliveryTargetDataSource.data = formTemplate.deliveryTargets;
            this.formLocationIds = this.formTemplate.facilityIds.map(String);
        });
        this.previewPDF(this.formTemplate.id);
    }

    updateFile(facilityId: string) {
        this.fileService.updateForm(facilityId, this.newFile);
    }

    public deleteDeliveryTarget(id) {

    }

    public onChange(files: FileList) {
        if (files[0] === undefined) {
            this.readyForUpload = false;
        } else {
            this.readyForUpload = true;
            this.newFile        = files[0];
        }
        // this.cdf.detectChanges();
    }

    public previewPDF(id: string) {
        this.formService.getFormTemplatePDF(id).subscribe((result: Blob) => {
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(result));
        });
    }

    public applyFormTemplateChanges() {
        this.store.dispatch(new EditPNSFormTemplate(this.formTemplate));
    }
}
