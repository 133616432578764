import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSOrganization} from '@portal-ng/PNSModels/PNSOrganization.model';
import * as fromOrganization from '@portal-ng/PNSOrganization/reducers';
import {LoadPNSOrganizations} from '@portal-ng/PNSOrganization/actions/PNSOrganization.action';
import {PNSOrganizationService} from '@portal-ng/PNSServices/PNSOrganization.service';

@Component({
  selector: 'portal-ng-PNSOrganization-list',
  templateUrl: './PNSOrganization-list.component.html',
  styleUrls: ['./PNSOrganization-list.component.scss'],
})
export class PNSOrganizationListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filter: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'actions', 'name', 'abbreviation', 'externalID', 'type'];

  toggledUser$: Partial<IPNSOrganization> = {
    abbreviation: '',
    name: '',
    orgType: 'HOSPITAL',
  externalID: '',
};

  constructor(  private store: Store<IAuthState>) {
  }

  ngOnInit() {
    this.store.pipe(select(fromOrganization.getAllOrganizations)).subscribe();
    this.dataSource = new MatTableDataSource<IPNSOrganization>();
    this.refresh();
  }

  updateOrganizationList() {
    this.store.dispatch(new LoadPNSOrganizations(PNSOrganizationService.getDefaultSearch()));
  }

  ngOnDestroy() {
    // this.user$.unsubscribe();
  }

  applyFilter(filter: EventTarget) {
    const element = filter as HTMLInputElement;
    let filterValue: string;

    if (element.value !== undefined) {
      filterValue = element.value.trim().toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    } else {
      this.refresh();
    }
  }

  refresh() {
    this.store.pipe(select(fromOrganization.getAllOrganizations))
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource<IPNSOrganization>(data);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filter = this.filter;
        });

      });
  }

}
