import {Action} from '@ngrx/store';
import {IPNSFormTemplate, IPNSFormTemplateSearch} from '@portal-ng/PNSModels';

export enum PNSFormTemplateActionTypes {
  AddPNSFormTemplate = '[PNSFormTemplate] Add',
  AddPNSFormTemplateSuccess = '[PNSFormTemplate] Add Success',

  RemPNSFormTemplateConfirm = '[PNSFormTemplate] Remove Confirm',
  RemPNSFormTemplateCancel = '[PNSFormTemplate] Remove Cancel',
  RemPNSFormTemplate = '[PNSFormTemplate] Remove',
  RemPNSFormTemplateSuccess = '[PNSFormTemplate] Remove Success',

  LoadPNSFormTemplates = '[PNSFormTemplate] Fetch',
  LoadPNSFormTemplatesSuccess = '[PNSFormTemplate] Fetch Success',

  EditPNSFormTemplate = '[PNSFormTemplate] Remove',
  EditPNSFormTemplateSuccess = '[PNSFormTemplate] Remove Success',
}

// Editing
export class EditPNSFormTemplate implements Action {
  readonly type = PNSFormTemplateActionTypes.EditPNSFormTemplate;
  constructor(public payload: Partial<IPNSFormTemplate>) {}
}

export class EditPNSFormTemplateSuccess implements Action {
  readonly type = PNSFormTemplateActionTypes.EditPNSFormTemplateSuccess;
  constructor(public payload: {formTemplate: IPNSFormTemplate} ) {}
}

export class AddPNSFormTemplate implements Action {
  readonly type = PNSFormTemplateActionTypes.AddPNSFormTemplate;
  constructor(public payload: Partial<IPNSFormTemplate>) {
  }

}

export class AddPNSFormTemplateSuccess implements Action {
  readonly type = PNSFormTemplateActionTypes.AddPNSFormTemplateSuccess;
  constructor(public payload: { FormTemplate: IPNSFormTemplate }) {
  }
}

// Loading

export class LoadPNSFormTemplates implements Action {
  readonly type = PNSFormTemplateActionTypes.LoadPNSFormTemplates;
  constructor(public payload: IPNSFormTemplateSearch) {}
}

export class LoadPNSFormTemplatesSuccess implements Action {
  readonly type = PNSFormTemplateActionTypes.LoadPNSFormTemplatesSuccess;
  constructor(public payload: {response: IPNSFormTemplate[]} ) {}
}

export type PNSFormTemplateActionTypeUnion =
  AddPNSFormTemplate
  | AddPNSFormTemplateSuccess
  | EditPNSFormTemplate
  | EditPNSFormTemplateSuccess
  | LoadPNSFormTemplates
  | LoadPNSFormTemplatesSuccess;
