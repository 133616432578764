import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {Store} from '@ngrx/store';
import {IState} from '@portal-ng/PNSUser/reducers';
import {SAMLTokenRequest} from '@portal-ng/PNSAuth/actions/PNSAuth.actions';
import {SAMLTokenRequestPayload} from '@portal-ng/PNSModels';

@Component({
  selector   : 'portal-ng-samllogin',
  templateUrl: './saml-login.component.html',
  styleUrls  : ['./saml-login.component.scss']
})
export class SamlLoginComponent implements OnInit {

  showLoginButton = false;

  constructor(
    private route: ActivatedRoute,
    private auth: PNSAuthService,
    private store: Store<IState>,
  ) { }

  doLogin(): void {
    window.location.href = this.auth.getHostedLoginURL();
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['code'] === undefined || this.route.snapshot.queryParams['code'] === null) {
     this.showLoginButton = true;
    } else if (window.location.href !== undefined) {
      this.store.dispatch(new SAMLTokenRequest( {
          code       : this.route.snapshot.queryParams['code'],
          redirectURL: '/',
      } as SAMLTokenRequestPayload));
    } else {
      console.log('SAMLLogin Component Error');
    }
  }
}

