<div id="dialogWrapper">
<h1  mat-dialog-title>Forms for {{data.facilityName}} <button color="primary" mat-mini-fab (click)="addFormTemplate()"><mat-icon svgIcon="plus"></mat-icon></button></h1>

<mat-selection-list #locationFormTemplates [(ngModel)]="selectedFormTemplates" >
    <mat-list-option *ngFor="let form of data.forms" [selected]="form.facilityIds.includes(data.facilityId)" [value]="form.id">
        <mat-icon mat-list-icon svgIcon="clipboard-edit"></mat-icon>
        <div mat-line>{{form.displayName}}</div>
        <div mat-line><em>{{form.topic}}</em></div>
    </mat-list-option>
</mat-selection-list>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close] (click)="editAssignedForms()" cdkFocusInitial>Apply</button>
</div>
</div>
