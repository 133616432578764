import {PNSAuthActions} from '@portal-ng/PNSAuth/actions';
import {IPNSUser, SAMLTokenResponsePayload} from '@portal-ng/PNSModels';

export interface IAuthState {
  needsAuthentication: boolean;
  authenticatedUser: IPNSUser | null;
  authenticated: boolean;
  authentication: SAMLTokenResponsePayload | null;
  preAuthenticationURL: string | null;
  postAuthenticationURL: string | null;
}

export const initialState: IAuthState = {
  needsAuthentication : false,
  authenticatedUser   : null,
  authenticated       : false,
  authentication      : null,
  preAuthenticationURL: null,
  postAuthenticationURL: null,
};

export function reducer(
  state = initialState,

  action: PNSAuthActions.PNSAuthActionsUnion): IAuthState {
  switch (action.type) {

    case PNSAuthActions.PNSAuthActionTypes.AuthenticationRequired: {
      return {
        ...state,
        needsAuthentication: false,
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.AuthSetPostAuthURL: {
      return {
        ...state,
        postAuthenticationURL: action.payload.url,
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.SAMLTokenResponse: {
      return {
        ...state,
        authenticated : true,
        authentication: action.payload,
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.SAMLRefreshTokenError: {
      return {
        ...state,
        authenticated: false,
        authentication: null,
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.SAMLRefreshTokenSuccess: {
      return {
        ...state,
        preAuthenticationURL: null,
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.LoginSuccess: {
      return {
        ...state,
        preAuthenticationURL: null,
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.SAMLRefreshTokenResponse: {
      return {
        ...state,
        authenticated : true,
        authentication: {
          id_token: action.payload.id_token,
          access_token: action.payload.access_token,
          issued: Date.now().toString(),
          expires_in: action.payload.expires_in,
          token_type: action.payload.token_type,
          refresh_token: state.authentication.refresh_token,
        },
      };
    }

    case PNSAuthActions.PNSAuthActionTypes.Logout: {
      if (action.payload.url === '/login') {
        return {
          ...state,
          needsAuthentication: false,
          authenticatedUser: null,
          authenticated    : false,
          authentication   : null,
          preAuthenticationURL: null,
        };
      } else {
        return {
          ...state,
          needsAuthentication : false,
          authenticatedUser   : null,
          authenticated       : false,
          authentication      : null,
          preAuthenticationURL: action.payload.url,
        };
      }

    }

    case PNSAuthActions.PNSAuthActionTypes.PNSWhoamiResponse: {
      return {
        ...state,
        authenticated : true,
        authenticatedUser: action.payload,
      };

    }

    default:
      return state;
  }
}

