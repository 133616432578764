<div class="confForm">

  <div *ngIf="this.formReady == true">
    <h1 class="mat-display-1">
      <button mat-mini-fab color="accent" type='button' routerLink="/admin/locations/facility/list">
        <mat-icon svgIcon="arrow_back"></mat-icon>
      </button>
      {{loc.name}} &middot; {{formData.name}}</h1>
    <button mat-raised-button (click)="openBarcodeConfig()">
      <mat-icon svgIcon="qr_code"></mat-icon>
      Barcode Formats
    </button>
    <mat-checkbox style='margin: 1em' (change)="setShowDeprecated($event.checked)">Show Deprecated Fields</mat-checkbox>


    <p class="mat-body-1">{{formData.description}}</p>
    <div *ngIf="configForm !== undefined && configForm !== null">
      <form [formGroup]="configForm" (ngSubmit)="saveForm(configForm)">
        <mat-accordion [multi]="true" class="example-headers-align">
          <mat-expansion-panel [expanded]=true *ngFor="let confGroup of formData.configurationGroups">
            <mat-expansion-panel-header>
              <mat-panel-title>{{confGroup.name}}</mat-panel-title>
              <mat-panel-description>{{confGroup.description}}
                <mat-icon [svgIcon]="confGroup.icon"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div [formGroupName]="confGroup.name">
              <div *ngFor="let confItem of confGroup.configurationItems">
                <div *ngIf="!(this.confItem.status == this.configFieldStatus.Deprecated && !this.showDeprecatedFields)">
                  <div [ngSwitch]="confItem.type">

                    <div *ngSwitchCase="'CHECKBOX'">
                      <mat-checkbox [formControlName]='confItem.name'>{{confItem.name}}</mat-checkbox>
                      <p class="mat-body-1 description">
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </p>
                    </div>

                    <div *ngSwitchCase="'BOOLEAN'">
                      <mat-slide-toggle [formControlName]='confItem.name' [checked]="confItem.value">
                        {{confItem.name}}
                      </mat-slide-toggle>
                      <br/>
                      <mat-hint>{{confItem.description}}</mat-hint>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'TEXTAREA'">
                      <mat-form-field class="full-width">
                    <textarea matInput [formControlName]='confItem.name'
                              placeholder="{{confItem.name}}">{{confItem.value}}</textarea>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'STRINGARRAY'">
                      <div *ngIf="confItem.name;">
                        <fieldset>
                          <label class="mat-h2">{{confItem.displayName}}
                            <button mat-mini-fab color="primary" type='button'
                                    (click)="addArrayItem(configForm, confGroup.name, confItem.name)">
                              <mat-icon svgIcon="plus"></mat-icon>
                            </button>
                          </label><br/><br/>

                          <div
                            *ngIf="configForm.get(confGroup.name.concat('.',confItem.name)) as FormControl; let item;">
                            <div *ngFor="let control of item['controls']; index as i;">
                              <mat-form-field class="full-width">
                                <button style="margin-right: 10px;" type='button' mat-mini-fab color="primary"
                                        (click)="removeArrayItem(configForm, confGroup.name, confItem.name, i)">
                                  <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                                <!--suppress AngularInvalidExpressionResultType -->
                                <input style="width: 50%;" [formControl]="control" matInput
                                       placeholder="Provider: ###-###-####" value="{{control.value}}"/>
                              </mat-form-field>
                            </div>
                            <p>{{confItem.description}}</p>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div *ngSwitchCase="'TEXT'">
                      <mat-form-field class="full-width">
                        <input matInput [formControlName]='confItem.name' placeholder="{{confItem.name}}"/>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'PAYMENT_GATEWAY'">
                      <mat-form-field class="full-width">
                        <input matInput [formControlName]='confItem.name' placeholder="{{confItem.name}}"/>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'INTEGER'">
                      <mat-form-field class="half-width">
                        <input matInput [formControlName]='confItem.name' type="number" min="0" step="1"
                               placeholder="{{confItem.name}}"/>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'DOUBLE'">
                      <mat-form-field class="half-width">
                        <input matInput [formControlName]='confItem.name' type="number" min="0" step="any"
                               placeholder="{{confItem.name}}"/>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'EMAIL_ADDRESS'">
                      <mat-form-field class="half-width">
                        <input matInput [formControlName]='confItem.name' type="email" placeholder="{{confItem.name}}"/>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'BARCODE_FORMATS'">
                      <mat-form-field class="half-width">
                        <mat-label>{{confItem.name}}</mat-label>
                        <mat-select [formControlName]='confItem.name'>
                          <mat-option value='Patient'>Patient</mat-option>
                          <mat-option value='Visit'>Visit</mat-option>
                        </mat-select>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>

                    </div>
                    <div *ngSwitchCase="'PERMISSION_LEVEL'">
                      <mat-form-field class="half-width">
                        <mat-label>{{confItem.name}}</mat-label>
                        <mat-select [formControlName]='confItem.name'>
                          <mat-option value='Patient'>Patient</mat-option>
                          <mat-option value='Visit'>Visit</mat-option>
                        </mat-select>
                        <mat-hint>{{confItem.description}}</mat-hint>
                      </mat-form-field>
                      <br/><br/>
                    </div>

                    <div *ngSwitchCase="'CODE_ARRAY'">
                      <mat-label>{{confItem.name}}</mat-label>
                      <mat-selection-list [compareWith]="compareCodes" [formControlName]='confItem.name'
                                          multiple="true">
                        <mat-list-option checkboxPosition="before"
                                         *ngFor="let code of this.getCodes(confItem.codeCategory)"
                                         [value]="code">{{code.title}}</mat-list-option>
                      </mat-selection-list>
                      <br/><br/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class=" "><br/>
          <button style="margin: 10px;" mat-raised-button color="primary" (click)="saveForm(configForm)">Save
            Configuration
          </button>
          <button style="margin: 10px;" mat-raised-button color="accent" routerLink="/admin/locations/facility/list">
            Cancel
            Configuration
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
