<div id="page">
  <h1 class="mat-h1">Add Organization</h1>
<form #organizationForm="ngForm"  (ngSubmit)="addOrg(organizationForm)" >
  <mat-form-field class="full-width">
    <input matInput placeholder="name" [(ngModel)]="addOrg$.name"  name="organizationname">
    <mat-hint align="start"><strong>The organization's name</strong> </mat-hint>
    <mat-hint align="end">{{addOrg$.name.length}} / 256</mat-hint>
    <mat-error *ngIf="organizationForm.hasError('name') && !organizationForm.hasError('name')">
      Please enter a valid organization name.
    </mat-error>
    <mat-error *ngIf="organizationForm.hasError('required')">
      Organization name is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br />  <br />
  <mat-form-field class="full-width">
    <input matInput placeholder="abbreviation" [(ngModel)]="addOrg$.abbreviation" name="abbreviation"/>
    <mat-hint align="start">The organization's abbreviation.</mat-hint>
  </mat-form-field>
  <br />  <br />
  <mat-form-field>
      <select matNativeControl required name="orgType" [(ngModel)]="addOrg$.orgType">
        <option value="HOSPITAL">Hospital</option>
        <option value="HEALTH_SYSTEM">Health System</option>
      </select>
  </mat-form-field>

  <div class="form-actions">
    <button type="button" mat-raised-button [routerLink]="['/admin/organizations/list']">Cancel Adding Organization</button>
    <button type="submit" mat-raised-button color="primary"  cdkFocusInitial [disabled]="!organizationForm.valid" >Add organizationOrganization</button>


  </div>
</form>
</div>
