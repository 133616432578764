import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {LoadPNSConnection} from '@portal-ng/PNSAdminEntities/actions/PNSConnection.action';
import {getConnectionsLoaded} from '@portal-ng/PNSAdminEntities/reducers';
import {IPNSConnectionState} from '@portal-ng/PNSAdminEntities/reducers/PNSConnection.reducer';
import {PNSConnectionService} from '@portal-ng/PNSServices/PNSConnectionService';
import {PNSTaskService} from '@portal-ng/PNSServices/PNSTask.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';

@Injectable()
export class PNSConnectionResolver implements CanActivate {
  constructor(private taskService: PNSTaskService, private store: Store<IPNSConnectionState>) {
  }

  canActivate(): Observable<boolean> {
    return this.getConnections().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  private getConnections(): Observable<any> {
    return this.store.pipe(
      select(getConnectionsLoaded),
      tap((consLoaded) => { if (!consLoaded) {
        this.store.dispatch(new LoadPNSConnection(PNSConnectionService.defaultSearch()));
      }}),
      filter((consLoaded) => consLoaded),
      take(1),
    );
  }
}
