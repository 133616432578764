import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IPNSExecution} from '@portal-ng/PNSModels';
import * as PNSExecutionsActions from '../actions/PNSExecutions.action';

export interface IPNSExecutionsState extends EntityState<IPNSExecution> {
  loaded: boolean;
  selectedId: string;
}

export const adapter: EntityAdapter<IPNSExecution> = createEntityAdapter<IPNSExecution>({
  selectId: (Execution: IPNSExecution) => Execution.id,
  sortComparer: false,
});

const initialState: IPNSExecutionsState = adapter.getInitialState({
  selectedId: null,
  loaded: false,
});

export function reducer(state: IPNSExecutionsState = initialState, action: PNSExecutionsActions.PNSExecutionsActionTypeUnion): IPNSExecutionsState {
  switch (action.type) {

    case PNSExecutionsActions.PNSExecutionsActionTypes.LoadPNSExecutionsSuccess:
      const updateState = {...state,
        loaded: true,
      };
      const searchResults: IPNSExecution[] = action.payload.executions.results;
      return adapter.setAll(searchResults, updateState);

    default:
      return state;
  }
}

export const getSelectedExecutionsId = (state: IPNSExecutionsState) => state.selectedId;
export const getExecutionsLoaded     = (state: IPNSExecutionsState) => state.loaded;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectExecutionsIds = selectIds;

// select the dictionary of user entities
export const selectExecutionsEntities = selectEntities;

// select the array of users
export const selectAllExecutions = selectAll;

// select the total user count
export const selectExecutionsTotal = selectTotal;
