import {IPNSCustodialOrganization} from '@portal-ng/PNSModels/PNSCustodialOrganization.model';

export enum PNSLocationConfigurationPermissionLevel {
  Patient,
  Visit,
}

export enum PNSLocationConfigurationBarcodeType {
  RX,
  RXBatch,
}

export enum PNSLocationConfigurationbarcodePriceFormat {
WITH_DECIMAL,
NO_DECIMAL,
}

export enum PNSLocationConfigurationBarcodeControlledSubstanceFormat {
YesNo,
YN,
ZeroOne,
ScheduleNumber,
}

export enum PNSLocationConfigurationBarcodeSymbology {
  Plessey,
  UPC,
  EAN_UCC,
  Codabar,
  Interleaved_2_Of_5,
  Code_93,
  Code_128,
  Code_11,
  Pharmacode,
  POSTNET,
  PostBar,
  CPC_Binary,
  Telepen,
}

export interface IPNSLocationConfigurationBarcodeFormat {
  id: string;
  updatedBy?: string;
  organizationId?: string;
  locationId: string;
  type: PNSLocationConfigurationBarcodeType;
  symbology?: PNSLocationConfigurationBarcodeSymbology;
  matchingExpresion: string;
  rxNumberIndex?: string;
  fillNumberIndex?: string;
  partialFillNumberIndex?: string;
  priceIndex?: string;
  priceFormat?: PNSLocationConfigurationbarcodePriceFormat;
  controlledSubstanceIndex?: string;
  controlledSubstanceFormat?: PNSLocationConfigurationBarcodeControlledSubstanceFormat;
  controlledSubstanceExpression?: string;
}

export interface IPNSLocationConfigurationModel {
  id?: string;
  updatedBy?: string;
  embeddedPOS?: boolean;
  charityCare?: boolean;
  verifyPsePurchase?: boolean;
  verifyCheckPayment?: boolean;
  pseVerifcationOverride?: boolean;
  allowFamilyPickup?: boolean;
  preferScannedPriceForRx?: boolean;
  promptForCustomerDesgination?: boolean;
  autoSelectCreditCardOnFileAgreement?: boolean;
  displaySSN?: boolean;
  loyaltyPointsEnabled?: boolean;
  restrictVoidToManagers?: boolean;
  autoPrintReceipt?: boolean;
  c2dEnabled?: boolean;
  operationalUnitsOnly?: boolean;
  includeLyftWorkflow?: boolean;
  unitAssignmentEnabled?: boolean;
  followUpUnableToServeFeedbackEnabled?: boolean;
  enableWebPayments?: boolean;
  enableBulkUserAssignment?: boolean;
  showPCPAffiliation?: boolean;
  permissionLevel?: PNSLocationConfigurationPermissionLevel;
  rxNotificationEnabled?: boolean;
  scanIdControlledSubstanceOverride?: boolean;
  readmissionsDays?: number;
  barcodeFormats?: IPNSLocationConfigurationBarcodeFormat[];
  transportationProviders?: string[];
  eschedulingEnabled?: boolean;

  custodialOrganizations?: [IPNSCustodialOrganization];
  custodialCouriers?: [any];

  allowNurseHandoffForMedsToBeds?: boolean;
  chainOfCustodyWorkflowAvailableInPOS?: boolean;
  chainOfCustodyWorkflowAvailable?: boolean;
  chainOfCustodyCourierAvailable?: boolean;
  chainOfCustodyTubeSystemAvailable?: boolean;
  chainOfCustodyTubeSystemStationRegex?: string;
  chainOfCustodyTubeSystemStationFormatLabel?: string;
  chainOfCustodyTubeSystemStationImage?: string;
  c2dApplicationLocationId?: string;
  posApplicationLocationId?: string;
}
