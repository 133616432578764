import {Action} from '@ngrx/store';
import {IPNSSearch, IPNSSearchResults} from '@portal-ng/PNSModels';

export enum PNSConnectionActionTypes {
  LoadPNSConnection        = '[PNSConnection] Retrieve',
  LoadPNSConnectionSuccess = '[PNSConnection] Retrieve Success',
}

// Loading

export class LoadPNSConnection implements Action {
  readonly type = PNSConnectionActionTypes.LoadPNSConnection;

  constructor(public payload: IPNSSearch) {}
}

export class LoadPNSConnectionSuccess implements Action {
  readonly type = PNSConnectionActionTypes.LoadPNSConnectionSuccess;

  constructor(public payload: { connection: IPNSSearchResults }) {}
}

export type PNSConnectionActionTypeUnion =
  LoadPNSConnection
  | LoadPNSConnectionSuccess;
