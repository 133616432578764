import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPNSUserSearch} from '@portal-ng/PNSModels/PNSUserSearch.model';
import {IPNSRoleSearchResults, IPNSUserSearchResult} from '@portal-ng/PNSModels/PNSSearchResults.model';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {Observable, of} from 'rxjs';
import {PNSAuthService} from './PNSAuth.service';
import {IPNSUser} from '@portal-ng/PNSModels';

@Injectable({providedIn: 'root'})

export class PNSUserService {

  static logout() {
    return of(true);
  }

  constructor(
    private auth: PNSAuthService,
    private PNS: PNSService,
  ) {}

    static getDefaultSearch(): IPNSUserSearch {
      const defaultSearch      = PNSService.defaultSearch() as IPNSUserSearch;
      defaultSearch.searchTerm = '';
      defaultSearch.limit      = 1000;
      return defaultSearch;
    }

  loadUsers(payload: IPNSUserSearch): Observable<IPNSUserSearchResult> {
    let params = new HttpParams().set('search', payload.searchTerm);
    params = PNSService.setCommonSearchParams(params, payload);
    return this.PNS.authenticateGet( 'users', params);
  }

  loadUser(username: string): Observable<IPNSUser> {
    const params = new HttpParams();
    return this.PNS.authenticateGet( 'users/' + username, params);
  }


  loadRoles(): Observable<IPNSRoleSearchResults> {
    return this.PNS.authenticateGet( 'roles');
  }

  reloadUser(username: string): void {
    this.loadUser(username).subscribe((qwwwuser) => {
      this.editUser({username, updatedBy: 'portalRefresh'}).subscribe((x) => console.log(x));
    });
  }

  editUser(user: Partial<IPNSUser>): Observable<any> {
    return this.PNS.authenticatePut( 'users/' + encodeURIComponent(user.username), user);
  }

  addUser(user: Partial<IPNSUser>): Observable<any> {
    return this.PNS.authenticatePost( 'users', user);
  }

  deleteUser(username: string): Observable<any> {
    return this.PNS.authenticateDelete( 'users/' + username);
  }

  resetUserPassword(username: string): Observable<any> {
    return this.PNS.authenticatePost( 'users/' + username + '/reset/email', []);
  }
}
