import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'portalNG-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
