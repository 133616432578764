import {PNSEnvironmentDataMap} from '../PNSTreeSelectList.model';

export const LOCATION_TREE_DATA: PNSEnvironmentDataMap = {
    PROD: {
        ROOT: {
            'Testing & Development': {
                'Moreland Medical Center': '7089512 ',
                'MORELAND'               : '11134428',
            },
            'Advent Health'        : {
                'West Florida Division'         : {
                    'Hospitals': {
                        'Florida Heartland': '11134018',
                        'Ocala'            : '11135974',
                        'Dade City'        : '11136052',
                        'Tampa'            : '11136055',
                        'Wesley Chapel'    : '11136056',
                        'Zephyrhills'      : '11136057',
                        'North Pinellas'   : '11136058',
                        'Carrollwood'      : '11136059',
                    },
                    'POS'      : {
                        'Florida Heartland POS ': '11134287',
                        'Tampa POS'             : '11140492',
                    },
                    'Remote ED': {
                        'Central Pasco ER': '11136496',
                        'Palm Harbor ER'  : '11136497',
                        'TimberRidge ER'  : '11136647',
                        'Westchase ER'    : '11140480',
                        'Brandon ER'      : '11140482',
                        'Lake Placid '    : '11134316',
                        'Wauchula'        : '11134317',
                    },
                },
                'Southeast Mid-America Division': {
                    'Shawnee Mission'    : '11140750',
                    'Shawnee Mission POS': '11141061',
                },
                'Southeast Division'            : {
                    'Gordon'    : '11140514',
                    'Murray'    : '11140515',
                    'Manchester': '11140516',
                },
                'Specialty Facilities'          : {
                    'AdventHealth CentraCare': '11140622',
                    'AHMG'                   : '11140623',
                    'Connerton'              : '11140477',
                },
            },
        },
    },
    DEV : {
        ROOT: {
            'Development & Testing': {
                'Moreland Medical Center'           : '7089512',
                'Donahue Health System'             : '11147691',
                'AdventHealth Sebring EPIC TEST'    : '11147973',
                'AdventHealth Sebring POS EPIC TEST': '11148164',
            },
            'AdventHealth'         : {
                'West Florida Division': {
                    'Specialty Groups'              : {
                        'AdventHealth Centra Care'  : '11147742',
                        'AdventHealth Medical Group': '11147743',
                    },
                    'Hospitals'                     : {
                        'AdventHealth Sebring'       : '11134360',
                        'AdventHealth Ocala'         : '11146167',
                        'AdventHealth Zephyrhills'   : '11146255',
                        'AdventHealth Wesley Chapel' : '11146256',
                        'AdventHealth Dade City'     : '11146257',
                        'AdventHealth North Pinellas': '11146259',
                        'AdventHealth Tampa'         : '11146260',
                        'AdventHealth Carrollwood'   : '11146261',
                    },
                    'Off-Site Emergency Departments': {
                        'AdventHealth Lake Placid ER'  : '11135080',
                        'AdventHealth Wauchula'        : '11135081',
                        'AdventHealth Central Pasco ER': '11146640',
                        'AdventHealth Palm Harbor ER'  : '11146641',
                        'AdventHealth TimberRidge ER'  : '11146717',
                        'AdventHealth Connerton'       : '11147666',
                        'AdventHealth Brandon ER'      : '11147686',
                        'AdventHealth Westchase ER'    : '11147687',
                    },
                    'POS Locations'                 : {
                        'AdventHealth Sebring POS'    : '11146001',
                        'AdventHealth Ocala POS'      : '11146168',
                        'AdventHealth Tampa POS'      : '11147946',
                        'AdventHealth Carrollwood POS': '11148091',
                    },
                },
                'Georgia & Kentucky'   : {
                    'Hospitals'    : {
                        'AdventHealth Gordon '   : '11147692',
                        'AdventHealth Murray '   : '11147693',
                        'AdventHealth Manchester': '11147694',
                    },
                    'POS Locations': {
                        'AdventHealth Gordon POS': '11148170',
                        'AdventHealth Murray POS': '11148171',
                    },
                },
                'Midwest'              : {
                    'AdventHealth Shawnee Mission'    : '11147745',
                    'AdventHealth Shawnee Mission POS': '11147957',
                },
            }

        },
    }
};




