import {Injectable} from '@angular/core';
import {ActivatedRoute, CanActivate, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class PNSAuthorizationGuard implements CanActivate {
    constructor(private store: Store<IAuthState>, private activatedRoute: ActivatedRoute, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.store.pipe(select(fromAuth.getAuthModuleState),
            map((authState: IAuthState) => {
                if (authState && authState.authenticatedUser && authState.authenticatedUser.privilegeNames) {
                    if (authState.authenticatedUser.privilegeNames.indexOf(environment.Auth.portalAccessRole) !== -1) {
                        return true;
                    } else {
                        this.router.navigate(['/403']);
                        return false;
                    }
                } else {
                    this.router.navigate(['/login']);
                    return false;
                }
            }),
            take(1),
        );
    }
}
