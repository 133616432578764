import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import * as fromLocation from '@portal-ng/PNSLocation/reducers';
import {IPNSTableColumnConfiguration, IPNSTableFilter, IPNSVisitSummary, IPNSVisitSummarySearchResults, PNSColumnType} from '@portal-ng/PNSModels';
import {IPNSLocation} from '@portal-ng/PNSModels/PNSLocation.model';
import {Subscription} from 'rxjs';
import {PNSVisitService} from '@portal-ng/PNSServices/PNSVisit.service';

@Component({
  selector: 'portal-ng-pnslocation-unit-status',
  templateUrl: './pnslocation-unit-status.component.html',
  styleUrls: ['./pnslocation-unit-status.component.scss'],
})

export class PNSLocationUnitStatusComponent implements OnInit {
  locationID: string;
  unitID: string;
  loc$; unit$; sum$: Subscription;
  location$: IPNSLocation;
  summaries$: IPNSVisitSummary[];

  activeOnly = false;

  columnConfiguration: IPNSTableColumnConfiguration[] = [
    {name: 'visit.visitNumber', title: 'Visit Number', type: PNSColumnType.string},
    {name: 'visit.status', title: 'Status', type: PNSColumnType.string},
    {name: 'pnsDischargeDisposition', title: 'Discharge Disposition', type: PNSColumnType.string},
    {name: 'Room', title: 'Patient Location', type: PNSColumnType.concat, names: ['visit.unit', 'visit.room', 'visit.bed'], delimiter: ' | '},
    {name: 'fullName2', title: 'Patient Name', type: PNSColumnType.string},
    {name: 'mrn', title: 'Medical Record Number', type: PNSColumnType.string},
    {name: 'compositeDischargeTime', title: 'Discharge Time', type: PNSColumnType.dateTime},
  ];

  filters: IPNSTableFilter[] = [
    {name: 'Active Patients', type: 'checkbox'} as IPNSTableFilter,
  ];

  constructor(
    private store: Store<IAuthState>,
    private cdf: ChangeDetectorRef,
    private route: ActivatedRoute,
    private visitService: PNSVisitService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.locationID = this.route.snapshot.params['locationID'];
    this.unitID = this.route.snapshot.params['unitID'];
    this.unit$ = this.store.pipe(select(fromLocation.getLocationByID(this.unitID))).subscribe( (unit: IPNSLocation) => {this.unit$ = unit; });
    this.loc$ = this.store.pipe(select(fromLocation.getLocationByID(this.locationID))).subscribe((loc) => {this.location$ = loc; });
    this.sum$ = this.visitService.getVisitSummariesByUnitID(this.locationID, this.unitID).subscribe(
      ((results) => {
        this.summaries$ = results;
      }));
    this.refresh();
  }

  filter(event) {
    this.activeOnly = event.source.id === 'Active Patients' && event.checked === true;
    this.refresh();
  }

  detectDoubleBeds(): boolean {
    return this.summaries$.map((ps) => ps.visit.room + ' | ' + ps.visit.bed)
      .some((value, index, array) => array.indexOf(value) !== array.lastIndexOf(value));
  }

  doubleBedAlert() {
   if (this.detectDoubleBeds()) {
     this.showDoubleBedAlert();
   }
  }

  showDoubleBedAlert() {
    this.snackBar.open('Double Bedded Patient Detected', 'Refresh Patient Summary',
      {duration: 5000});
  }

  refresh() {
    if (this.activeOnly === true) {
      this.sum$ = this.visitService.getActiveVisitSummariesByUnitID(this.locationID, this.unitID).subscribe(
        (results: IPNSVisitSummarySearchResults) => {
          this.summaries$ = results.patientSummaries;
        });
    } else {
      this.sum$ = this.visitService.getVisitSummariesByUnitID(this.locationID, this.unitID).subscribe(
        (results: IPNSVisitSummarySearchResults) => {
          this.summaries$ = results.patientSummaries;
        });
      this.cdf.detectChanges();
      this.doubleBedAlert();
    }
  }
}
