import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@portal-ng/PNSAuth/reducers';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSUser} from '@portal-ng/PNSModels';
import {MicrosoftService} from '@portal-ng/PNSServices/Microsoft.service';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {PNSUserService} from '@portal-ng/PNSServices/PNSUser.service';
import {Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Component({
    selector   : 'portal-ng-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls  : ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
    env: any;
    auth$: Subscription;
    authUser: IPNSUser;
    isAuthenticated             = false;
    userPhotoData: SafeUrl;
    userPhotoBlob: Subject<any> = new Subject();
    msUser: any;
    quickNav                    = {
        items    : [
            {
                routerLink: '/admin/tasks/oneTimeTasks',
                icon      : 'plus-one',
                title     : 'One-Time Tasks',
            },
            {
                href : 'https://peatech.atlassian.net/wiki/spaces/PNSKB',
                icon : 'library',
                title: 'Customer Service Portal',
            },
            {
                routerLink: '/admin/locations/facility/list',
                icon      : 'hospital',
                title     : 'Facilities',
            },
            {
                routerLink: '/admin/tasks/oneTimeTasks',
                icon      : 'plus-one',
                title     : 'One-Time Tasks',
            },
            {
                href : 'https://peatech.atlassian.net/wiki/spaces/PNSKB',
                icon : 'library',
                title: 'Customer Service Portal',
            },
            {
                routerLink: '/admin/locations/facility/list',
                icon      : 'hospital',
                title     : 'Facilities',
            },
            {
                routerLink: '/admin/organizations/list',
                icon      : 'domain',
                title     : 'Organizations',
            },
            {
                href : 'https://patientengagementadvisors.sharepoint.com/SitePages/Academy.aspx?RootFolder=%2FAcademy%20Document%20Library%2F2019',
                icon : 'school',
                title: 'PEA Academy',
            },
            {
                href : 'https://peatech.atlassian.net/projects/PNS',
                icon : 'bug',
                title: 'JIRA',
            },
            {
                routerLink: '/admin/organizations/list',
                icon      : 'domain',
                title     : 'Organizations',
            },
            {
                href : 'https://patientengagementadvisors.sharepoint.com/SitePages/Academy.aspx?RootFolder=%2FAcademy%20Document%20Library%2F2019',
                icon : 'school',
                title: 'PEA Academy',
            },
            {
                href : 'https://peatech.atlassian.net/projects/PNS',
                icon : 'bug',
                title: 'JIRA',
            },
            {
                routerLink: '/admin/users/add',
                icon      : 'account-plus',
                title     : 'Add User',
            },
            {
                routerLink: '/admin/locations/facility/add',
                svgIcon   : 'hospital',
                title     : 'Add Facility',
            },
        ],
    };

    cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(() => {
            return [
                {title: '', cols: 1, rows: 5, type: 'whatsnew', content: '', class: 'dashboard-card'},
                {title: 'Quick Links', cols: 1, rows: 3, type: 'quickLinks', content: 'TBD', class: 'dashboard-card'},
                {title: 'System Status', cols: 1, rows: 2, type: 'status', content: '', class: 'dashboard-card'},
            ];
        }),
    );

    ngOnInit() {
        this.env   = environment;
        this.auth$ = this.store.pipe(select(fromAuth.getAuthModuleState))
            .subscribe((auth: IAuthState) => {
                    if (auth.authenticatedUser) {
                        this.authUser        = auth.authenticatedUser;
                        this.isAuthenticated = auth.authenticated;
                    }
                }
            );

    }

    constructor(
        private cdf: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver,
        private store: Store<IAuthState>,
        private userService: PNSUserService,
        private locationService: PNSLocationService,
        private msService: MicrosoftService,
        private sanitizer: DomSanitizer,
    ) {
        sanitizer.bypassSecurityTrustResourceUrl('https://js-agent.newrelic.com/nr-spa-1099.min.js');
    }

    public ngAfterViewInit(): void {}
}
