import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {
    PNSTreeSelectListData,
    PNSTreeSelectListFlatNode,
    PNSTreeSelectListNode,
    PNSTreeSelectListNodeSelection,
} from '@portal-ng/PNSShared/components/PNSTreeSelectList/PNSTreeSelectList.model';
import {PNSTreeSelectListController} from '@portal-ng/PNSShared/components/PNSTreeSelectList/PNSTreeSelectListController';

@Component({
    selector   : 'PortalNG-PNSTreeSelectList',
    templateUrl: './PNSTreeSelectList.component.html',
    styleUrls  : ['./PNSTreeSelectList.component.scss'],
    providers  : [PNSTreeSelectListController],
})

export class PNSTreeSelectListComponent implements OnInit, AfterViewInit {
    @Input() initialSelectedValues: string[];
    @Input() multiSelect: boolean;
    @Input() treeData: PNSTreeSelectListData;
    @Input() startExpanded: boolean;
    @Output() selectionChanged: EventEmitter<PNSTreeSelectListNodeSelection> = new EventEmitter<PNSTreeSelectListNodeSelection>();

    flatNodeMap                                      = new Map<PNSTreeSelectListFlatNode, PNSTreeSelectListNode>();
    nestedNodeMap                                    = new Map<PNSTreeSelectListNode, PNSTreeSelectListFlatNode>();
    selectedParent: PNSTreeSelectListFlatNode | null = null;
    newItemName                                      = '';
    treeControl: FlatTreeControl<PNSTreeSelectListFlatNode>;
    treeFlattener: MatTreeFlattener<PNSTreeSelectListNode, PNSTreeSelectListFlatNode>;
    dataSource: MatTreeFlatDataSource<PNSTreeSelectListNode, PNSTreeSelectListFlatNode>;
    checklistSelection: SelectionModel<PNSTreeSelectListFlatNode>;
    isAllExpanded: boolean;
    expandToggleIcon                                 = 'expand-all';

    constructor(private _database: PNSTreeSelectListController) {}

    ngOnInit() {
        this._database.loadData(this.treeData);
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl   = new FlatTreeControl<PNSTreeSelectListFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource    = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this._database.dataChange.subscribe((data) => this.dataSource.data = data);
        this.checklistSelection = new SelectionModel<PNSTreeSelectListFlatNode>(this.multiSelect);

        if (this.treeControl.dataNodes.length > 0 && this.initialSelectedValues && this.initialSelectedValues.length > 0) {
            this.initializeSelections(this.initialSelectedValues);
        }

    }

    // Returns values of currently selected items in tree list.

    initializeSelections(initialSelections: string[]) {
        for (const item of this.treeControl.dataNodes) {
            if (item && (initialSelections.includes(item.value))) {

                this.nodeToggle(item);
            }
        }
    }

    getCurrentSelectionValues(): string[] {
        const selectionSnapshot = Array();
        this.checklistSelection.selected.forEach((f) => {
            if (f.value !== undefined) {
                selectionSnapshot.push(f.value);
            }
        });
        return selectionSnapshot;
    }

    ngAfterViewInit() {
        if (this.startExpanded) { this.treeControl.expandAll(); }
        this.isAllExpanded = !this.startExpanded;

    }

    getLevel     = (node: PNSTreeSelectListFlatNode) => node.level;
    isExpandable = (node: PNSTreeSelectListFlatNode) => node.expandable;
    getChildren  = (node: PNSTreeSelectListNode): PNSTreeSelectListNode[] => node.children;
    hasChild     = (_: number, _nodeData: PNSTreeSelectListFlatNode) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: PNSTreeSelectListFlatNode) => _nodeData.item === '';

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: PNSTreeSelectListNode, level: number) => {
        const existingNode  = this.nestedNodeMap.get(node);
        const flatNode      = existingNode && existingNode.item === node.item
            ? existingNode
            : new PNSTreeSelectListFlatNode();
        flatNode.item       = node.item;
        flatNode.value      = node.value;
        flatNode.level      = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: PNSTreeSelectListFlatNode): boolean {
        return this.treeControl.getDescendants(node).every((child) =>
            this.checklistSelection.isSelected(child),
        );
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: PNSTreeSelectListFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result      = descendants.some((child) => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    nodeItemSelectionToggle(node: PNSTreeSelectListFlatNode): void {
        this.nodeToggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.nodeSelect(...descendants)
            : this.nodeDeselect(...descendants);

        // Force update for the parent
        descendants.every((child) =>
            this.checklistSelection.isSelected(child),
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    nodeLeafItemSelectionToggle(node: PNSTreeSelectListFlatNode): void {
        this.nodeToggle(node);

        if (this.multiSelect) {
            this.checkAllParentsSelection(node);
        }
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: PNSTreeSelectListFlatNode): void {
        let parent: PNSTreeSelectListFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: PNSTreeSelectListFlatNode): void {
        const nodeSelected    = this.checklistSelection.isSelected(node);
        const descendants     = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every((child) =>
            this.checklistSelection.isSelected(child),
        );
        if (nodeSelected && !descAllSelected) {
            this.nodeDeselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.nodeSelect(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: PNSTreeSelectListFlatNode): PNSTreeSelectListFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    nodeSelect(...node: PNSTreeSelectListFlatNode[]) {
        this.checklistSelection.select(...node);
        node.forEach((n) => this.selectionChanged.emit({selected: this.checklistSelection.isSelected(n), node: n})
        );
    }

    nodeDeselect(...node: PNSTreeSelectListFlatNode[]) {
        this.checklistSelection.deselect(...node);
        node.forEach((n) => this.selectionChanged.emit({selected: this.checklistSelection.isSelected(n), node: n}));
    }

    nodeToggle(node: PNSTreeSelectListFlatNode) {
        if (this.multiSelect) {
            this.checklistSelection.toggle(node);
            this.selectionChanged.emit({selected: this.checklistSelection.isSelected(node), node});
        } else {
            this.checklistSelection.select(node);
            this.selectionChanged.emit({selected: this.checklistSelection.isSelected(node), node});
        }
    }

    /** Select the category so we can insert the new item. */
    addNewItem(node: PNSTreeSelectListFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        // tslint:disable-next-line:no-non-null-assertion
        this._database.insertItem(parentNode!, '');
        this.treeControl.expand(node);
    }

    /** Save the node to database */
    saveNode(node: PNSTreeSelectListFlatNode, itemValue: string) {
        const nestedNode = this.flatNodeMap.get(node);
        // tslint:disable-next-line:no-non-null-assertion
        this._database.updateItem(nestedNode!, itemValue);
    }

    public toggleExpanded() {
        if (this.isAllExpanded) {
            this.treeControl.expandAll();
            this.expandToggleIcon = 'collapse-all';
            this.isAllExpanded    = !this.isAllExpanded;
        } else {
            this.treeControl.collapseAll();
            this.expandToggleIcon = 'expand-all';
            this.isAllExpanded    = !this.isAllExpanded;
        }
    }
}
