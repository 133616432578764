import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {AddPNSLocation, LoadPNSLocations} from '@portal-ng/PNSLocation/actions/PNSLocation.action';
import * as fromLocs from '@portal-ng/PNSLocation/reducers';
import {IPNSLocation, IPNSLocationConfigurationModel, IPNSOrganization, PNSLocationConfigurationPermissionLevel} from '@portal-ng/PNSModels';
import * as fromOrgs from '@portal-ng/PNSOrganization/reducers';
import {GoogleGeocodingService} from '@portal-ng/PNSServices/Google.Geocoding';
import {PNSLocationService} from '@portal-ng/PNSServices/PNSLocation.service';
import {Subscription} from 'rxjs';

@Component({
  selector   : 'portal-ng-pnslocation-add-form',
  templateUrl: './pnslocation-add-form.component.html',
  styleUrls  : ['./pnslocation-add-form.component.scss'],
})
export class PNSLocationAddFormComponent implements OnInit {
  orgs$: Subscription;
  orgs: IPNSOrganization[];
  locs$: Subscription;
  locs: IPNSLocation[];
  copyLocConfigFrom: IPNSLocation;
  geoAddr: any;
  addrValidated: boolean;
  geocodedAddress$: Subscription;
  addLoc$: Partial<IPNSLocation> = {
    organizationId: '',
    type          : 'FACILITY',
    externalId    : '',
    operational   : true,
    address       : {
      street1  : '',
      street2  : '',
      city     : '',
      state    : '',
      zip      : '',
      longitude: '',
      latitude : '',
    },
    name          : '',
    abbreviation  : '',
    configuration : {
      updatedBy                           : 'defaultConfig',
      embeddedPOS                         : true,
      charityCare                         : false,
      verifyPsePurchase                   : false,
      verifyCheckPayment                  : false,
      pseVerifcationOverride              : false,
      allowFamilyPickup                   : true,
      preferScannedPriceForRx             : true,
      promptForCustomerDesgination        : false,
      autoSelectCreditCardOnFileAgreement : false,
      displaySSN                          : false,
      loyaltyPointsEnabled                : false,
      restrictVoidToManagers              : false,
      autoPrintReceipt                    : true,
      c2dEnabled                          : true,
      operationalUnitsOnly                : false,
      includeLyftWorkflow                 : true,
      unitAssignmentEnabled               : true,
      followUpUnableToServeFeedbackEnabled: false,
      enableWebPayments                   : false,
      enableBulkUserAssignment            : true,
      showPCPAffiliation                  : true,
      scanIdControlledSubstanceOverride   : false,
      permissionLevel                     : PNSLocationConfigurationPermissionLevel.Patient,
      rxNotificationEnabled               : false,
      readmissionsDays                    : 30,
      eschedulingEnabled                  : false,
    },
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private locationService: PNSLocationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAuthState>,
    private geocodingService: GoogleGeocodingService,
  ) {
  }

  @HostListener('keydown.esc')
  public onEsc() {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['/admin/locations/facility/list']);
    this.cdr.detectChanges();

  }

  ngOnInit() {
    this.invalidateAddress();
    this.orgs$                  = this.store.pipe(select(fromOrgs.getAllOrganizations)).subscribe((orgs) => {
      this.orgs = orgs;
    });
    this.locs$                  = this.store.pipe(select(fromLocs.getAllLocationsArray)).subscribe((locs) => {
      this.locs = locs;

    });
    this.addLoc$.organizationId = this.route.snapshot.params['orgId'];
  }

  invalidateAddress() {
    this.addrValidated = false;
  }

  validateAddress(form: NgForm): void {
    const v               = form.value;
    const address: string = v.street1 + ', ' + v.street2 + ' ' + v.city + ', ' + v.state + ' ' + v.zip;
    this.geocodedAddress$ = this.geocodingService.geoCodeAddress(address).subscribe((addr) => this.geoAddr = this.mapAddr(addr));
  }

  // noinspection JSMethodCanBeStatic
  compareObjects(o1: any, o2: any): boolean {
    if (o1 !== null && o2 !== null) {
      return o1.name === o2.name && o1.id === o2.id;
    } else {
      return o1;
    }
  }

  mapAddr(googleGeoAddr: any): any {
    const geoAddr = googleGeoAddr.results[0];

    if (geoAddr !== undefined && geoAddr !== null) {
      geoAddr.address_components.forEach((addressPart) => {
        if (addressPart.types.includes('street_number')) {
          this.addLoc$.address.street1 = addressPart.long_name + ' ';
        }

        if (addressPart.types.includes('route')) {
          this.addLoc$.address.street1 += (addressPart.long_name);
        }

        if (addressPart.types.includes('locality')) {
          this.addLoc$.address.city = addressPart.long_name;
        }

        if (addressPart.types.includes('administrative_area_level_1')) {
          this.addLoc$.address.state = addressPart.short_name;
        }

        if (addressPart.types.includes('postal_code')) {
          this.addLoc$.address.zip = addressPart.short_name;
        }

        if (addressPart.types.includes('postal_code_suffix')) {
          this.addLoc$.address.zip += '-' + addressPart.short_name;
        }
      });

      if (geoAddr.geometry.location.lat !== null) {
        this.addLoc$.address.latitude  = geoAddr.geometry.location.lat;
        this.addLoc$.address.longitude = geoAddr.geometry.location.lng;
      }
    }

    this.geocodingService.getTimeZone(this.addLoc$.address.latitude.toString(), this.addLoc$.address.longitude.toString()).subscribe((addrTZ) => {
      this.addLoc$.timeZone = addrTZ.timeZoneId;
      this.addrValidated    = true;
      this.cdr.detectChanges();
    });
  }

  addLocation(): void {
    const copyConf: IPNSLocationConfigurationModel = this.copyLocConfigFrom.configuration;
    if (copyConf !== undefined && copyConf !== null) {
      this.addLoc$.configuration = {...this.copyLocConfigFrom.configuration};
    }
    this.store.dispatch(new LoadPNSLocations(PNSLocationService.getDefaultSearch()));
    this.store.dispatch(new AddPNSLocation(this.addLoc$));
  }
}
