import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {PNSAuthModule} from '@portal-ng/PNSAuth/PNSAuth.module';
import {CoreComponent} from '@portal-ng/PNSCore/components/core/core.component';
import {DialogComponent} from '@portal-ng/PNSCore/components/dialog/dialog.component';
import {InfoComponent} from '@portal-ng/PNSCore/components/info/info.component';
import {PNSMenuListItemComponent} from '@portal-ng/PNSCore/components/menu-list-item/menu-list-item.component';
import {NotFoundPageComponent} from '@portal-ng/PNSCore/components/not-found-page.component';
import {StatusBadgeComponent} from '@portal-ng/PNSCore/components/status-badge-component/status-badge.component';
import {StatusBankComponent} from '@portal-ng/PNSCore/components/status-bank/status-bank.component';
import {YouShallNotPassComponent} from '@portal-ng/PNSCore/components/you-shall-not-pass.component';
import {AdminLayoutComponent} from '@portal-ng/PNSCore/layouts/admin/admin.layout.component';
import {BareLayoutComponent} from '@portal-ng/PNSCore/layouts/bare/bare.layout';
import {PNSBarcodeConfigFormComponent} from '@portal-ng/PNSLocation/components/pnsbarcode-config-form/pnsbarcode-config-form.component';
import {PNSRawSearchResultComponent} from '@portal-ng/PNSShared/components/raw-search-result-component/raw-search-result.component';
import {SharedModule} from '@portal-ng/PNSShared/shared.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {CoreRoutingModule} from './core-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {MaterialModule} from '@portal-ng/PNSMaterial';

export const COMPONENTS = [
    AdminLayoutComponent,
    CoreComponent,
    BareLayoutComponent,
    StatusBadgeComponent,
    StatusBankComponent,
    InfoComponent,
    NotFoundPageComponent,
    DashboardComponent,
    DialogComponent,
    PNSMenuListItemComponent,
    YouShallNotPassComponent,
];

@NgModule({
    imports        : [
        MaterialModule,
        CommonModule,
        BrowserModule,
        RouterModule,
        FlexLayoutModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        PNSAuthModule,
        CoreRoutingModule,
        SharedModule,
    ],
    declarations   : COMPONENTS,
    exports        : COMPONENTS,
    entryComponents: [DialogComponent, PNSRawSearchResultComponent, PNSBarcodeConfigFormComponent],
})

export class CoreModule {}
