import {Action} from '@ngrx/store';
import {IPNSOrganization} from '@portal-ng/PNSModels';
import {IPNSOrganizationSearch} from '@portal-ng/PNSModels/PNSOrganizationSearch.model';
import {IPNSOrganizationSearchResults} from '@portal-ng/PNSModels/PNSOrganizationSearchResults.model';

export enum PNSOrganizationActionTypes {
  AddPNSOrganization = '[PNSOrganzation] Add',
  AddPNSOrganizationSuccess = '[PNSOrganzation] Add Success',

  RemPNSOrganizationConfirm = '[PNSOrganzation] Remove Confirm',
  RemPNSOrganizationCancel = '[PNSOrganzation] Remove Cancel',
  RemPNSOrganization = '[PNSOrganzation] Remove',
  RemPNSOrganizationSuccess = '[PNSOrganzation] Remove Success',

  LoadPNSOrganizations = '[PNSOrganzation] Fetch',
  LoadPNSOrganizationsSuccess = '[PNSOrganzation] Fetch Success',

  EditPNSOrganization = '[PNSOrganzation] Remove',
  EditPNSOrganizationSuccess = '[PNSOrganzation] Remove Success',
}

// Editing
export class EditPNSOrganization implements Action {
  readonly type = PNSOrganizationActionTypes.EditPNSOrganization;
  constructor(public payload: Partial<IPNSOrganization>) {}
}

export class EditPNSOrganizationSuccess implements Action {
  readonly type = PNSOrganizationActionTypes.EditPNSOrganizationSuccess;
  constructor(public payload: {organization: IPNSOrganization} ) {}
}

export class AddPNSOrganization implements Action {
  readonly type = PNSOrganizationActionTypes.AddPNSOrganization;

  constructor(public payload: Partial<IPNSOrganization>) {
  }

}

export class AddPNSOrganizationSuccess implements Action {
  readonly type = PNSOrganizationActionTypes.AddPNSOrganizationSuccess;

  constructor(public payload: { organization: IPNSOrganization }) {
  }
}

// Loading

export class LoadPNSOrganizations implements Action {
  readonly type = PNSOrganizationActionTypes.LoadPNSOrganizations;
  constructor(public payload: IPNSOrganizationSearch) {}
}

export class LoadPNSOrganizationsSuccess implements Action {
  readonly type = PNSOrganizationActionTypes.LoadPNSOrganizationsSuccess;
  constructor(public payload: {response: IPNSOrganizationSearchResults} ) {}
}

export type PNSOrganizationActionTypeUnion =
  AddPNSOrganization
  | AddPNSOrganizationSuccess
  | EditPNSOrganization
  | EditPNSOrganizationSuccess
  | LoadPNSOrganizations
  | LoadPNSOrganizationsSuccess;
