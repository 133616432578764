import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthState} from '@portal-ng/PNSAuth/reducers/auth.reducer';
import {IPNSOrganization, IPNSOrganizationSearch, IPNSOrganizationSearchResults} from '@portal-ng/PNSModels';
import {PNSService} from '@portal-ng/PNSServices/PNS.service';
import {PNSAuthService} from '@portal-ng/PNSServices/PNSAuth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PNSOrganizationService {

  static getDefaultSearch(): IPNSOrganizationSearch {
    const defaultSearch = PNSService.defaultSearch() as IPNSOrganizationSearch;
    defaultSearch.type  = null;
    return defaultSearch;
  }

  constructor(
    private store: Store<IAuthState>,
    private auth: PNSAuthService,
    private PNS: PNSService,
  ) {}

  // CRUD functions
  editOrganization(organization: Partial<IPNSOrganization>): Observable<any> {
    return this.PNS.authenticatePut('organizations/' + organization.id, organization);
  }

  loadOrganizations(payload: IPNSOrganizationSearch): Observable<IPNSOrganizationSearchResults> {
    let params = new HttpParams();

    if (payload.type !== undefined && payload.type !== null) {
      params.set('type', payload.type.toString());
    }
    params = PNSService.setCommonSearchParams(params, payload);
    return this.PNS.authenticateGet('organizations', params);
  }
}
