<div id="page">
<div *ngIf="userLoaded">
  <h1 class="mat-h1">{{auditUser$.name}} <small>Access Audit</small></h1>
  <h3 class="mat-h2"><strong>Roles:</strong> {{auditUser$.roleNames | list}}</h3>
  <h3 class="mat-h2"><strong>Locations:</strong>
  <span *ngFor="let locationId of auditUser$.locationIds; let i = index">
    <span *ngIf="this.getLocationFromID(locationId).name !== undefined">
    {{this.getLocationFromID(locationId).name}}<span *ngIf="i+1 !== auditUser$.locationIds.length">, </span>
      </span>
  </span>
  </h3>
  <img src="assets/images/mocks/audit.png" alt="audit" style="width:600px; "/>
</div>
</div>
